import { useQuery as i, useMutation as c } from "@tanstack/react-query";
import a from "axios";
const d = (t, e) => a.get(
  "/echo",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), p = (t) => ["/echo", ...t ? [t] : []], _ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? p(t), queryFn: ({ signal: u }) => d(t, { signal: u, ...r }), ...n };
};
function g(t, e) {
  const n = _(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const m = (t, e) => a.post(
  "/echo",
  t,
  e
), l = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return m(o, n);
  }, ...e };
}, q = (t) => {
  const e = l(t);
  return c(e);
}, wg = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  getEcho: d,
  getGetEchoQueryKey: p,
  getGetEchoQueryOptions: _,
  getPostEchoMutationOptions: l,
  postEcho: m,
  useGetEcho: g,
  usePostEcho: q
}, Symbol.toStringTag, { value: "Module" })), Qg = {
  cash_advance_offer_created: "cash_advance_offer_created",
  cash_advance_offer_accepted: "cash_advance_offer_accepted",
  cash_advance_top_up_eligible: "cash_advance_top_up_eligible",
  cash_advance_paid_off: "cash_advance_paid_off",
  cash_advance_closed: "cash_advance_closed",
  cash_advance_offer_closed: "cash_advance_offer_closed",
  flex_loan_offer_accepted: "flex_loan_offer_accepted",
  flex_loan_closed: "flex_loan_closed",
  flex_loan_offer_created: "flex_loan_offer_created",
  flex_loan_offer_closed: "flex_loan_offer_closed",
  ordercreated: "order.created",
  orderupdated: "order.updated",
  refundcreated: "refund.created",
  refundupdated: "refund.updated",
  capital_product_offercreated: "capital_product_offer.created",
  capital_product_offerclosed: "capital_product_offer.closed",
  capital_productcreated: "capital_product.created",
  capital_productclosed: "capital_product.closed",
  capital_product_applicationupdated: "capital_product_application.updated",
  opt_in: "opt_in",
  fis_opt_in_merchant_account: "fis_opt_in_merchant_account",
  fis_opt_in_bank_account: "fis_opt_in_bank_account",
  spend_cardcreated: "spend_card.created",
  spend_cardclosed: "spend_card.closed",
  cash_accountcreated: "cash_account.created",
  cash_accountclosed: "cash_account.closed",
  spend_card_offercreated: "spend_card_offer.created",
  spend_card_offerclosed: "spend_card_offer.closed"
}, Lg = {
  normal: "normal",
  sync: "sync"
}, Wg = {
  processing: "processing",
  completed: "completed",
  failed: "failed"
}, Vg = {
  enabled: "enabled",
  disabled: "disabled"
}, $g = {
  processing: "processing",
  completed: "completed",
  failed: "failed"
}, zg = {
  active: "active",
  inactive: "inactive"
}, Hg = {
  read: "read",
  unread: "unread"
}, Yg = {
  automatically_verified: "automatically_verified",
  pending_automatic_verification: "pending_automatic_verification",
  pending_manual_verification: "pending_manual_verification",
  manually_verified: "manually_verified",
  verification_expired: "verification_expired",
  verification_failed: "verification_failed",
  database_matched: "database_matched",
  database_insights_pass: "database_insights_pass",
  database_insights_pass_with_caution: "database_insights_pass_with_caution",
  database_insights_fail: "database_insights_fail"
}, Xg = {
  Error: "Error",
  Warning: "Warning"
}, jg = {
  admin: "admin",
  user: "user",
  controller: "controller"
}, Zg = {
  pending_underwriting: "pending_underwriting",
  underwriting: "underwriting",
  failed: "failed",
  success: "success"
}, Jg = {
  failed_plaid_auth: "failed_plaid_auth"
}, tq = {
  offer: "offer",
  no_offer: "no_offer",
  manual_review: "manual_review"
}, eq = {
  pst: "pst",
  est: "est",
  utc: "utc"
}, nq = {
  terms_of_service: "terms_of_service",
  state_disclosure: "state_disclosure",
  net_top_up_itemization: "net_top_up_itemization"
}, rq = {
  payout: "payout",
  balance_transaction: "balance_transaction"
}, sq = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AS: "AS",
  GU: "GU",
  MP: "MP",
  PR: "PR",
  VI: "VI",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT"
}, oq = {
  mfa: "mfa",
  otp: "otp",
  account_recovery: "account_recovery"
}, aq = {
  daily: "daily",
  weekly: "weekly",
  biweekly: "biweekly",
  monthly: "monthly",
  term: "term"
}, uq = {
  very_high: "very_high",
  high: "high",
  medium: "medium",
  low: "low"
}, iq = {
  capital_product_plaid_bank_linking: "capital_product_plaid_bank_linking",
  capital_product_acceptance: "capital_product_acceptance",
  merchant_bank_card_transaction: "merchant_bank_card_transaction",
  merchant_bank_transfer: "merchant_bank_transfer"
}, cq = {
  pending_response: "pending_response",
  completed: "completed"
}, yq = {
  before_marketing: "before_marketing",
  after_sign_up: "after_sign_up"
}, dq = {
  capital_product_offers_table: "capital_product_offers_table",
  capital_product_applications_table: "capital_product_applications_table",
  capital_products_table: "capital_products_table",
  orders_table: "orders_table"
}, pq = {
  completed: "completed",
  in_progress: "in_progress",
  not_started: "not_started",
  failed: "failed",
  expired: "expired"
}, _q = {
  shopify: "shopify",
  amazon: "amazon",
  ebay: "ebay",
  walmart: "walmart",
  etsy: "etsy",
  woo_commerce: "woo_commerce",
  magento: "magento",
  xero: "xero",
  sage_business_cloud: "sage_business_cloud",
  square: "square",
  zohobooks: "zohobooks",
  quickbooks: "quickbooks",
  freshbooks: "freshbooks"
}, mq = {
  payments: "payments",
  accounting: "accounting"
}, lq = {
  pending: "pending",
  available: "available",
  disconnected: "disconnected",
  disabled: "disabled",
  link_error: "link_error",
  error: "error"
}, gq = {
  duplicate: "duplicate",
  incorrect_amount: "incorrect_amount",
  incorrect_receiving_account: "incorrect_receiving_account",
  date_earlier_than_intended: "date_earlier_than_intended",
  date_later_than_intended: "date_later_than_intended"
}, qq = {
  offer_amount: "offer_amount",
  fee_amount: "fee_amount"
}, Oq = {
  regular: "regular",
  special: "special"
}, fq = {
  R01: "R01",
  R02: "R02",
  R03: "R03",
  R04: "R04",
  R05: "R05",
  R06: "R06",
  R07: "R07",
  R08: "R08",
  R09: "R09",
  R10: "R10",
  R11: "R11",
  R12: "R12",
  R13: "R13",
  R14: "R14",
  R15: "R15",
  R16: "R16",
  R17: "R17",
  R18: "R18",
  R19: "R19",
  R20: "R20",
  R21: "R21",
  R22: "R22",
  R23: "R23",
  R24: "R24",
  R25: "R25",
  R26: "R26",
  R27: "R27",
  R28: "R28",
  R29: "R29",
  R30: "R30",
  R31: "R31",
  R32: "R32",
  R33: "R33",
  R34: "R34",
  R35: "R35",
  R36: "R36",
  R37: "R37",
  R38: "R38",
  R39: "R39",
  R45: "R45",
  C01: "C01",
  C02: "C02",
  C03: "C03",
  C05: "C05",
  C06: "C06",
  C07: "C07",
  C08: "C08",
  C09: "C09",
  C13: "C13",
  C14: "C14",
  NUMBER_900: 900,
  NUMBER_901: 901,
  NUMBER_902: 902,
  NUMBER_903: 903,
  NUMBER_904: 904,
  NUMBER_905: 905,
  NUMBER_907: 907,
  NUMBER_908: 908,
  NUMBER_909: 909,
  NUMBER_910: 910,
  NUMBER_911: 911,
  NUMBER_912: 912,
  NUMBER_914: 914,
  NUMBER_915: 915,
  NUMBER_916: 916,
  NUMBER_917: 917,
  NUMBER_918: 918,
  NUMBER_919: 919,
  NUMBER_920: 920,
  NUMBER_921: 921,
  NUMBER_922: 922,
  NUMBER_990: 990
}, hq = {
  prevent_business_onboarding: "prevent_business_onboarding",
  suspend_card_account: "suspend_card_account",
  prevent_money_movement_out: "prevent_money_movement_out",
  prevent_money_movement_in_and_out: "prevent_money_movement_in_and_out"
}, Kq = {
  manual: "manual",
  auto_expiration: "auto_expiration",
  merchant_behavior_adjustment: "merchant_behavior_adjustment",
  repeated_failed_nsf_repayments: "repeated_failed_nsf_repayments",
  lift_repeated_failed_nsf_repayments: "lift_repeated_failed_nsf_repayments",
  unverified_bank_account: "unverified_bank_account",
  lift_unverified_bank_account: "lift_unverified_bank_account",
  recent_non_nsf_repayment_returns: "recent_non_nsf_repayment_returns",
  lift_recent_non_nsf_repayment_returns: "lift_recent_non_nsf_repayment_returns",
  first_repayment_returned_nsf: "first_repayment_returned_nsf",
  lift_first_repayment_returned_nsf: "lift_first_repayment_returned_nsf",
  first_auto_repayment_returned_nsf: "first_auto_repayment_returned_nsf",
  lift_first_auto_repayment_returned_nsf: "lift_first_auto_repayment_returned_nsf",
  no_recent_sales: "no_recent_sales",
  lift_no_recent_sales: "lift_no_recent_sales",
  plaid_transaction_review: "plaid_transaction_review",
  generic_application_denial: "generic_application_denial"
}, Pq = {
  merchant_behavior_adjustment: "merchant_behavior_adjustment",
  unverified_bank_account: "unverified_bank_account",
  recent_non_nsf_repayment_returns: "recent_non_nsf_repayment_returns",
  first_repayment_returned_nsf: "first_repayment_returned_nsf",
  first_auto_repayment_returned_nsf: "first_auto_repayment_returned_nsf",
  no_recent_sales: "no_recent_sales",
  repeated_failed_nsf_repayments: "repeated_failed_nsf_repayments",
  fraud: "fraud",
  bankruptcy: "bankruptcy",
  active_payment_plan_high_risk: "active_payment_plan_high_risk",
  payment_processor_transition: "payment_processor_transition",
  shared_email_across_platforms: "shared_email_across_platforms",
  bank_account_high_servicing_risk: "bank_account_high_servicing_risk",
  servicing_risk: "servicing_risk",
  generic_application_denial: "generic_application_denial"
}, bq = {
  unverified_bank_account: "unverified_bank_account",
  repayment_returns: "repayment_returns",
  other: "other"
}, xq = {
  damaged: "damaged",
  lost: "lost",
  stolen: "stolen",
  never_received: "never_received"
}, Mq = {
  complete: "complete",
  deferred: "deferred",
  failed: "failed",
  pending: "pending",
  refunded: "refunded",
  double_refunded: "double_refunded"
}, Fq = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  manual: "manual",
  sales_based_adjustment: "sales_based_adjustment",
  minimum_amount: "minimum_amount"
}, kq = {
  complete: "complete",
  deferred: "deferred",
  failed: "failed",
  pending: "pending",
  refunded: "refunded",
  double_refunded: "double_refunded"
}, Rq = {
  complete: "complete",
  deferred: "deferred",
  failed: "failed",
  pending: "pending",
  refunded: "refunded",
  double_refunded: "double_refunded"
}, Bq = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  manual: "manual",
  sales_based_adjustment: "sales_based_adjustment",
  minimum_amount: "minimum_amount"
}, Cq = {
  daily: "daily",
  weekly: "weekly",
  biweekly: "biweekly",
  monthly: "monthly"
}, Eq = {
  cx: "cx",
  pending_funding: "pending_funding",
  too_many_missed_repayments: "too_many_missed_repayments",
  administrative_ach_return: "administrative_ach_return",
  awaiting_retry_non_nsf_return: "awaiting_retry_non_nsf_return",
  pending_reversal_completion: "pending_reversal_completion"
}, Sq = {
  direct_debit: "direct_debit",
  net_settlement: "net_settlement"
}, vq = {
  gross_sales: "gross_sales",
  net_payouts: "net_payouts"
}, Dq = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  minimum_amount: "minimum_amount"
}, Aq = {
  opened: "opened",
  failed: "failed",
  succeeded: "succeeded"
}, Uq = {
  payment_method_not_refundable: "payment_method_not_refundable",
  bank_return: "bank_return"
}, Nq = {
  pending: "pending",
  approved: "approved",
  denied: "denied",
  manual_review: "manual_review"
}, Gq = {
  other: "other",
  disbursements: "disbursements",
  serviceability: "serviceability"
}, Tq = {
  mfa_phone_number: "mfa_phone_number",
  unverified_phone_number: "unverified_phone_number"
}, Iq = {
  NUMBER_93: "93",
  NUMBER_355: "355",
  NUMBER_213: "213",
  NUMBER_1684: "1684",
  NUMBER_376: "376",
  NUMBER_244: "244",
  NUMBER_1264: "1264",
  NUMBER_672: "672",
  NUMBER_1268: "1268",
  NUMBER_54: "54",
  NUMBER_374: "374",
  NUMBER_297: "297",
  NUMBER_61: "61",
  NUMBER_43: "43",
  NUMBER_994: "994",
  NUMBER_1242: "1242",
  NUMBER_973: "973",
  NUMBER_880: "880",
  NUMBER_1246: "1246",
  NUMBER_375: "375",
  NUMBER_32: "32",
  NUMBER_501: "501",
  NUMBER_229: "229",
  NUMBER_1441: "1441",
  NUMBER_975: "975",
  NUMBER_591: "591",
  NUMBER_387: "387",
  NUMBER_267: "267",
  NUMBER_55: "55",
  NUMBER_246: "246",
  NUMBER_1284: "1284",
  NUMBER_673: "673",
  NUMBER_359: "359",
  NUMBER_226: "226",
  NUMBER_257: "257",
  NUMBER_855: "855",
  NUMBER_237: "237",
  NUMBER_1: "1",
  NUMBER_238: "238",
  NUMBER_1345: "1345",
  NUMBER_236: "236",
  NUMBER_235: "235",
  NUMBER_56: "56",
  NUMBER_86: "86",
  NUMBER_57: "57",
  NUMBER_269: "269",
  NUMBER_682: "682",
  NUMBER_506: "506",
  NUMBER_385: "385",
  NUMBER_53: "53",
  NUMBER_599: "599",
  NUMBER_357: "357",
  NUMBER_420: "420",
  NUMBER_243: "243",
  NUMBER_45: "45",
  NUMBER_253: "253",
  "1-767": "1-767",
  NUMBER_1809: "1809",
  NUMBER_1829: "1829",
  NUMBER_1849: "1849",
  NUMBER_670: "670",
  NUMBER_593: "593",
  NUMBER_20: "20",
  NUMBER_503: "503",
  NUMBER_240: "240",
  NUMBER_291: "291",
  NUMBER_372: "372",
  NUMBER_251: "251",
  NUMBER_500: "500",
  NUMBER_298: "298",
  NUMBER_679: "679",
  NUMBER_358: "358",
  NUMBER_33: "33",
  NUMBER_689: "689",
  NUMBER_241: "241",
  NUMBER_220: "220",
  NUMBER_995: "995",
  NUMBER_49: "49",
  NUMBER_233: "233",
  NUMBER_350: "350",
  NUMBER_30: "30",
  NUMBER_299: "299",
  NUMBER_1473: "1473",
  NUMBER_1671: "1671",
  NUMBER_502: "502",
  NUMBER_441481: "441481",
  NUMBER_224: "224",
  NUMBER_245: "245",
  NUMBER_592: "592",
  NUMBER_509: "509",
  NUMBER_504: "504",
  NUMBER_852: "852",
  NUMBER_36: "36",
  NUMBER_354: "354",
  NUMBER_91: "91",
  NUMBER_62: "62",
  NUMBER_98: "98",
  NUMBER_964: "964",
  NUMBER_353: "353",
  NUMBER_441624: "441624",
  NUMBER_972: "972",
  NUMBER_39: "39",
  NUMBER_225: "225",
  NUMBER_1876: "1876",
  NUMBER_81: "81",
  NUMBER_441534: "441534",
  NUMBER_962: "962",
  NUMBER_7: "7",
  NUMBER_254: "254",
  NUMBER_686: "686",
  NUMBER_383: "383",
  NUMBER_965: "965",
  NUMBER_996: "996",
  NUMBER_856: "856",
  NUMBER_371: "371",
  NUMBER_961: "961",
  NUMBER_266: "266",
  NUMBER_231: "231",
  NUMBER_218: "218",
  NUMBER_423: "423",
  NUMBER_370: "370",
  NUMBER_352: "352",
  NUMBER_853: "853",
  NUMBER_389: "389",
  NUMBER_261: "261",
  NUMBER_265: "265",
  NUMBER_60: "60",
  NUMBER_960: "960",
  NUMBER_223: "223",
  NUMBER_356: "356",
  NUMBER_692: "692",
  NUMBER_222: "222",
  NUMBER_230: "230",
  NUMBER_262: "262",
  NUMBER_52: "52",
  NUMBER_691: "691",
  NUMBER_373: "373",
  NUMBER_377: "377",
  NUMBER_976: "976",
  NUMBER_382: "382",
  "1-664": "1-664",
  NUMBER_212: "212",
  NUMBER_258: "258",
  NUMBER_95: "95",
  NUMBER_264: "264",
  NUMBER_674: "674",
  NUMBER_977: "977",
  NUMBER_31: "31",
  NUMBER_687: "687",
  NUMBER_64: "64",
  NUMBER_505: "505",
  NUMBER_227: "227",
  NUMBER_234: "234",
  NUMBER_683: "683",
  NUMBER_850: "850",
  NUMBER_1670: "1670",
  NUMBER_47: "47",
  NUMBER_968: "968",
  NUMBER_92: "92",
  NUMBER_680: "680",
  NUMBER_970: "970",
  NUMBER_507: "507",
  NUMBER_675: "675",
  NUMBER_595: "595",
  NUMBER_51: "51",
  NUMBER_63: "63",
  NUMBER_48: "48",
  NUMBER_351: "351",
  NUMBER_1787: "1787",
  NUMBER_1939: "1939",
  NUMBER_974: "974",
  NUMBER_242: "242",
  NUMBER_40: "40",
  NUMBER_250: "250",
  NUMBER_590: "590",
  NUMBER_290: "290",
  NUMBER_1869: "1869",
  NUMBER_1758: "1758",
  NUMBER_508: "508",
  NUMBER_1784: "1784",
  NUMBER_685: "685",
  NUMBER_378: "378",
  NUMBER_239: "239",
  NUMBER_966: "966",
  NUMBER_221: "221",
  NUMBER_381: "381",
  NUMBER_248: "248",
  NUMBER_232: "232",
  NUMBER_65: "65",
  NUMBER_1721: "1721",
  NUMBER_421: "421",
  NUMBER_386: "386",
  NUMBER_677: "677",
  NUMBER_252: "252",
  NUMBER_27: "27",
  NUMBER_82: "82",
  NUMBER_211: "211",
  NUMBER_34: "34",
  NUMBER_94: "94",
  NUMBER_249: "249",
  NUMBER_597: "597",
  NUMBER_268: "268",
  NUMBER_46: "46",
  NUMBER_41: "41",
  NUMBER_963: "963",
  NUMBER_886: "886",
  NUMBER_992: "992",
  NUMBER_255: "255",
  NUMBER_66: "66",
  NUMBER_228: "228",
  NUMBER_690: "690",
  NUMBER_676: "676",
  NUMBER_1868: "1868",
  NUMBER_216: "216",
  NUMBER_90: "90",
  NUMBER_993: "993",
  NUMBER_1649: "1649",
  NUMBER_688: "688",
  NUMBER_1340: "1340",
  NUMBER_256: "256",
  NUMBER_380: "380",
  NUMBER_971: "971",
  NUMBER_44: "44",
  NUMBER_598: "598",
  NUMBER_998: "998",
  NUMBER_678: "678",
  NUMBER_379: "379",
  NUMBER_58: "58",
  NUMBER_84: "84",
  NUMBER_681: "681",
  NUMBER_967: "967",
  NUMBER_260: "260",
  NUMBER_263: "263"
}, wq = {
  BUSINESS: "BUSINESS",
  BUSINESSSUPPLEMENTALDOCUMENTS: "BUSINESSSUPPLEMENTALDOCUMENTS",
  GOVID: "GOVID",
  ADHOCBUSINESSNAMEDOCUMENT: "ADHOCBUSINESSNAMEDOCUMENT",
  ADHOCBUSINESSADDRESSDOCUMENT: "ADHOCBUSINESSADDRESSDOCUMENT",
  ADHOCBUSINESSSTATUSDOCUMENT: "ADHOCBUSINESSSTATUSDOCUMENT",
  ADHOCHOMEADDRESSDOCUMENT: "ADHOCHOMEADDRESSDOCUMENT",
  ADHOCEINDOCUMENT: "ADHOCEINDOCUMENT",
  ADHOCIDENTITYDOCUMENT: "ADHOCIDENTITYDOCUMENT"
}, Qq = {
  admin: "admin",
  merchant_representative: "merchant_representative",
  developer: "developer",
  controller: "controller"
}, Lq = {
  s3_datashare_upload: "s3_datashare_upload",
  manual_datashare_upload: "manual_datashare_upload",
  missing_sales_datashare: "missing_sales_datashare",
  missing_bank_account_datashare: "missing_bank_account_datashare",
  api_errors: "api_errors",
  crm_errors: "crm_errors",
  webhook_errors: "webhook_errors",
  user_added: "user_added",
  send_otp: "send_otp"
}, Wq = {
  merchant_cash_advance: "merchant_cash_advance",
  card: "card",
  flex_loan: "flex_loan",
  merchant_bank: "merchant_bank"
}, Vq = {
  opened: "opened",
  accepted: "accepted",
  canceled: "canceled",
  declined: "declined",
  finalized: "finalized"
}, $q = {
  opened: "opened",
  accepted: "accepted",
  canceled: "canceled",
  declined: "declined",
  finalized: "finalized"
}, zq = {
  in_progress: "in_progress",
  completed: "completed",
  failed: "failed"
}, Hq = {
  void_cash_advance: "void_cash_advance"
}, Yq = {
  basic_offer_generation: "basic_offer_generation",
  underwriting: "underwriting",
  boost_link: "boost_link",
  accounting_integration: "accounting_integration",
  kyc_retry: "kyc_retry",
  boost_offer_generation: "boost_offer_generation",
  kyc: "kyc",
  kyc_submitted: "kyc_submitted",
  kyc_document_upload: "kyc_document_upload",
  bank_link: "bank_link",
  bank_link_with_statements: "bank_link_with_statements",
  bank_verification: "bank_verification",
  bank_verification_with_statements: "bank_verification_with_statements",
  bank_pad_agreement: "bank_pad_agreement",
  amazon_bank_verification: "amazon_bank_verification",
  amazon_bank_reverification: "amazon_bank_reverification",
  amazon_bank_reverification_with_statements: "amazon_bank_reverification_with_statements",
  introductory_offer_preview: "introductory_offer_preview",
  introductory_offer_underwriting: "introductory_offer_underwriting",
  introductory_offer_underwriting_rate_limit_exceeded: "introductory_offer_underwriting_rate_limit_exceeded",
  offer_acceptance: "offer_acceptance",
  lien_info: "lien_info",
  debt_info: "debt_info",
  financial_review: "financial_review",
  tax_record: "tax_record",
  officer_subsidiary_record: "officer_subsidiary_record",
  manual_review: "manual_review",
  offer_accepted: "offer_accepted",
  offer_preview: "offer_preview",
  capital_product: "capital_product",
  denied: "denied",
  abandoned: "abandoned",
  merchant_bank_pre_onboarding: "merchant_bank_pre_onboarding",
  merchant_bank_created: "merchant_bank_created",
  contact_verification: "contact_verification",
  application_temporary_waiting_screen: "application_temporary_waiting_screen"
}, Xq = {
  merchant_cash_advance: "merchant_cash_advance",
  loan: "loan",
  capital: "capital",
  merchant_bank: "merchant_bank"
}, jq = {
  basic_offer_generation: "basic_offer_generation",
  boost_link: "boost_link",
  accounting_integration: "accounting_integration",
  kyc_retry: "kyc_retry",
  boost_offer_generation: "boost_offer_generation",
  kyc: "kyc",
  kyc_submitted: "kyc_submitted",
  bank_link: "bank_link",
  bank_link_with_statements: "bank_link_with_statements",
  bank_pad_agreement: "bank_pad_agreement",
  bank_verification: "bank_verification",
  bank_verification_with_statements: "bank_verification_with_statements",
  bank_reverification: "bank_reverification",
  amazon_bank_verification: "amazon_bank_verification",
  amazon_bank_reverification: "amazon_bank_reverification",
  amazon_bank_reverification_with_statements: "amazon_bank_reverification_with_statements",
  offer_acceptance: "offer_acceptance",
  kyc_document_upload: "kyc_document_upload",
  lien_info: "lien_info",
  debt_info: "debt_info",
  financial_review: "financial_review",
  tax_record: "tax_record",
  officer_subsidiary_record: "officer_subsidiary_record",
  start_application: "start_application",
  offer_preview: "offer_preview",
  application_temporary_waiting_screen: "application_temporary_waiting_screen",
  introductory_offer_underwriting_rate_limit_exceeded: "introductory_offer_underwriting_rate_limit_exceeded",
  introductory_offer_underwriting: "introductory_offer_underwriting",
  underwriting: "underwriting"
}, Zq = {
  top_up_reminder: "top_up_reminder",
  test_notification_without_reset_window: "test_notification_without_reset_window",
  connections_reminder: "connections_reminder"
}, Jq = {
  active: "active",
  inactive: "inactive"
}, tO = {
  opted_in: "opted_in",
  opted_out: "opted_out",
  never_opted_in: "never_opted_in"
}, eO = {
  minimum: "minimum",
  sales_based: "sales_based"
}, nO = {
  unverified: "unverified",
  posted: "posted",
  reversed: "reversed",
  verified: "verified",
  failed: "failed"
}, rO = {
  ach: "ach",
  same_day_ach: "same_day_ach",
  rtp: "rtp",
  wire: "wire"
}, sO = {
  inner_transfer: "inner_transfer",
  card_transaction: "card_transaction",
  recipient_transfer: "recipient_transfer",
  external_bank_transfer: "external_bank_transfer",
  received_transfer: "received_transfer",
  transfer_return: "transfer_return",
  transfer_reversal: "transfer_reversal",
  repayment: "repayment",
  card_dispute_credit: "card_dispute_credit"
}, oO = {
  succeeded: "succeeded",
  failed: "failed",
  pending: "pending",
  cancelled: "cancelled",
  archived: "archived"
}, aO = {
  money_in: "money_in",
  money_out: "money_out"
}, uO = {
  merchant_bank_account: "merchant_bank_account",
  recipient_account: "recipient_account",
  external_bank_account: "external_bank_account"
}, iO = {
  manual: "manual",
  payout: "payout"
}, cO = {
  electrical: "electrical",
  wire: "wire"
}, yO = {
  normal: "normal",
  micro_deposit: "micro_deposit",
  partner_payout: "partner_payout"
}, dO = {
  credit_limit: "credit_limit"
}, pO = {
  checking: "checking",
  savings: "savings"
}, _O = {
  active: "active",
  unlinked: "unlinked",
  archived_due_to_update: "archived_due_to_update",
  unverified_micro_deposit: "unverified_micro_deposit",
  failed_micro_deposit: "failed_micro_deposit"
}, mO = {
  physical: "physical",
  virtual: "virtual"
}, lO = {
  damaged: "damaged",
  lost: "lost",
  stolen: "stolen",
  never_received: "never_received"
}, gO = {
  cash: "cash",
  credit: "credit"
}, qO = {
  user_submitted: "user_submitted",
  parafin_rejected: "parafin_rejected",
  parafin_pending: "parafin_pending",
  parafin_submitted: "parafin_submitted",
  marqeta_rejected: "marqeta_rejected",
  marqeta_chargeback_won: "marqeta_chargeback_won",
  marqeta_chargeback_lost: "marqeta_chargeback_lost",
  parafin_credited: "parafin_credited",
  parafin_not_credited: "parafin_not_credited"
}, OO = {
  did_not_purchase: "did_not_purchase",
  charged_for_cancelled_or_returned_purchase: "charged_for_cancelled_or_returned_purchase",
  incorrect_amount: "incorrect_amount",
  never_received: "never_received",
  expecting_credit: "expecting_credit",
  not_as_described_or_defective: "not_as_described_or_defective",
  other: "other"
}, fO = {
  contact_verification: "contact_verification",
  kyc: "kyc",
  kyc_document_upload: "kyc_document_upload",
  kyc_submitted: "kyc_submitted",
  kyc_approved: "kyc_approved",
  manual_review: "manual_review",
  merchant_bank_created: "merchant_bank_created",
  denied: "denied"
}, hO = {
  checking: "checking",
  savings: "savings"
}, KO = {
  funded: "funded",
  funding_initiated: "funding_initiated",
  offer_accepted: "offer_accepted",
  in_progress: "in_progress",
  pending_verification: "pending_verification",
  preapproved: "preapproved",
  no_offers: "no_offers",
  funding_denied: "funding_denied"
}, PO = {
  pre_launch_email: "pre_launch_email",
  capital_product_offer_email: "capital_product_offer_email"
}, bO = {
  underwriting: "underwriting",
  kyc: "kyc",
  funding_checklist: "funding_checklist",
  statement_upload: "statement_upload",
  plaid_investigation: "plaid_investigation",
  manual_review: "manual_review",
  liens_pending: "liens_pending"
}, xO = {
  funded: "funded",
  funding_initiated: "funding_initiated",
  offer_accepted: "offer_accepted",
  in_progress: "in_progress",
  pending_verification: "pending_verification",
  preapproved: "preapproved",
  no_offers: "no_offers",
  funding_denied: "funding_denied"
}, MO = {
  ucc: "ucc",
  federal: "federal",
  state: "state",
  judgment: "judgment"
}, FO = {
  open: "open",
  closed: "closed",
  unknown: "unknown",
  filed: "filed"
}, kO = {
  pending_user: "pending_user",
  user_reviewed: "user_reviewed",
  do_not_need_user_review: "do_not_need_user_review"
}, RO = {
  date_of_birth: "date_of_birth",
  address: "address",
  name: "name",
  tin: "tin"
}, BO = {
  address: "address",
  name: "name",
  tin: "tin"
}, CO = {
  incomplete: "incomplete",
  approved: "approved",
  denied: "denied",
  under_review: "under_review",
  pending: "pending"
}, EO = {
  out_of_date_statements: "out_of_date_statements",
  disbursements_not_found: "disbursements_not_found"
}, SO = {
  checking_to_savings: "checking_to_savings",
  savings_to_checking: "savings_to_checking"
}, vO = {
  not_started: "not_started",
  pending: "pending",
  success: "success",
  failed: "failed"
}, DO = {
  irrelevant: "irrelevant",
  incomplete: "incomplete",
  complete: "complete"
}, AO = {
  llc: "llc",
  corporation: "corporation",
  sole_proprietorship: "sole_proprietorship",
  partnership: "partnership",
  cooperative: "cooperative",
  business_trust: "business_trust",
  joint_venture: "joint_venture",
  other: "other"
}, UO = {
  llc: "llc",
  corporation: "corporation",
  sole_proprietorship: "sole_proprietorship",
  partnership: "partnership",
  cooperative: "cooperative",
  business_trust: "business_trust",
  joint_venture: "joint_venture",
  other: "other"
}, NO = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  PR: "PR",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT",
  Federal: "Federal"
}, GO = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  PR: "PR",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT",
  Federal: "Federal"
}, TO = {
  all_merchants: "all_merchants",
  post_opt_in: "post_opt_in",
  post_opt_in_sync_webhook: "post_opt_in_sync_webhook",
  never: "never"
}, IO = {
  upcoming_repayment: "upcoming_repayment",
  scheduled_repayment: "scheduled_repayment"
}, wO = {
  manual: "manual",
  rutter: "rutter"
}, QO = {
  string: "string",
  decimal: "decimal",
  int: "int",
  date: "date",
  object: "object",
  currency_code: "currency_code",
  boolean: "boolean",
  long: "long"
}, LO = {
  not_started: "not_started",
  under_review: "under_review",
  approved: "approved"
}, WO = {
  business: "business",
  account: "account"
}, VO = {
  system: "system",
  user: "user"
}, $O = {
  active: "active",
  inactive: "inactive"
}, zO = {
  not_started: "not_started",
  completed: "completed",
  in_progress: "in_progress",
  failed: "failed"
}, HO = {
  partner_datashare: "partner_datashare",
  manual_edit: "manual_edit",
  kyc: "kyc",
  auto_opt_in: "auto_opt_in",
  auto_opt_in_backfill: "auto_opt_in_backfill"
}, YO = {
  BUSINESS_GROUP_ADMIN: "BUSINESS GROUP ADMIN",
  ADMIN: "ADMIN",
  "N/A": "N/A",
  MANAGER: "MANAGER",
  STORE_OPERATOR: "STORE OPERATOR"
}, XO = {
  kyc: "kyc",
  bank_verification: "bank_verification",
  financial_review: "financial_review",
  tax_record: "tax_record",
  intro_offer: "intro_offer"
}, jO = {
  order: "order"
}, ZO = {
  user_submitted: "user_submitted",
  parafin_rejected: "parafin_rejected",
  parafin_pending: "parafin_pending",
  parafin_submitted: "parafin_submitted",
  marqeta_rejected: "marqeta_rejected",
  marqeta_chargeback_won: "marqeta_chargeback_won",
  marqeta_chargeback_lost: "marqeta_chargeback_lost",
  parafin_credited: "parafin_credited",
  parafin_not_credited: "parafin_not_credited"
}, JO = {
  user_submitted: "user_submitted",
  case_processing: "case_processing",
  user_won: "user_won",
  user_lost: "user_lost"
}, tf = {
  unauthorized_transaction: "unauthorized_transaction",
  product_not_received: "product_not_received",
  product_unacceptable: "product_unacceptable",
  incorrect_charge_amount: "incorrect_charge_amount",
  product_cancelled_or_returned: "product_cancelled_or_returned",
  other: "other"
}, ef = {
  partner_wide_program: "partner_wide_program",
  business_level_program: "business_level_program"
}, nf = {
  under_review: "under_review",
  scheduled: "scheduled",
  running: "running",
  ended: "ended",
  denied: "denied"
}, rf = {
  revenue_share: "revenue_share",
  parafin_funded: "parafin_funded"
}, sf = {
  apply: "apply",
  lift: "lift"
}, of = {
  underwriting: "underwriting",
  kyc: "kyc",
  funding_checklist: "funding_checklist",
  location: "location"
}, af = {
  pending: "pending",
  under_review: "under_review",
  approved: "approved",
  denied: "denied",
  expired: "expired",
  error: "error",
  not_applicable: "not_applicable"
}, uf = {
  sunday: "sunday",
  monday: "monday",
  tuesday: "tuesday",
  wednesday: "wednesday",
  thursday: "thursday",
  friday: "friday",
  saturday: "saturday"
}, cf = {
  file_feed: "file_feed",
  manual_upload: "manual_upload",
  standard_s3: "standard_s3",
  api: "api",
  stripe_ingestion: "stripe_ingestion",
  custom_legacy: "custom_legacy",
  opt_in: "opt_in"
}, yf = {
  pending: "pending",
  success: "success",
  failed: "failed"
}, df = {
  business: "business",
  sale_record: "sale_record",
  bank_account_info: "bank_account_info",
  person: "person",
  person_business_relationship: "person_business_relationship"
}, pf = {
  irrelevant: "irrelevant",
  optional: "optional",
  required: "required"
}, _f = {
  AED: "AED",
  AFN: "AFN",
  ALL: "ALL",
  AMD: "AMD",
  ANG: "ANG",
  AOA: "AOA",
  ARS: "ARS",
  AUD: "AUD",
  AWG: "AWG",
  AZN: "AZN",
  BAM: "BAM",
  BBD: "BBD",
  BDT: "BDT",
  BGN: "BGN",
  BHD: "BHD",
  BIF: "BIF",
  BMD: "BMD",
  BND: "BND",
  BOB: "BOB",
  BOV: "BOV",
  BRL: "BRL",
  BSD: "BSD",
  BTN: "BTN",
  BWP: "BWP",
  BYN: "BYN",
  BZD: "BZD",
  CAD: "CAD",
  CDF: "CDF",
  CHE: "CHE",
  CHF: "CHF",
  CHW: "CHW",
  CLF: "CLF",
  CLP: "CLP",
  CNY: "CNY",
  COP: "COP",
  COU: "COU",
  CRC: "CRC",
  CUC: "CUC",
  CUP: "CUP",
  CVE: "CVE",
  CZK: "CZK",
  DJF: "DJF",
  DKK: "DKK",
  DOP: "DOP",
  DZD: "DZD",
  EGP: "EGP",
  ERN: "ERN",
  ETB: "ETB",
  EUR: "EUR",
  FJD: "FJD",
  FKP: "FKP",
  GBP: "GBP",
  GEL: "GEL",
  GHS: "GHS",
  GIP: "GIP",
  GMD: "GMD",
  GNF: "GNF",
  GTQ: "GTQ",
  GYD: "GYD",
  HKD: "HKD",
  HNL: "HNL",
  HTG: "HTG",
  HUF: "HUF",
  IDR: "IDR",
  ILS: "ILS",
  INR: "INR",
  IQD: "IQD",
  IRR: "IRR",
  ISK: "ISK",
  JMD: "JMD",
  JOD: "JOD",
  JPY: "JPY",
  KES: "KES",
  KGS: "KGS",
  KHR: "KHR",
  KMF: "KMF",
  KPW: "KPW",
  KRW: "KRW",
  KWD: "KWD",
  KYD: "KYD",
  KZT: "KZT",
  LAK: "LAK",
  LBP: "LBP",
  LKR: "LKR",
  LRD: "LRD",
  LSL: "LSL",
  LYD: "LYD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "MGA",
  MKD: "MKD",
  MMK: "MMK",
  MNT: "MNT",
  MOP: "MOP",
  MRU: "MRU",
  MUR: "MUR",
  MVR: "MVR",
  MWK: "MWK",
  MXN: "MXN",
  MXV: "MXV",
  MYR: "MYR",
  MZN: "MZN",
  NAD: "NAD",
  NGN: "NGN",
  NIO: "NIO",
  NOK: "NOK",
  NPR: "NPR",
  NZD: "NZD",
  OMR: "OMR",
  PAB: "PAB",
  PEN: "PEN",
  PGK: "PGK",
  PHP: "PHP",
  PKR: "PKR",
  PLN: "PLN",
  PYG: "PYG",
  QAR: "QAR",
  RON: "RON",
  RSD: "RSD",
  RUB: "RUB",
  RWF: "RWF",
  SAR: "SAR",
  SBD: "SBD",
  SCR: "SCR",
  SDG: "SDG",
  SEK: "SEK",
  SGD: "SGD",
  SHP: "SHP",
  SLE: "SLE",
  SOS: "SOS",
  SRD: "SRD",
  SSP: "SSP",
  STN: "STN",
  SVC: "SVC",
  SYP: "SYP",
  SZL: "SZL",
  THB: "THB",
  TJS: "TJS",
  TMT: "TMT",
  TND: "TND",
  TOP: "TOP",
  TRY: "TRY",
  TTD: "TTD",
  TWD: "TWD",
  TZS: "TZS",
  UAH: "UAH",
  UGX: "UGX",
  USD: "USD",
  USN: "USN",
  UYI: "UYI",
  UYU: "UYU",
  UYW: "UYW",
  UZS: "UZS",
  VED: "VED",
  VES: "VES",
  VND: "VND",
  VUV: "VUV",
  WST: "WST",
  XAF: "XAF",
  XAG: "XAG",
  XAU: "XAU",
  XBA: "XBA",
  XBB: "XBB",
  XBC: "XBC",
  XBD: "XBD",
  XCD: "XCD",
  XDR: "XDR",
  XOF: "XOF",
  XPD: "XPD",
  XPF: "XPF",
  XPT: "XPT",
  XSU: "XSU",
  XTS: "XTS",
  XUA: "XUA",
  XXX: "XXX",
  YER: "YER",
  ZAR: "ZAR",
  ZMW: "ZMW",
  ZWL: "ZWL"
}, mf = {
  USD: "USD",
  CAD: "CAD"
}, lf = {
  UNSUPPORTEDCURRENCY: "UNSUPPORTEDCURRENCY",
  USD: "USD",
  CAD: "CAD",
  USDFOURTHDECIMAL: "USDFOURTHDECIMAL"
}, gf = {
  hubspot: "hubspot",
  marketo: "marketo",
  salesforce: "salesforce"
}, qf = {
  offer_summary: "offer_summary"
}, Of = {
  pending: "pending",
  succeeded: "succeeded",
  failed: "failed"
}, ff = {
  US: "US",
  CA: "CA"
}, hf = {
  fulfillmentdigitally_presented: "fulfillment.digitally_presented",
  fulfillmentissued: "fulfillment.issued",
  fulfillmentordered: "fulfillment.ordered",
  fulfillmentrejected: "fulfillment.rejected",
  fulfillmentreordered: "fulfillment.reordered",
  fulfillmentshipped: "fulfillment.shipped",
  stateactivated: "state.activated",
  statereinstated: "state.reinstated",
  statesuspended: "state.suspended",
  stateterminated: "state.terminated",
  pinchanged: "pin.changed",
  pinset: "pin.set",
  pinreveal: "pin.reveal"
}, Kf = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNACTIVATED: "UNACTIVATED"
}, Pf = {
  NUMBER_00: "00",
  NUMBER_01: "01",
  NUMBER_02: "02",
  NUMBER_03: "03",
  NUMBER_04: "04",
  NUMBER_05: "05",
  NUMBER_06: "06",
  NUMBER_07: "07",
  NUMBER_08: "08",
  NUMBER_09: "09",
  NUMBER_10: "10",
  NUMBER_11: "11",
  NUMBER_12: "12",
  NUMBER_13: "13",
  NUMBER_14: "14",
  NUMBER_15: "15",
  NUMBER_16: "16",
  NUMBER_17: "17",
  NUMBER_18: "18",
  NUMBER_19: "19",
  NUMBER_20: "20",
  NUMBER_21: "21",
  NUMBER_22: "22",
  NUMBER_23: "23",
  NUMBER_24: "24",
  NUMBER_25: "25",
  NUMBER_26: "26",
  NUMBER_27: "27",
  NUMBER_28: "28",
  NUMBER_29: "29",
  NUMBER_30: "30",
  NUMBER_31: "31"
}, bf = {
  ADMIN: "ADMIN",
  API: "API",
  FRAUD: "FRAUD",
  IVR: "IVR",
  SYSTEM: "SYSTEM"
}, xf = {
  chargeback: "chargeback",
  normal: "normal"
}, Mf = {
  LOCAL_MAIL: "LOCAL_MAIL",
  GROUND: "GROUND",
  TWO_DAY: "TWO_DAY",
  OVERNIGHT: "OVERNIGHT",
  INTERNATIONAL: "INTERNATIONAL",
  FEDEX_EXPEDITED: "FEDEX_EXPEDITED",
  FEDEX_REGULAR: "FEDEX_REGULAR",
  UPS_EXPEDITED: "UPS_EXPEDITED",
  UPS_REGULAR: "UPS_REGULAR",
  USPS_EXPEDITED: "USPS_EXPEDITED",
  USPS_REGULAR: "USPS_REGULAR"
}, Ff = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNSUPPORTED: "UNSUPPORTED",
  UNACTIVATED: "UNACTIVATED",
  LIMITED: "LIMITED"
}, kf = {
  ISSUED: "ISSUED",
  ORDERED: "ORDERED",
  REORDERED: "REORDERED",
  REJECTED: "REJECTED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  DIGITALLY_PRESENTED: "DIGITALLY_PRESENTED"
}, Rf = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNSUPPORTED: "UNSUPPORTED",
  UNACTIVATED: "UNACTIVATED",
  LIMITED: "LIMITED"
}, Bf = {
  ISSUED: "ISSUED",
  ORDERED: "ORDERED",
  REORDERED: "REORDERED",
  REJECTED: "REJECTED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  DIGITALLY_PRESENTED: "DIGITALLY_PRESENTED"
}, Cf = {
  physical: "physical",
  virtual: "virtual"
}, Ef = {
  no_opt_in: "no_opt_in",
  opt_in: "opt_in",
  onboarding: "onboarding",
  offer_expired: "offer_expired",
  kyc_review: "kyc_review",
  kyc_failed: "kyc_failed",
  verify_bank: "verify_bank",
  reverify_bank: "reverify_bank",
  no_transaction: "no_transaction",
  complete: "complete",
  account_closed: "account_closed"
}, Sf = {
  physical: "physical",
  virtual: "virtual"
}, vf = {
  no_opt_in: "no_opt_in",
  opt_in: "opt_in",
  onboarding: "onboarding",
  offer_expired: "offer_expired",
  kyc_review: "kyc_review",
  kyc_failed: "kyc_failed",
  verify_bank: "verify_bank",
  reverify_bank: "reverify_bank",
  no_transaction: "no_transaction",
  complete: "complete",
  account_closed: "account_closed"
}, Df = {
  merchant_cash_advance: "merchant_cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  bnpl: "bnpl"
}, Af = {
  created: "created",
  submitted: "submitted",
  approved: "approved",
  denied: "denied",
  abandoned: "abandoned",
  confirmed: "confirmed",
  funded: "funded"
}, Uf = {
  seen_accounting_integrations: "seen_accounting_integrations"
}, Nf = {
  outstanding: "outstanding",
  paid: "paid",
  abandoned: "abandoned",
  cancelled: "cancelled",
  waived: "waived",
  void: "void"
}, Gf = {
  merchant_cash_advance: "merchant_cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan"
}, Tf = {
  "1month": "1month",
  "2month": "2month",
  "3month": "3month",
  "4month": "4month",
  "5month": "5month",
  "6month": "6month",
  "7month": "7month",
  "8month": "8month",
  "9month": "9month",
  "10month": "10month",
  "11month": "11month",
  "12month": "12month",
  "13month": "13month",
  "14month": "14month",
  "15month": "15month",
  "16month": "16month",
  "17month": "17month",
  "18month": "18month",
  "19month": "19month",
  "20month": "20month",
  "21month": "21month",
  "22month": "22month",
  "23month": "23month",
  "24month": "24month"
}, If = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  minimum_amount: "minimum_amount"
}, wf = {
  healthy: "healthy",
  unhealthy: "unhealthy"
}, Qf = {
  merchant_cash_advance: "merchant_cash_advance",
  amazon_mca: "amazon_mca",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  bnpl: "bnpl"
}, Lf = {
  application_created: "application_created",
  application_progress: "application_progress",
  manual_review: "manual_review",
  denied: "denied",
  multiple_manual_review: "multiple_manual_review",
  underwriting_manual_review: "underwriting_manual_review",
  underwriting_denial: "underwriting_denial",
  multiple_denied_reasons: "multiple_denied_reasons",
  kyc_manual_review: "kyc_manual_review",
  kyc_denial: "kyc_denial",
  bank_statement_upload_manual_review: "bank_statement_upload_manual_review",
  plaid_investigation_manual_review: "plaid_investigation_manual_review",
  funding_check_manual_review: "funding_check_manual_review",
  funding_check_denial: "funding_check_denial",
  auto_approval: "auto_approval",
  manual_review_approval: "manual_review_approval",
  manual_review_denial: "manual_review_denial",
  offer_refreshed: "offer_refreshed",
  offer_accepted: "offer_accepted",
  funding_completed: "funding_completed",
  expired: "expired",
  abandoned: "abandoned",
  cancelled: "cancelled",
  deactivate_for_modified_offer: "deactivate_for_modified_offer",
  withdrawn: "withdrawn",
  offer_pulled: "offer_pulled",
  consistency: "consistency",
  unsupported: "unsupported",
  metadata_update: "metadata_update"
}, Wf = {
  created: "created",
  submitted: "submitted",
  approved: "approved",
  denied: "denied",
  abandoned: "abandoned",
  confirmed: "confirmed",
  funded: "funded"
}, Vf = {
  in_progress: "in_progress",
  manual_review: "manual_review",
  approved: "approved",
  denied: "denied",
  expired: "expired",
  cancelled: "cancelled",
  deactivate_for_modified_offer: "deactivate_for_modified_offer",
  withdrawn: "withdrawn",
  offer_accepted: "offer_accepted",
  funded: "funded"
}, $f = {
  principal: "principal",
  fee: "fee",
  payment: "payment",
  minimum_payment: "minimum_payment",
  automatic_payment: "automatic_payment",
  manual_payment: "manual_payment",
  generic: "generic",
  payment_adjustment: "payment_adjustment"
}, zf = {
  card: "card",
  cash_advance: "cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  bnpl: "bnpl",
  merchant_bank: "merchant_bank"
}, Hf = {
  new: "new",
  verified: "verified"
}, Yf = {
  full: "full",
  partial: "partial",
  none: "none",
  partner_does_not_have: "partner_does_not_have"
}, Xf = {
  withdrawn: "withdrawn",
  expired: "expired",
  canceled: "canceled",
  deactivate_for_modified_offer: "deactivate_for_modified_offer"
}, jf = {
  bankruptcy: "bankruptcy",
  excessive_credit_obligations: "excessive_credit_obligations",
  fraud: "fraud",
  insufficient_sales: "insufficient_sales",
  recent_sales_volatility: "recent_sales_volatility",
  liens: "liens",
  problematic_bank_account: "problematic_bank_account",
  problematic_payment_history: "problematic_payment_history",
  too_many_liens: "too_many_liens",
  unpaid_prior_account: "unpaid_prior_account",
  unserviceable_bank_account: "unserviceable_bank_account",
  kyc: "kyc",
  unlicensed_business: "unlicensed_business",
  program_terminated: "program_terminated",
  problematic_payment_history_with_others: "problematic_payment_history_with_others",
  collection_action_or_judgement: "collection_action_or_judgement",
  la_wildfire_jan2025: "la_wildfire_jan2025",
  temporary_program_termination: "temporary_program_termination",
  "": ""
}, Zf = {
  HOME: "HOME",
  WORK: "WORK",
  LEGAL: "LEGAL",
  SHIPPING: "SHIPPING",
  PHYSICAL: "PHYSICAL"
}, Jf = {
  celtic_bank: "celtic_bank",
  parafin: "parafin",
  parafin_spv_1: "parafin_spv_1",
  parafin_spv_2: "parafin_spv_2",
  parafin_spv_3: "parafin_spv_3"
}, O = (t, e) => a.get(
  "/business_cores",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), f = (t) => ["/business_cores", ...t ? [t] : []], h = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? f(t), queryFn: ({ signal: u }) => O(t, { signal: u, ...r }), ...n };
};
function th(t, e) {
  const n = h(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const K = (t, e) => a.post(
  "/business_cores",
  t,
  e
), P = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return K(o, n);
  }, ...e };
}, eh = (t) => {
  const e = P(t);
  return c(e);
}, b = (t, e) => a.get(
  "/business_annotations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), x = (t) => ["/business_annotations", ...t ? [t] : []], M = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? x(t), queryFn: ({ signal: u }) => b(t, { signal: u, ...r }), ...n };
};
function nh(t, e) {
  const n = M(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const F = (t, e) => a.post(
  "/business_annotations",
  t,
  e
), k = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return F(o, n);
  }, ...e };
}, rh = (t) => {
  const e = k(t);
  return c(e);
}, R = (t, e) => a.get(
  "/partners/doordash/admin_users",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), B = (t) => ["/partners/doordash/admin_users", ...t ? [t] : []], C = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? B(t), queryFn: ({ signal: u }) => R(t, { signal: u, ...r }), ...n };
};
function sh(t, e) {
  const n = C(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const E = (t, e) => a.get(
  "/cash_advance_pauses",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), S = (t) => ["/cash_advance_pauses", ...t ? [t] : []], v = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? S(t), queryFn: ({ signal: u }) => E(t, { signal: u, ...r }), ...n };
};
function oh(t, e) {
  const n = v(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const D = (t, e) => a.post(
  "/cash_advance_pauses",
  t,
  e
), A = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return D(o, n);
  }, ...e };
}, ah = (t) => {
  const e = A(t);
  return c(e);
}, U = (t, e) => a.delete(
  `/cash_advance_pauses/${t}`,
  e
), N = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { p1: o } = s ?? {};
    return U(o, n);
  }, ...e };
}, uh = (t) => {
  const e = N(t);
  return c(e);
}, G = (t, e, n) => a.patch(
  `/cash_advance_pauses/${t}`,
  e,
  n
), T = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { p1: o, data: u } = s ?? {};
    return G(o, u, n);
  }, ...e };
}, ih = (t) => {
  const e = T(t);
  return c(e);
}, I = (t, e) => a.get(
  "/repayment_plan_parameters",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), w = (t) => ["/repayment_plan_parameters", ...t ? [t] : []], Q = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? w(t), queryFn: ({ signal: u }) => I(t, { signal: u, ...r }), ...n };
};
function ch(t, e) {
  const n = Q(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const L = (t, e) => a.get(
  "/future_activities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), W = (t) => ["/future_activities", ...t ? [t] : []], V = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? W(t), queryFn: ({ signal: u }) => L(t, { signal: u, ...r }), ...n };
};
function yh(t, e) {
  const n = V(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const $ = (t, e) => a.get(
  "/partners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), z = (t) => ["/partners", ...t ? [t] : []], H = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? z(t), queryFn: ({ signal: u }) => $(t, { signal: u, ...r }), ...n };
};
function dh(t, e) {
  const n = H(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Y = (t, e) => a.post(
  "/partners",
  t,
  e
), X = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Y(o, n);
  }, ...e };
}, ph = (t) => {
  const e = X(t);
  return c(e);
}, j = (t) => a.get(
  "/partners/underwriting",
  t
), Z = () => ["/partners/underwriting"], J = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Z(), queryFn: ({ signal: o }) => j({ signal: o, ...n }), ...e };
};
function _h(t) {
  const e = J(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const tt = (t, e, n) => a.patch(
  `/partners/${t}`,
  e,
  n
), et = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return tt(o, u, n);
  }, ...e };
}, mh = (t) => {
  const e = et(t);
  return c(e);
}, nt = (t, e) => a.get(
  "/partner/api_keys",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), rt = (t) => ["/partner/api_keys", ...t ? [t] : []], st = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? rt(t), queryFn: ({ signal: u }) => nt(t, { signal: u, ...r }), ...n };
};
function lh(t, e) {
  const n = st(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ot = (t, e) => a.post(
  "/partner/api_keys",
  t,
  e
), at = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ot(o, n);
  }, ...e };
}, gh = (t) => {
  const e = at(t);
  return c(e);
}, ut = (t) => a.get(
  "/partner/organizations",
  t
), it = () => ["/partner/organizations"], ct = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? it(), queryFn: ({ signal: o }) => ut({ signal: o, ...n }), ...e };
};
function qh(t) {
  const e = ct(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const yt = (t, e) => a.post(
  "/partner/organizations",
  t,
  e
), dt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return yt(o, n);
  }, ...e };
}, Oh = (t) => {
  const e = dt(t);
  return c(e);
}, pt = (t, e) => a.post(
  "/partner/organizations/link_partner",
  t,
  e
), _t = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return pt(o, n);
  }, ...e };
}, fh = (t) => {
  const e = _t(t);
  return c(e);
}, mt = (t, e) => a.get(
  "/partner/organizations/partners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), lt = (t) => ["/partner/organizations/partners", ...t ? [t] : []], gt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? lt(t), queryFn: ({ signal: u }) => mt(t, { signal: u, ...r }), ...n };
};
function hh(t, e) {
  const n = gt(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const qt = (t, e) => a.post(
  "/slack/authorize",
  t,
  e
), Ot = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return qt(o, n);
  }, ...e };
}, Kh = (t) => {
  const e = Ot(t);
  return c(e);
}, ft = (t) => a.get(
  "/slack/channels",
  t
), ht = () => ["/slack/channels"], Kt = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ht(), queryFn: ({ signal: o }) => ft({ signal: o, ...n }), ...e };
};
function Ph(t) {
  const e = Kt(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Pt = (t, e) => a.delete(
  `/slack/channels/${t}`,
  e
), bt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return Pt(o, n);
  }, ...e };
}, bh = (t) => {
  const e = bt(t);
  return c(e);
}, xt = (t) => a.get(
  "/partner/users",
  t
), Mt = () => ["/partner/users"], Ft = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Mt(), queryFn: ({ signal: o }) => xt({ signal: o, ...n }), ...e };
};
function xh(t) {
  const e = Ft(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const kt = (t, e) => a.post(
  "/partner/users",
  t,
  e
), Rt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return kt(o, n);
  }, ...e };
}, Mh = (t) => {
  const e = Rt(t);
  return c(e);
}, Bt = (t, e) => a.patch(
  "/partner/users",
  t,
  e
), Ct = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Bt(o, n);
  }, ...e };
}, Fh = (t) => {
  const e = Ct(t);
  return c(e);
}, Et = (t, e) => a.delete(
  "/partner/delete_users",
  { data: t, ...e }
), St = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Et(o, n);
  }, ...e };
}, kh = (t) => {
  const e = St(t);
  return c(e);
}, vt = (t, e) => a.delete(
  "/organization/delete_users",
  { data: t, ...e }
), Dt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return vt(o, n);
  }, ...e };
}, Rh = (t) => {
  const e = Dt(t);
  return c(e);
}, At = (t, e) => a.post(
  "/partner/users/v2",
  t,
  e
), Ut = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return At(o, n);
  }, ...e };
}, Bh = (t) => {
  const e = Ut(t);
  return c(e);
}, Nt = (t, e) => a.patch(
  "/partner/users/v2",
  t,
  e
), Gt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Nt(o, n);
  }, ...e };
}, Ch = (t) => {
  const e = Gt(t);
  return c(e);
}, Tt = (t, e) => a.get(
  "/partner/public_configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), It = (t) => ["/partner/public_configs", ...t ? [t] : []], wt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? It(t), queryFn: ({ signal: u }) => Tt(t, { signal: u, ...r }), ...n };
};
function Eh(t, e) {
  const n = wt(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Qt = (t, e) => a.get(
  "/partner/configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Lt = (t) => ["/partner/configs", ...t ? [t] : []], Wt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Lt(t), queryFn: ({ signal: u }) => Qt(t, { signal: u, ...r }), ...n };
};
function Sh(t, e) {
  const n = Wt(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Vt = (t, e, n) => a.patch(
  `/partner/configs/${t}`,
  e,
  n
), $t = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Vt(o, u, n);
  }, ...e };
}, vh = (t) => {
  const e = $t(t);
  return c(e);
}, zt = (t, e) => a.post(
  "/auth/tokens",
  t,
  e
), Ht = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return zt(o, n);
  }, ...e };
}, Dh = (t) => {
  const e = Ht(t);
  return c(e);
}, Yt = (t, e) => a.get(
  "/statements_v2",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xt = (t) => ["/statements_v2", ...t ? [t] : []], jt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xt(t), queryFn: ({ signal: u }) => Yt(t, { signal: u, ...r }), ...n };
};
function Ah(t, e) {
  const n = jt(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Zt = (t, e) => a.post(
  "/marketing/review",
  t,
  e
), Jt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Zt(o, n);
  }, ...e };
}, Uh = (t) => {
  const e = Jt(t);
  return c(e);
}, te = (t) => a.get(
  "/marketing/integrations",
  t
), ee = () => ["/marketing/integrations"], ne = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ee(), queryFn: ({ signal: o }) => te({ signal: o, ...n }), ...e };
};
function Nh(t) {
  const e = ne(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const re = (t, e) => a.post(
  "/marketing/integrations",
  t,
  e
), se = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return re(o, n);
  }, ...e };
}, Gh = (t) => {
  const e = se(t);
  return c(e);
}, oe = (t, e) => a.delete(
  `/marketing/integrations/${t}`,
  e
), ae = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { vendor: o } = s ?? {};
    return oe(o, n);
  }, ...e };
}, Th = (t) => {
  const e = ae(t);
  return c(e);
}, ue = (t) => a.get(
  "/marketing/gtm_toolkit",
  t
), ie = () => ["/marketing/gtm_toolkit"], ce = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ie(), queryFn: ({ signal: o }) => ue({ signal: o, ...n }), ...e };
};
function Ih(t) {
  const e = ce(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const ye = (t) => a.get(
  "/marketing/paragon_token",
  t
), de = () => ["/marketing/paragon_token"], pe = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? de(), queryFn: ({ signal: o }) => ye({ signal: o, ...n }), ...e };
};
function wh(t) {
  const e = pe(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const _e = (t, e) => a.get(
  "/beneficial_owners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), me = (t) => ["/beneficial_owners", ...t ? [t] : []], le = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? me(t), queryFn: ({ signal: u }) => _e(t, { signal: u, ...r }), ...n };
};
function Qh(t, e) {
  const n = le(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ge = (t, e) => a.post(
  "/beneficial_owners",
  t,
  e
), qe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ge(o, n);
  }, ...e };
}, Lh = (t) => {
  const e = qe(t);
  return c(e);
}, Oe = (t, e) => a.delete(
  `/beneficial_owners/${t}`,
  e
), fe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return Oe(o, n);
  }, ...e };
}, Wh = (t) => {
  const e = fe(t);
  return c(e);
}, he = (t, e, n) => a.patch(
  `/beneficial_owners/${t}`,
  e,
  n
), Ke = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return he(o, u, n);
  }, ...e };
}, Vh = (t) => {
  const e = Ke(t);
  return c(e);
}, Pe = (t, e) => a.get(
  "/owner_is_beneficial_owner",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), be = (t) => ["/owner_is_beneficial_owner", ...t ? [t] : []], xe = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? be(t), queryFn: ({ signal: u }) => Pe(t, { signal: u, ...r }), ...n };
};
function $h(t, e) {
  const n = xe(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Me = (t, e) => a.patch(
  "/owner_is_beneficial_owner",
  t,
  e
), Fe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Me(o, n);
  }, ...e };
}, zh = (t) => {
  const e = Fe(t);
  return c(e);
}, ke = (t, e) => a.get(
  "/banks",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Re = (t) => ["/banks", ...t ? [t] : []], Be = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Re(t), queryFn: ({ signal: u }) => ke(t, { signal: u, ...r }), ...n };
};
function Hh(t, e) {
  const n = Be(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ce = (t, e) => a.get(
  "/business_table_rows/product_agnostic",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ee = (t) => ["/business_table_rows/product_agnostic", ...t ? [t] : []], Se = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ee(t), queryFn: ({ signal: u }) => Ce(t, { signal: u, ...r }), ...n };
};
function Yh(t, e) {
  const n = Se(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ve = (t, e) => a.get(
  "/business_table_rows/mca",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), De = (t) => ["/business_table_rows/mca", ...t ? [t] : []], Ae = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? De(t), queryFn: ({ signal: u }) => ve(t, { signal: u, ...r }), ...n };
};
function Xh(t, e) {
  const n = Ae(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ue = (t, e) => a.get(
  "/business_table_rows/loan",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ne = (t) => ["/business_table_rows/loan", ...t ? [t] : []], Ge = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ne(t), queryFn: ({ signal: u }) => Ue(t, { signal: u, ...r }), ...n };
};
function jh(t, e) {
  const n = Ge(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Te = (t, e) => a.get(
  "/business_table_rows/card",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ie = (t) => ["/business_table_rows/card", ...t ? [t] : []], we = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ie(t), queryFn: ({ signal: u }) => Te(t, { signal: u, ...r }), ...n };
};
function Zh(t, e) {
  const n = we(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Qe = (t, e) => a.get(
  "/business/officers",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Le = (t) => ["/business/officers", ...t ? [t] : []], We = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Le(t), queryFn: ({ signal: u }) => Qe(t, { signal: u, ...r }), ...n };
};
function Jh(t, e) {
  const n = We(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ve = (t, e) => a.post(
  "/business/officers",
  t,
  e
), $e = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ve(o, n);
  }, ...e };
}, tK = (t) => {
  const e = $e(t);
  return c(e);
}, ze = (t, e) => a.delete(
  `/business/officers/${t}`,
  e
), He = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return ze(o, n);
  }, ...e };
}, eK = (t) => {
  const e = He(t);
  return c(e);
}, Ye = (t, e, n) => a.patch(
  `/business/officers/${t}`,
  e,
  n
), Xe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Ye(o, u, n);
  }, ...e };
}, nK = (t) => {
  const e = Xe(t);
  return c(e);
}, je = (t) => a.post(
  "/dataingestion/oneschema/generate_jwt",
  void 0,
  t
), Ze = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => je(n), ...e };
}, rK = (t) => {
  const e = Ze(t);
  return c(e);
}, Je = (t, e) => a.get(
  "/dataset_uploads",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), tn = (t) => ["/dataset_uploads", ...t ? [t] : []], en = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? tn(t), queryFn: ({ signal: u }) => Je(t, { signal: u, ...r }), ...n };
};
function sK(t, e) {
  const n = en(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const nn = (t, e) => a.get(
  "/dataingestion/manual_uploads/dataset_upload_validation_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), rn = (t) => ["/dataingestion/manual_uploads/dataset_upload_validation_results", ...t ? [t] : []], sn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? rn(t), queryFn: ({ signal: u }) => nn(t, { signal: u, ...r }), ...n };
};
function oK(t, e) {
  const n = sn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const on = (t, e) => a.get(
  "/dataingestion/s3/dataset_uploads",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), an = (t) => ["/dataingestion/s3/dataset_uploads", ...t ? [t] : []], un = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? an(t), queryFn: ({ signal: u }) => on(t, { signal: u, ...r }), ...n };
};
function aK(t, e) {
  const n = un(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const cn = (t, e) => a.get(
  "/dataingestion/s3/dataset_upload_validation_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), yn = (t) => ["/dataingestion/s3/dataset_upload_validation_results", ...t ? [t] : []], dn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? yn(t), queryFn: ({ signal: u }) => cn(t, { signal: u, ...r }), ...n };
};
function uK(t, e) {
  const n = dn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const pn = (t, e) => a.get(
  "/dataingestion/s3/dataset_integrations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _n = (t) => ["/dataingestion/s3/dataset_integrations", ...t ? [t] : []], mn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _n(t), queryFn: ({ signal: u }) => pn(t, { signal: u, ...r }), ...n };
};
function iK(t, e) {
  const n = mn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ln = (t, e) => a.post(
  "/dataingestion/s3/dataset_integrations",
  t,
  e
), gn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ln(o, n);
  }, ...e };
}, cK = (t) => {
  const e = gn(t);
  return c(e);
}, qn = (t, e) => a.get(
  "/dataingestion/s3/org_dataset_integrations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), On = (t) => ["/dataingestion/s3/org_dataset_integrations", ...t ? [t] : []], fn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? On(t), queryFn: ({ signal: u }) => qn(t, { signal: u, ...r }), ...n };
};
function yK(t, e) {
  const n = fn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const hn = (t, e) => a.post(
  "/dataingestion/s3/org_dataset_integrations",
  t,
  e
), Kn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return hn(o, n);
  }, ...e };
}, dK = (t) => {
  const e = Kn(t);
  return c(e);
}, Pn = (t, e) => a.get(
  "/dataingestion/s3/dataset_ingestion_configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), bn = (t) => ["/dataingestion/s3/dataset_ingestion_configs", ...t ? [t] : []], xn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? bn(t), queryFn: ({ signal: u }) => Pn(t, { signal: u, ...r }), ...n };
};
function pK(t, e) {
  const n = xn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Mn = (t, e) => a.put(
  "/dataingestion/s3/dataset_ingestion_configs",
  t,
  e
), Fn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Mn(o, n);
  }, ...e };
}, _K = (t) => {
  const e = Fn(t);
  return c(e);
}, kn = (t, e) => a.post(
  "/dataingestion/s3/run_dataset_ingestion",
  t,
  e
), Rn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return kn(o, n);
  }, ...e };
}, mK = (t) => {
  const e = Rn(t);
  return c(e);
}, Bn = (t, e) => a.get(
  "/dataingestion/schema",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Cn = (t) => ["/dataingestion/schema", ...t ? [t] : []], En = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Cn(t), queryFn: ({ signal: u }) => Bn(t, { signal: u, ...r }), ...n };
};
function lK(t, e) {
  const n = En(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Sn = (t, e) => a.get(
  "/dataingestion/stripe_ingestions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), vn = (t) => ["/dataingestion/stripe_ingestions", ...t ? [t] : []], Dn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? vn(t), queryFn: ({ signal: u }) => Sn(t, { signal: u, ...r }), ...n };
};
function gK(t, e) {
  const n = Dn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const An = (t, e) => a.post(
  "/dataingestion/stripe_ingestions",
  t,
  e
), Un = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return An(o, n);
  }, ...e };
}, qK = (t) => {
  const e = Un(t);
  return c(e);
}, Nn = (t, e) => a.patch(
  "/dataingestion/stripe_ingestions",
  t,
  e
), Gn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Nn(o, n);
  }, ...e };
}, OK = (t) => {
  const e = Gn(t);
  return c(e);
}, Tn = (t, e) => a.get(
  "/onboarding/business_requirements",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), In = (t) => ["/onboarding/business_requirements", ...t ? [t] : []], wn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? In(t), queryFn: ({ signal: u }) => Tn(t, { signal: u, ...r }), ...n };
};
function fK(t, e) {
  const n = wn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Qn = (t, e) => a.get(
  "/capital_product_prospective_terms_of_service",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ln = (t) => ["/capital_product_prospective_terms_of_service", ...t ? [t] : []], Wn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ln(t), queryFn: ({ signal: u }) => Qn(t, { signal: u, ...r }), ...n };
};
function hK(t, e) {
  const n = Wn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Vn = (t, e) => a.get(
  "/capital_products",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $n = (t) => ["/capital_products", ...t ? [t] : []], zn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $n(t), queryFn: ({ signal: u }) => Vn(t, { signal: u, ...r }), ...n };
};
function KK(t, e) {
  const n = zn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Hn = (t, e) => a.post(
  "/capital_products",
  t,
  e
), Yn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Hn(o, n);
  }, ...e };
}, PK = (t) => {
  const e = Yn(t);
  return c(e);
}, Xn = (t, e, n) => a.patch(
  `/capital_products/${t}`,
  e,
  n
), jn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Xn(o, u, n);
  }, ...e };
}, bK = (t) => {
  const e = jn(t);
  return c(e);
}, Zn = (t, e) => a.get(
  "/capital_products_summary",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Jn = (t) => ["/capital_products_summary", ...t ? [t] : []], tr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Jn(t), queryFn: ({ signal: u }) => Zn(t, { signal: u, ...r }), ...n };
};
function xK(t, e) {
  const n = tr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const er = (t) => a.get(
  "/capital_product_application_amount_summary",
  t
), nr = () => ["/capital_product_application_amount_summary"], rr = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? nr(), queryFn: ({ signal: o }) => er({ signal: o, ...n }), ...e };
};
function MK(t) {
  const e = rr(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const sr = (t, e) => a.get(
  "/capital_product_offer_collections",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), or = (t) => ["/capital_product_offer_collections", ...t ? [t] : []], ar = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? or(t), queryFn: ({ signal: u }) => sr(t, { signal: u, ...r }), ...n };
};
function FK(t, e) {
  const n = ar(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ur = (t, e) => a.post(
  "/capital_product_offer_collections",
  t,
  e
), ir = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ur(o, n);
  }, ...e };
}, kK = (t) => {
  const e = ir(t);
  return c(e);
}, cr = (t, e) => a.get(
  "/capital_product_activities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), yr = (t) => ["/capital_product_activities", ...t ? [t] : []], dr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? yr(t), queryFn: ({ signal: u }) => cr(t, { signal: u, ...r }), ...n };
};
function RK(t, e) {
  const n = dr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const pr = (t, e) => a.get(
  "/capital_product_applications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _r = (t) => ["/capital_product_applications", ...t ? [t] : []], mr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _r(t), queryFn: ({ signal: u }) => pr(t, { signal: u, ...r }), ...n };
};
function BK(t, e) {
  const n = mr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const lr = (t, e) => a.post(
  "/capital_product_applications",
  t,
  e
), gr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return lr(o, n);
  }, ...e };
}, CK = (t) => {
  const e = gr(t);
  return c(e);
}, qr = (t, e) => a.get(
  "/capital_product_application_snapshots",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Or = (t) => ["/capital_product_application_snapshots", ...t ? [t] : []], fr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Or(t), queryFn: ({ signal: u }) => qr(t, { signal: u, ...r }), ...n };
};
function EK(t, e) {
  const n = fr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const hr = (t, e) => a.post(
  "/net_top_up",
  t,
  e
), Kr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return hr(o, n);
  }, ...e };
}, SK = (t) => {
  const e = Kr(t);
  return c(e);
}, Pr = (t, e) => a.get(
  "/capital_product_net_top_up_itemization",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), br = (t) => ["/capital_product_net_top_up_itemization", ...t ? [t] : []], xr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? br(t), queryFn: ({ signal: u }) => Pr(t, { signal: u, ...r }), ...n };
};
function vK(t, e) {
  const n = xr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Mr = (t, e) => a.get(
  "/capital_product_state_disclosure",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Fr = (t) => ["/capital_product_state_disclosure", ...t ? [t] : []], kr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Fr(t), queryFn: ({ signal: u }) => Mr(t, { signal: u, ...r }), ...n };
};
function DK(t, e) {
  const n = kr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Rr = (t) => a.get(
  "/benchmark_capital_products_ownerships",
  t
), Br = () => ["/benchmark_capital_products_ownerships"], Cr = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Br(), queryFn: ({ signal: o }) => Rr({ signal: o, ...n }), ...e };
};
function AK(t) {
  const e = Cr(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Er = (t, e) => a.get(
  "/capital_product_repayment_schedule",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Sr = (t) => ["/capital_product_repayment_schedule", ...t ? [t] : []], vr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Sr(t), queryFn: ({ signal: u }) => Er(t, { signal: u, ...r }), ...n };
};
function UK(t, e) {
  const n = vr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Dr = (t, e) => a.post(
  "/offered_capital_product_discount",
  t,
  e
), Ar = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Dr(o, n);
  }, ...e };
}, NK = (t) => {
  const e = Ar(t);
  return c(e);
}, Ur = (t, e) => a.get(
  "/capital_product_intents",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Nr = (t) => ["/capital_product_intents", ...t ? [t] : []], Gr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Nr(t), queryFn: ({ signal: u }) => Ur(t, { signal: u, ...r }), ...n };
};
function GK(t, e) {
  const n = Gr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Tr = (t, e) => a.post(
  "/capital_product_intents",
  t,
  e
), Ir = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Tr(o, n);
  }, ...e };
}, TK = (t) => {
  const e = Ir(t);
  return c(e);
}, wr = (t, e, n) => a.patch(
  `/capital_product_intents/${t}`,
  e,
  n
), Qr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return wr(o, u, n);
  }, ...e };
}, IK = (t) => {
  const e = Qr(t);
  return c(e);
}, Lr = (t, e) => a.get(
  "/counteroffer",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Wr = (t) => ["/counteroffer", ...t ? [t] : []], Vr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wr(t), queryFn: ({ signal: u }) => Lr(t, { signal: u, ...r }), ...n };
};
function wK(t, e) {
  const n = Vr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const $r = (t, e) => a.post(
  "/capital_product_super_applications",
  t,
  e
), zr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return $r(o, n);
  }, ...e };
}, QK = (t) => {
  const e = zr(t);
  return c(e);
}, Hr = (t, e) => a.post(
  "/capital_product_super_application_actions",
  t,
  e
), Yr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Hr(o, n);
  }, ...e };
}, LK = (t) => {
  const e = Yr(t);
  return c(e);
}, Xr = (t, e, n) => a.get(
  `/capital_product_offer_chunk_calculated/${t}`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), jr = (t, e) => [`/capital_product_offer_chunk_calculated/${t}`, ...e ? [e] : []], Zr = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? jr(t, e), queryFn: ({ signal: y }) => Xr(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function WK(t, e, n) {
  const r = Zr(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const Jr = (t, e) => a.get(
  `/capital_product_disputes/${t}`,
  e
), ts = (t) => [`/capital_product_disputes/${t}`], es = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ts(t), queryFn: ({ signal: u }) => Jr(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function VK(t, e) {
  const n = es(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ns = (t, e, n) => a.patch(
  `/capital_product_disputes/${t}`,
  e,
  n
), rs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { disputeId: o, data: u } = s ?? {};
    return ns(o, u, n);
  }, ...e };
}, $K = (t) => {
  const e = rs(t);
  return c(e);
}, ss = (t, e) => a.get(
  "/capital_product_disputes",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), os = (t) => ["/capital_product_disputes", ...t ? [t] : []], as = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? os(t), queryFn: ({ signal: u }) => ss(t, { signal: u, ...r }), ...n };
};
function zK(t, e) {
  const n = as(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const us = (t, e) => a.post(
  "/capital_product_disputes",
  t,
  e
), is = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return us(o, n);
  }, ...e };
}, HK = (t) => {
  const e = is(t);
  return c(e);
}, cs = (t, e) => a.get(
  "/flex_loan_minimum_repayment_schedule",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ys = (t) => ["/flex_loan_minimum_repayment_schedule", ...t ? [t] : []], ds = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ys(t), queryFn: ({ signal: u }) => cs(t, { signal: u, ...r }), ...n };
};
function YK(t, e) {
  const n = ds(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ps = (t, e) => a.post(
  "/trigger_run_celtics_checklist",
  t,
  e
), _s = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ps(o, n);
  }, ...e };
}, XK = (t) => {
  const e = _s(t);
  return c(e);
}, ms = (t, e) => a.get(
  "/run_ofac_screening_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ls = (t) => ["/run_ofac_screening_results", ...t ? [t] : []], gs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ls(t), queryFn: ({ signal: u }) => ms(t, { signal: u, ...r }), ...n };
};
function jK(t, e) {
  const n = gs(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const qs = (t, e, n) => a.patch(
  `/clear_watchlist_hits/${t}`,
  e,
  n
), Os = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return qs(o, u, n);
  }, ...e };
}, ZK = (t) => {
  const e = Os(t);
  return c(e);
}, fs = (t, e) => a.get(
  "/term_loan_prospective_repayments",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), hs = (t) => ["/term_loan_prospective_repayments", ...t ? [t] : []], Ks = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hs(t), queryFn: ({ signal: u }) => fs(t, { signal: u, ...r }), ...n };
};
function JK(t, e) {
  const n = Ks(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ps = (t, e) => a.get(
  "/funding/bank_account_info",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), bs = (t) => ["/funding/bank_account_info", ...t ? [t] : []], xs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? bs(t), queryFn: ({ signal: u }) => Ps(t, { signal: u, ...r }), ...n };
};
function tP(t, e) {
  const n = xs(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ms = (t, e) => a.post(
  "/funding/bank_account_info",
  t,
  e
), Fs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ms(o, n);
  }, ...e };
}, eP = (t) => {
  const e = Fs(t);
  return c(e);
}, ks = (t, e, n) => a.patch(
  `/funding/bank_account_info/${t}`,
  e,
  n
), Rs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return ks(o, u, n);
  }, ...e };
}, nP = (t) => {
  const e = Rs(t);
  return c(e);
}, Bs = (t, e) => a.get(
  "/funding/accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Cs = (t) => ["/funding/accounts", ...t ? [t] : []], Es = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Cs(t), queryFn: ({ signal: u }) => Bs(t, { signal: u, ...r }), ...n };
};
function rP(t, e) {
  const n = Es(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ss = (t, e) => a.post(
  "/funding/accounts",
  t,
  e
), vs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ss(o, n);
  }, ...e };
}, sP = (t) => {
  const e = vs(t);
  return c(e);
}, Ds = (t, e) => a.get(
  "/funding/plaid_linkage_with_investigation",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), As = (t) => ["/funding/plaid_linkage_with_investigation", ...t ? [t] : []], Us = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? As(t), queryFn: ({ signal: u }) => Ds(t, { signal: u, ...r }), ...n };
};
function oP(t, e) {
  const n = Us(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ns = (t, e) => a.post(
  "/funding/plaid_linkage_with_investigation",
  t,
  e
), Gs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ns(o, n);
  }, ...e };
}, aP = (t) => {
  const e = Gs(t);
  return c(e);
}, Ts = (t, e, n) => a.patch(
  `/persona_fallback_inquiries/${t}`,
  e,
  n
), Is = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Ts(o, u, n);
  }, ...e };
}, uP = (t) => {
  const e = Is(t);
  return c(e);
}, ws = (t, e) => a.get(
  "/kyc_retry_fields",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Qs = (t) => ["/kyc_retry_fields", ...t ? [t] : []], Ls = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qs(t), queryFn: ({ signal: u }) => ws(t, { signal: u, ...r }), ...n };
};
function iP(t, e) {
  const n = Ls(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ws = (t, e) => a.post(
  "/kyc_retry",
  t,
  e
), Vs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ws(o, n);
  }, ...e };
}, cP = (t) => {
  const e = Vs(t);
  return c(e);
}, $s = (t, e) => a.get(
  "/onboarding/states",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), zs = (t) => ["/onboarding/states", ...t ? [t] : []], Hs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zs(t), queryFn: ({ signal: u }) => $s(t, { signal: u, ...r }), ...n };
};
function yP(t, e) {
  const n = Hs(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ys = (t, e) => a.get(
  "/onboarding/info_needed",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xs = (t) => ["/onboarding/info_needed", ...t ? [t] : []], js = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xs(t), queryFn: ({ signal: u }) => Ys(t, { signal: u, ...r }), ...n };
};
function dP(t, e) {
  const n = js(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Zs = (t, e) => a.get(
  "/business_identities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Js = (t) => ["/business_identities", ...t ? [t] : []], to = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Js(t), queryFn: ({ signal: u }) => Zs(t, { signal: u, ...r }), ...n };
};
function pP(t, e) {
  const n = to(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const eo = (t, e, n) => a.patch(
  `/business_identities/${t}`,
  e,
  n
), no = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return eo(o, u, n);
  }, ...e };
}, _P = (t) => {
  const e = no(t);
  return c(e);
}, ro = (t, e) => a.get(
  "/owners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), so = (t) => ["/owners", ...t ? [t] : []], oo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? so(t), queryFn: ({ signal: u }) => ro(t, { signal: u, ...r }), ...n };
};
function mP(t, e) {
  const n = oo(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ao = (t, e, n) => a.patch(
  `/owners/${t}`,
  e,
  n
), uo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return ao(o, u, n);
  }, ...e };
}, lP = (t) => {
  const e = uo(t);
  return c(e);
}, io = (t) => a.get(
  "/business_count",
  t
), co = () => ["/business_count"], yo = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? co(), queryFn: ({ signal: o }) => io({ signal: o, ...n }), ...e };
};
function gP(t) {
  const e = yo(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const po = (t, e) => a.post(
  "/business",
  t,
  e
), _o = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return po(o, n);
  }, ...e };
}, qP = (t) => {
  const e = _o(t);
  return c(e);
}, mo = (t, e) => a.post(
  "/merchant_bank/business",
  t,
  e
), lo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return mo(o, n);
  }, ...e };
}, OP = (t) => {
  const e = lo(t);
  return c(e);
}, go = (t) => a.post(
  "/amazon_redirect_url",
  void 0,
  t
), qo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => go(n), ...e };
}, fP = (t) => {
  const e = qo(t);
  return c(e);
}, Oo = (t, e) => a.post(
  "/spd",
  t,
  e
), fo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Oo(o, n);
  }, ...e };
}, hP = (t) => {
  const e = fo(t);
  return c(e);
}, ho = (t, e) => a.post(
  "/capital_product_business",
  t,
  e
), Ko = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ho(o, n);
  }, ...e };
}, KP = (t) => {
  const e = Ko(t);
  return c(e);
}, Po = (t, e) => a.patch(
  "/flex_loan_age",
  t,
  e
), bo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Po(o, n);
  }, ...e };
}, PP = (t) => {
  const e = bo(t);
  return c(e);
}, xo = (t, e) => a.patch(
  "/flex_loan_ownership",
  t,
  e
), Mo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return xo(o, n);
  }, ...e };
}, bP = (t) => {
  const e = Mo(t);
  return c(e);
}, Fo = (t, e) => a.patch(
  "/flex_loan_fund",
  t,
  e
), ko = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Fo(o, n);
  }, ...e };
}, xP = (t) => {
  const e = ko(t);
  return c(e);
}, Ro = (t, e) => a.post(
  "/setup_amazon_top_up",
  void 0,
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Bo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { params: o } = s ?? {};
    return Ro(o, n);
  }, ...e };
}, MP = (t) => {
  const e = Bo(t);
  return c(e);
}, Co = (t, e) => a.get(
  "/cards",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Eo = (t) => ["/cards", ...t ? [t] : []], So = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Eo(t), queryFn: ({ signal: u }) => Co(t, { signal: u, ...r }), ...n };
};
function FP(t, e) {
  const n = So(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const vo = (t, e) => a.post(
  "/cards",
  t,
  e
), Do = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return vo(o, n);
  }, ...e };
}, kP = (t) => {
  const e = Do(t);
  return c(e);
}, Ao = (t, e) => a.get(
  "/businesses/products",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Uo = (t) => ["/businesses/products", ...t ? [t] : []], No = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Uo(t), queryFn: ({ signal: u }) => Ao(t, { signal: u, ...r }), ...n };
};
function RP(t, e) {
  const n = No(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Go = (t, e) => a.post(
  "/business/auth_link",
  t,
  e
), To = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Go(o, n);
  }, ...e };
}, BP = (t) => {
  const e = To(t);
  return c(e);
}, Io = (t, e) => a.get(
  "/document_upload/group",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), wo = (t) => ["/document_upload/group", ...t ? [t] : []], Qo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? wo(t), queryFn: ({ signal: u }) => Io(t, { signal: u, ...r }), ...n };
};
function CP(t, e) {
  const n = Qo(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Lo = (t, e) => a.post(
  "/document_upload/group",
  t,
  e
), Wo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Lo(o, n);
  }, ...e };
}, EP = (t) => {
  const e = Wo(t);
  return c(e);
}, Vo = (t, e) => a.get(
  "/document_upload/list",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $o = (t) => ["/document_upload/list", ...t ? [t] : []], zo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $o(t), queryFn: ({ signal: u }) => Vo(t, { signal: u, ...r }), ...n };
};
function SP(t, e) {
  const n = zo(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ho = (t, e) => a.get(
  "/restriction",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Yo = (t) => ["/restriction", ...t ? [t] : []], Xo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Yo(t), queryFn: ({ signal: u }) => Ho(t, { signal: u, ...r }), ...n };
};
function vP(t, e) {
  const n = Xo(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const jo = (t, e) => a.post(
  "/restriction",
  t,
  e
), Zo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return jo(o, n);
  }, ...e };
}, DP = (t) => {
  const e = Zo(t);
  return c(e);
}, Jo = (t, e) => a.get(
  "/restrictions/details",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ta = (t) => ["/restrictions/details", ...t ? [t] : []], ea = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ta(t), queryFn: ({ signal: u }) => Jo(t, { signal: u, ...r }), ...n };
};
function AP(t, e) {
  const n = ea(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const na = (t, e) => a.get(
  "/liens",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ra = (t) => ["/liens", ...t ? [t] : []], sa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ra(t), queryFn: ({ signal: u }) => na(t, { signal: u, ...r }), ...n };
};
function UP(t, e) {
  const n = sa(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const oa = (t, e, n) => a.patch(
  `/liens/${t}`,
  e,
  n
), aa = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return oa(o, u, n);
  }, ...e };
}, NP = (t) => {
  const e = aa(t);
  return c(e);
}, ua = (t, e) => a.post(
  "/opt_ins/trigger",
  t,
  e
), ia = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ua(o, n);
  }, ...e };
}, GP = (t) => {
  const e = ia(t);
  return c(e);
}, ca = (t, e) => a.post(
  "/person/opt_in",
  t,
  e
), ya = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ca(o, n);
  }, ...e };
}, TP = (t) => {
  const e = ya(t);
  return c(e);
}, da = (t, e) => a.get(
  "/businesses/daily_sales_records",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), pa = (t) => ["/businesses/daily_sales_records", ...t ? [t] : []], _a = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? pa(t), queryFn: ({ signal: u }) => da(t, { signal: u, ...r }), ...n };
};
function IP(t, e) {
  const n = _a(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ma = (t, e) => a.get(
  "/parafinder/businesses",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), la = (t) => ["/parafinder/businesses", ...t ? [t] : []], ga = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? la(t), queryFn: ({ signal: u }) => ma(t, { signal: u, ...r }), ...n };
};
function wP(t, e) {
  const n = ga(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const qa = (t) => a.get(
  "/parafinder/business_types",
  t
), Oa = () => ["/parafinder/business_types"], fa = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Oa(), queryFn: ({ signal: o }) => qa({ signal: o, ...n }), ...e };
};
function QP(t) {
  const e = fa(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const ha = (t, e) => a.get(
  "/parafinder/summary",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ka = (t) => ["/parafinder/summary", ...t ? [t] : []], Pa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ka(t), queryFn: ({ signal: u }) => ha(t, { signal: u, ...r }), ...n };
};
function LP(t, e) {
  const n = Pa(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ba = (t, e) => a.get(
  "/parafinderV2/capital_info_summary",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), xa = (t) => ["/parafinderV2/capital_info_summary", ...t ? [t] : []], Ma = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? xa(t), queryFn: ({ signal: u }) => ba(t, { signal: u, ...r }), ...n };
};
function WP(t, e) {
  const n = Ma(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Fa = (t, e) => a.get(
  `/merchant_bank/accounts/${t}`,
  e
), ka = (t) => [`/merchant_bank/accounts/${t}`], Ra = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ka(t), queryFn: ({ signal: u }) => Fa(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function VP(t, e) {
  const n = Ra(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ba = (t, e) => a.get(
  `/merchant_bank/accounts/${t}/balances`,
  e
), Ca = (t) => [`/merchant_bank/accounts/${t}/balances`], Ea = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ca(t), queryFn: ({ signal: u }) => Ba(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function $P(t, e) {
  const n = Ea(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Sa = (t, e) => a.get(
  "/merchant_bank/accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), va = (t) => ["/merchant_bank/accounts", ...t ? [t] : []], Da = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? va(t), queryFn: ({ signal: u }) => Sa(t, { signal: u, ...r }), ...n };
};
function zP(t, e) {
  const n = Da(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Aa = (t, e) => a.post(
  "/merchant_bank/accounts",
  t,
  e
), Ua = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Aa(o, n);
  }, ...e };
}, HP = (t) => {
  const e = Ua(t);
  return c(e);
}, Na = (t, e) => a.post(
  "/merchant_bank/inner_transfers",
  t,
  e
), Ga = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Na(o, n);
  }, ...e };
}, YP = (t) => {
  const e = Ga(t);
  return c(e);
}, Ta = (t, e, n) => a.get(
  `/merchant_bank/transactions/${t}`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Ia = (t, e) => [`/merchant_bank/transactions/${t}`, ...e ? [e] : []], wa = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Ia(t, e), queryFn: ({ signal: y }) => Ta(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function XP(t, e, n) {
  const r = wa(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const Qa = (t, e) => a.get(
  "/merchant_bank/transactions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), La = (t) => ["/merchant_bank/transactions", ...t ? [t] : []], Wa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? La(t), queryFn: ({ signal: u }) => Qa(t, { signal: u, ...r }), ...n };
};
function jP(t, e) {
  const n = Wa(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Va = (t) => a.get(
  "/merchant_bank/transfer_fees",
  t
), $a = () => ["/merchant_bank/transfer_fees"], za = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? $a(), queryFn: ({ signal: o }) => Va({ signal: o, ...n }), ...e };
};
function ZP(t) {
  const e = za(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Ha = (t, e) => a.get(
  "/merchant_bank/transfer_limits",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ya = (t) => ["/merchant_bank/transfer_limits", ...t ? [t] : []], Xa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ya(t), queryFn: ({ signal: u }) => Ha(t, { signal: u, ...r }), ...n };
};
function JP(t, e) {
  const n = Xa(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ja = (t) => a.get(
  "/merchant_bank/interest",
  t
), Za = () => ["/merchant_bank/interest"], Ja = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Za(), queryFn: ({ signal: o }) => ja({ signal: o, ...n }), ...e };
};
function tb(t) {
  const e = Ja(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const tu = (t, e) => a.get(
  "/merchant_bank/recipients",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), eu = (t) => ["/merchant_bank/recipients", ...t ? [t] : []], nu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? eu(t), queryFn: ({ signal: u }) => tu(t, { signal: u, ...r }), ...n };
};
function eb(t, e) {
  const n = nu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ru = (t, e) => a.post(
  "/merchant_bank/recipients",
  t,
  e
), su = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ru(o, n);
  }, ...e };
}, nb = (t) => {
  const e = su(t);
  return c(e);
}, ou = (t, e) => a.get(
  `/merchant_bank/recipients/${t}`,
  e
), au = (t) => [`/merchant_bank/recipients/${t}`], uu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? au(t), queryFn: ({ signal: u }) => ou(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function rb(t, e) {
  const n = uu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const iu = (t, e) => a.delete(
  `/merchant_bank/recipients/${t}`,
  e
), cu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return iu(o, n);
  }, ...e };
}, sb = (t) => {
  const e = cu(t);
  return c(e);
}, yu = (t, e, n) => a.patch(
  `/merchant_bank/recipients/${t}`,
  e,
  n
), du = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return yu(o, u, n);
  }, ...e };
}, ob = (t) => {
  const e = du(t);
  return c(e);
}, pu = (t, e, n) => a.post(
  `/merchant_bank/recipients/${t}/payment_rails`,
  e,
  n
), _u = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return pu(o, u, n);
  }, ...e };
}, ab = (t) => {
  const e = _u(t);
  return c(e);
}, mu = (t, e) => a.post(
  "/merchant_bank/recipient_transfers",
  t,
  e
), lu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return mu(o, n);
  }, ...e };
}, ub = (t) => {
  const e = lu(t);
  return c(e);
}, gu = (t, e, n) => a.patch(
  `/merchant_bank/recipient_transfers/${t}`,
  e,
  n
), qu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return gu(o, u, n);
  }, ...e };
}, ib = (t) => {
  const e = qu(t);
  return c(e);
}, Ou = (t, e) => a.get(
  "/merchant_bank/cards",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), fu = (t) => ["/merchant_bank/cards", ...t ? [t] : []], hu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? fu(t), queryFn: ({ signal: u }) => Ou(t, { signal: u, ...r }), ...n };
};
function cb(t, e) {
  const n = hu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ku = (t, e) => a.post(
  "/merchant_bank/cards",
  t,
  e
), Pu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ku(o, n);
  }, ...e };
}, yb = (t) => {
  const e = Pu(t);
  return c(e);
}, bu = (t, e, n) => a.patch(
  `/merchant_bank/card/cardholders/${t}`,
  e,
  n
), xu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return bu(o, u, n);
  }, ...e };
}, db = (t) => {
  const e = xu(t);
  return c(e);
}, Mu = (t, e) => a.get(
  "/merchant_bank/card/cardholders",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Fu = (t) => ["/merchant_bank/card/cardholders", ...t ? [t] : []], ku = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Fu(t), queryFn: ({ signal: u }) => Mu(t, { signal: u, ...r }), ...n };
};
function pb(t, e) {
  const n = ku(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ru = (t, e) => a.post(
  "/merchant_bank/card/activate",
  t,
  e
), Bu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ru(o, n);
  }, ...e };
}, _b = (t) => {
  const e = Bu(t);
  return c(e);
}, Cu = (t, e) => a.get(
  "/merchant_bank/card/access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Eu = (t) => ["/merchant_bank/card/access_token", ...t ? [t] : []], Su = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Eu(t), queryFn: ({ signal: u }) => Cu(t, { signal: u, ...r }), ...n };
};
function mb(t, e) {
  const n = Su(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const vu = (t, e) => a.post(
  "/merchant_bank/card/apple_pay",
  t,
  e
), Du = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return vu(o, n);
  }, ...e };
}, lb = (t) => {
  const e = Du(t);
  return c(e);
}, Au = (t, e) => a.get(
  "/merchant_bank/card/cardholder_access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Uu = (t) => ["/merchant_bank/card/cardholder_access_token", ...t ? [t] : []], Nu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Uu(t), queryFn: ({ signal: u }) => Au(t, { signal: u, ...r }), ...n };
};
function gb(t, e) {
  const n = Nu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Gu = (t, e) => a.post(
  "/merchant_bank/card/state_transition",
  t,
  e
), Tu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Gu(o, n);
  }, ...e };
}, qb = (t) => {
  const e = Tu(t);
  return c(e);
}, Iu = (t, e) => a.post(
  "/merchant_bank/card/google_pay",
  t,
  e
), wu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Iu(o, n);
  }, ...e };
}, Ob = (t) => {
  const e = wu(t);
  return c(e);
}, Qu = (t, e) => a.get(
  "/merchant_bank/card/disputes",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Lu = (t) => ["/merchant_bank/card/disputes", ...t ? [t] : []], Wu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Lu(t), queryFn: ({ signal: u }) => Qu(t, { signal: u, ...r }), ...n };
};
function fb(t, e) {
  const n = Wu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Vu = (t, e) => a.post(
  "/merchant_bank/card/disputes",
  t,
  e
), $u = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Vu(o, n);
  }, ...e };
}, hb = (t) => {
  const e = $u(t);
  return c(e);
}, zu = (t, e) => a.post(
  "/merchant_bank/card/dispute/state_transition",
  t,
  e
), Hu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return zu(o, n);
  }, ...e };
}, Kb = (t) => {
  const e = Hu(t);
  return c(e);
}, Yu = (t, e) => a.post(
  "/merchant_bank/card/dispute/chargeback_credit",
  t,
  e
), Xu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Yu(o, n);
  }, ...e };
}, Pb = (t) => {
  const e = Xu(t);
  return c(e);
}, ju = (t, e) => a.get(
  "/merchant_bank/external_accounts/plaid_link_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Zu = (t) => ["/merchant_bank/external_accounts/plaid_link_token", ...t ? [t] : []], Ju = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Zu(t), queryFn: ({ signal: u }) => ju(t, { signal: u, ...r }), ...n };
};
function bb(t, e) {
  const n = Ju(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ti = (t, e) => a.get(
  `/merchant_bank/external_accounts/${t}/plaid_link_token`,
  e
), ei = (t) => [`/merchant_bank/external_accounts/${t}/plaid_link_token`], ni = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ei(t), queryFn: ({ signal: u }) => ti(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function xb(t, e) {
  const n = ni(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ri = (t, e) => a.get(
  "/merchant_bank/external_accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), si = (t) => ["/merchant_bank/external_accounts", ...t ? [t] : []], oi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? si(t), queryFn: ({ signal: u }) => ri(t, { signal: u, ...r }), ...n };
};
function Mb(t, e) {
  const n = oi(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ai = (t, e) => a.post(
  "/merchant_bank/external_accounts",
  t,
  e
), ui = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ai(o, n);
  }, ...e };
}, Fb = (t) => {
  const e = ui(t);
  return c(e);
}, ii = (t, e) => a.get(
  `/merchant_bank/external_accounts/${t}`,
  e
), ci = (t) => [`/merchant_bank/external_accounts/${t}`], yi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ci(t), queryFn: ({ signal: u }) => ii(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function kb(t, e) {
  const n = yi(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const di = (t, e) => a.delete(
  `/merchant_bank/external_accounts/${t}`,
  e
), pi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return di(o, n);
  }, ...e };
}, Rb = (t) => {
  const e = pi(t);
  return c(e);
}, _i = (t, e, n) => a.patch(
  `/merchant_bank/external_accounts/${t}`,
  e,
  n
), mi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return _i(o, u, n);
  }, ...e };
}, Bb = (t) => {
  const e = mi(t);
  return c(e);
}, li = (t, e) => a.post(
  "/merchant_bank/external_bank_transfers",
  t,
  e
), gi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return li(o, n);
  }, ...e };
}, Cb = (t) => {
  const e = gi(t);
  return c(e);
}, qi = (t, e, n) => a.patch(
  `/merchant_bank/external_bank_transfers/${t}`,
  e,
  n
), Oi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return qi(o, u, n);
  }, ...e };
}, Eb = (t) => {
  const e = Oi(t);
  return c(e);
}, fi = (t, e) => a.get(
  "/debts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), hi = (t) => ["/debts", ...t ? [t] : []], Ki = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hi(t), queryFn: ({ signal: u }) => fi(t, { signal: u, ...r }), ...n };
};
function Sb(t, e) {
  const n = Ki(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Pi = (t, e) => a.post(
  "/debts",
  t,
  e
), bi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Pi(o, n);
  }, ...e };
}, vb = (t) => {
  const e = bi(t);
  return c(e);
}, xi = (t, e) => a.post(
  "/debts/check_complete",
  t,
  e
), Mi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return xi(o, n);
  }, ...e };
}, Db = (t) => {
  const e = Mi(t);
  return c(e);
}, Fi = (t, e) => a.get(
  "/debt_checks",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ki = (t) => ["/debt_checks", ...t ? [t] : []], Ri = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ki(t), queryFn: ({ signal: u }) => Fi(t, { signal: u, ...r }), ...n };
};
function Ab(t, e) {
  const n = Ri(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Bi = (t, e) => a.post(
  "/card/close_account",
  t,
  e
), Ci = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Bi(o, n);
  }, ...e };
}, Ub = (t) => {
  const e = Ci(t);
  return c(e);
}, Ei = (t, e) => a.get(
  "/financial_reviews",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Si = (t) => ["/financial_reviews", ...t ? [t] : []], vi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Si(t), queryFn: ({ signal: u }) => Ei(t, { signal: u, ...r }), ...n };
};
function Nb(t, e) {
  const n = vi(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Di = (t, e) => a.post(
  "/financial_reviews",
  t,
  e
), Ai = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Di(o, n);
  }, ...e };
}, Gb = (t) => {
  const e = Ai(t);
  return c(e);
}, Ui = (t, e) => a.get(
  "/accounting_info",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ni = (t) => ["/accounting_info", ...t ? [t] : []], Gi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ni(t), queryFn: ({ signal: u }) => Ui(t, { signal: u, ...r }), ...n };
};
function Tb(t, e) {
  const n = Gi(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ti = (t, e) => a.post(
  "/accounting_info",
  t,
  e
), Ii = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ti(o, n);
  }, ...e };
}, Ib = (t) => {
  const e = Ii(t);
  return c(e);
}, wi = (t, e) => a.post(
  "/tax_records",
  t,
  e
), Qi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return wi(o, n);
  }, ...e };
}, wb = (t) => {
  const e = Qi(t);
  return c(e);
}, Li = (t, e) => a.get(
  "/officer_subsidiary_records",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Wi = (t) => ["/officer_subsidiary_records", ...t ? [t] : []], Vi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wi(t), queryFn: ({ signal: u }) => Li(t, { signal: u, ...r }), ...n };
};
function Qb(t, e) {
  const n = Vi(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const $i = (t, e) => a.post(
  "/officer_subsidiary_records",
  t,
  e
), zi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return $i(o, n);
  }, ...e };
}, Lb = (t) => {
  const e = zi(t);
  return c(e);
}, Hi = (t) => a.get(
  "/analytics/cube_token",
  t
), Yi = () => ["/analytics/cube_token"], Xi = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Yi(), queryFn: ({ signal: o }) => Hi({ signal: o, ...n }), ...e };
};
function Wb(t) {
  const e = Xi(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const ji = (t, e) => a.get(
  "/logs/api_request",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Zi = (t) => ["/logs/api_request", ...t ? [t] : []], Ji = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Zi(t), queryFn: ({ signal: u }) => ji(t, { signal: u, ...r }), ...n };
};
function Vb(t, e) {
  const n = Ji(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const tc = (t) => a.get(
  "/marketing/offer_csvs",
  t
), ec = () => ["/marketing/offer_csvs"], nc = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ec(), queryFn: ({ signal: o }) => tc({ signal: o, ...n }), ...e };
};
function $b(t) {
  const e = nc(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const rc = (t) => a.post(
  "/marketing/offer_csvs/generate",
  void 0,
  t
), sc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => rc(n), ...e };
}, zb = (t) => {
  const e = sc(t);
  return c(e);
}, oc = (t, e) => a.get(
  "/marketing/emails/batch",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ac = (t) => ["/marketing/emails/batch", ...t ? [t] : []], uc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ac(t), queryFn: ({ signal: u }) => oc(t, { signal: u, ...r }), ...n };
};
function Hb(t, e) {
  const n = uc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ic = (t, e) => a.post(
  "/marketing/emails/batch",
  t,
  e
), cc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ic(o, n);
  }, ...e };
}, Yb = (t) => {
  const e = cc(t);
  return c(e);
}, yc = (t, e) => a.get(
  "/marketing/emails/scheduled_marketing_config",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), dc = (t) => ["/marketing/emails/scheduled_marketing_config", ...t ? [t] : []], pc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? dc(t), queryFn: ({ signal: u }) => yc(t, { signal: u, ...r }), ...n };
};
function Xb(t, e) {
  const n = pc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const _c = (t, e) => a.post(
  "/marketing/emails/scheduled_marketing_config",
  t,
  e
), mc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return _c(o, n);
  }, ...e };
}, jb = (t) => {
  const e = mc(t);
  return c(e);
}, lc = (t, e) => a.get(
  "/businesses/notifications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), gc = (t) => ["/businesses/notifications", ...t ? [t] : []], qc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? gc(t), queryFn: ({ signal: u }) => lc(t, { signal: u, ...r }), ...n };
};
function Zb(t, e) {
  const n = qc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Oc = (t, e) => a.post(
  "/businesses/notifications",
  t,
  e
), fc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Oc(o, n);
  }, ...e };
}, Jb = (t) => {
  const e = fc(t);
  return c(e);
}, hc = (t, e) => a.post(
  "/terms_of_service_mark_shown",
  t,
  e
), Kc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return hc(o, n);
  }, ...e };
}, tx = (t) => {
  const e = Kc(t);
  return c(e);
}, Pc = (t, e, n) => a.get(
  `/partner_dashboard/business_details_page/${t}/applications`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), bc = (t, e) => [`/partner_dashboard/business_details_page/${t}/applications`, ...e ? [e] : []], xc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? bc(t, e), queryFn: ({ signal: y }) => Pc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function ex(t, e, n) {
  const r = xc(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const Mc = (t, e) => a.get(
  `/partner_dashboard/business_details_page/${t}/bank_info`,
  e
), Fc = (t) => [`/partner_dashboard/business_details_page/${t}/bank_info`], kc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Fc(t), queryFn: ({ signal: u }) => Mc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function nx(t, e) {
  const n = kc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Rc = (t, e) => a.get(
  `/partner_dashboard/business_details_page/${t}/business_info`,
  e
), Bc = (t) => [`/partner_dashboard/business_details_page/${t}/business_info`], Cc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Bc(t), queryFn: ({ signal: u }) => Rc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function rx(t, e) {
  const n = Cc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ec = (t, e) => a.get(
  `/partner_dashboard/business_details_page/${t}/funded_products`,
  e
), Sc = (t) => [`/partner_dashboard/business_details_page/${t}/funded_products`], vc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Sc(t), queryFn: ({ signal: u }) => Ec(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function sx(t, e) {
  const n = vc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Dc = (t, e, n) => a.get(
  `/partner_dashboard/business_details_page/${t}/funded_product_details`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Ac = (t, e) => [`/partner_dashboard/business_details_page/${t}/funded_product_details`, ...e ? [e] : []], Uc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Ac(t, e), queryFn: ({ signal: y }) => Dc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function ox(t, e, n) {
  const r = Uc(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const Nc = (t, e, n) => a.get(
  `/partner_dashboard/business_details_page/${t}/payment_progress`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Gc = (t, e) => [`/partner_dashboard/business_details_page/${t}/payment_progress`, ...e ? [e] : []], Tc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Gc(t, e), queryFn: ({ signal: y }) => Nc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function ax(t, e, n) {
  const r = Tc(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const Ic = (t, e, n) => a.get(
  `/partner_dashboard/business_details_page/${t}/offers`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), wc = (t, e) => [`/partner_dashboard/business_details_page/${t}/offers`, ...e ? [e] : []], Qc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? wc(t, e), queryFn: ({ signal: y }) => Ic(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function ux(t, e, n) {
  const r = Qc(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const Lc = (t, e) => a.get(
  `/partner_dashboard/business_details_page/${t}/owner_info`,
  e
), Wc = (t) => [`/partner_dashboard/business_details_page/${t}/owner_info`], Vc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wc(t), queryFn: ({ signal: u }) => Lc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function ix(t, e) {
  const n = Vc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const $c = (t, e) => a.get(
  "/partner_dashboard/businesses_page/businesses_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), zc = (t) => ["/partner_dashboard/businesses_page/businesses_table_rows/count", ...t ? [t] : []], Hc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zc(t), queryFn: ({ signal: u }) => $c(t, { signal: u, ...r }), ...n };
};
function cx(t, e) {
  const n = Hc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Yc = (t, e) => a.get(
  "/partner_dashboard/businesses_page/businesses_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xc = (t) => ["/partner_dashboard/businesses_page/businesses_table_rows", ...t ? [t] : []], jc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xc(t), queryFn: ({ signal: u }) => Yc(t, { signal: u, ...r }), ...n };
};
function yx(t, e) {
  const n = jc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Zc = (t, e) => a.get(
  "/partner_dashboard/capital_tab/funded_products_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Jc = (t) => ["/partner_dashboard/capital_tab/funded_products_table_rows/count", ...t ? [t] : []], ty = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Jc(t), queryFn: ({ signal: u }) => Zc(t, { signal: u, ...r }), ...n };
};
function dx(t, e) {
  const n = ty(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ey = (t, e) => a.get(
  "/partner_dashboard/capital_tab/funded_products_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ny = (t) => ["/partner_dashboard/capital_tab/funded_products_table_rows", ...t ? [t] : []], ry = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ny(t), queryFn: ({ signal: u }) => ey(t, { signal: u, ...r }), ...n };
};
function px(t, e) {
  const n = ry(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const sy = (t, e) => a.get(
  "/partner_dashboard/orders_tab/orders_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), oy = (t) => ["/partner_dashboard/orders_tab/orders_table_rows/count", ...t ? [t] : []], ay = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? oy(t), queryFn: ({ signal: u }) => sy(t, { signal: u, ...r }), ...n };
};
function _x(t, e) {
  const n = ay(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const uy = (t, e) => a.get(
  "/partner_dashboard/orders_tab/orders_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), iy = (t) => ["/partner_dashboard/orders_tab/orders_table_rows", ...t ? [t] : []], cy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? iy(t), queryFn: ({ signal: u }) => uy(t, { signal: u, ...r }), ...n };
};
function mx(t, e) {
  const n = cy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const yy = (t, e) => a.get(
  "/partner_dashboard/capital_tab/applications_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), dy = (t) => ["/partner_dashboard/capital_tab/applications_table_rows/count", ...t ? [t] : []], py = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? dy(t), queryFn: ({ signal: u }) => yy(t, { signal: u, ...r }), ...n };
};
function lx(t, e) {
  const n = py(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const _y = (t, e) => a.get(
  "/partner_dashboard/capital_tab/applications_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), my = (t) => ["/partner_dashboard/capital_tab/applications_table_rows", ...t ? [t] : []], ly = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? my(t), queryFn: ({ signal: u }) => _y(t, { signal: u, ...r }), ...n };
};
function gx(t, e) {
  const n = ly(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const gy = (t, e) => a.get(
  "/partner_dashboard/capital_tab/offers_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), qy = (t) => ["/partner_dashboard/capital_tab/offers_table_rows/count", ...t ? [t] : []], Oy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? qy(t), queryFn: ({ signal: u }) => gy(t, { signal: u, ...r }), ...n };
};
function qx(t, e) {
  const n = Oy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const fy = (t, e) => a.get(
  "/partner_dashboard/capital_tab/offers_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), hy = (t) => ["/partner_dashboard/capital_tab/offers_table_rows", ...t ? [t] : []], Ky = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hy(t), queryFn: ({ signal: u }) => fy(t, { signal: u, ...r }), ...n };
};
function Ox(t, e) {
  const n = Ky(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Py = (t) => a.get(
  "/partner_dashboard/platform_tab/partner_support_info",
  t
), by = () => ["/partner_dashboard/platform_tab/partner_support_info"], xy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? by(), queryFn: ({ signal: o }) => Py({ signal: o, ...n }), ...e };
};
function fx(t) {
  const e = xy(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const My = (t, e) => a.get(
  `/partner_dashboard/business_details_page/${t}/offer_url`,
  e
), Fy = (t) => [`/partner_dashboard/business_details_page/${t}/offer_url`], ky = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Fy(t), queryFn: ({ signal: u }) => My(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function hx(t, e) {
  const n = ky(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ry = (t) => a.get(
  "/partner_dashboard/partner_event/subscriptions/list_user_subscriptions",
  t
), By = () => ["/partner_dashboard/partner_event/subscriptions/list_user_subscriptions"], Cy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? By(), queryFn: ({ signal: o }) => Ry({ signal: o, ...n }), ...e };
};
function Kx(t) {
  const e = Cy(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Ey = (t, e) => a.get(
  "/partner_dashboard/partner_event/web_notifications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Sy = (t) => ["/partner_dashboard/partner_event/web_notifications", ...t ? [t] : []], vy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Sy(t), queryFn: ({ signal: u }) => Ey(t, { signal: u, ...r }), ...n };
};
function Px(t, e) {
  const n = vy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Dy = (t, e) => a.post(
  "/partner_dashboard/partner_event/web_notifications/mark_all_read",
  t,
  e
), Ay = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Dy(o, n);
  }, ...e };
}, bx = (t) => {
  const e = Ay(t);
  return c(e);
}, Uy = (t, e) => a.post(
  "/partner_dashboard/partner_event/web_notifications/mark_one_read",
  t,
  e
), Ny = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Uy(o, n);
  }, ...e };
}, xx = (t) => {
  const e = Ny(t);
  return c(e);
}, Gy = (t, e) => a.patch(
  "/partner_dashboard/partner_event/subscriptions/update_user_subscriptions",
  t,
  e
), Ty = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Gy(o, n);
  }, ...e };
}, Mx = (t) => {
  const e = Ty(t);
  return c(e);
}, Iy = (t, e) => a.patch(
  "/partner_dashboard/partner_event/subscriptions/update_user_subscriptions_for_event_type",
  t,
  e
), wy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Iy(o, n);
  }, ...e };
}, Fx = (t) => {
  const e = wy(t);
  return c(e);
}, Qy = (t) => a.get(
  "/partner_dashboard/crm/integration",
  t
), Ly = () => ["/partner_dashboard/crm/integration"], Wy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Ly(), queryFn: ({ signal: o }) => Qy({ signal: o, ...n }), ...e };
};
function kx(t) {
  const e = Wy(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Vy = (t) => a.get(
  "/partner_dashboard/valid_object_id_prefixes",
  t
), $y = () => ["/partner_dashboard/valid_object_id_prefixes"], zy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? $y(), queryFn: ({ signal: o }) => Vy({ signal: o, ...n }), ...e };
};
function Rx(t) {
  const e = zy(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Hy = (t, e) => a.get(
  `/partner_dashboard/business/owner_auth/${t}`,
  e
), Yy = (t) => [`/partner_dashboard/business/owner_auth/${t}`], Xy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Yy(t), queryFn: ({ signal: u }) => Hy(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Bx(t, e) {
  const n = Xy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const jy = (t) => a.get(
  "/partner_dashboard/marketing/campaigns",
  t
), Zy = () => ["/partner_dashboard/marketing/campaigns"], Jy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Zy(), queryFn: ({ signal: o }) => jy({ signal: o, ...n }), ...e };
};
function Cx(t) {
  const e = Jy(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const td = (t, e) => a.get(
  "/partner_dashboard/marketing/scheduled_marketing_config",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ed = (t) => ["/partner_dashboard/marketing/scheduled_marketing_config", ...t ? [t] : []], nd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ed(t), queryFn: ({ signal: u }) => td(t, { signal: u, ...r }), ...n };
};
function Ex(t, e) {
  const n = nd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const rd = (t, e, n) => a.patch(
  `/partner_dashboard/marketing/scheduled_marketing_config/${t}`,
  e,
  n
), sd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return rd(o, u, n);
  }, ...e };
}, Sx = (t) => {
  const e = sd(t);
  return c(e);
}, od = (t, e) => a.post(
  "/v1/sandbox/generate_event/capital_product_offer/created",
  t,
  e
), ad = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return od(o, n);
  }, ...e };
}, vx = (t) => {
  const e = ad(t);
  return c(e);
}, ud = (t, e) => a.post(
  "/v1/sandbox/generate_event/capital_product_offer/closed",
  t,
  e
), id = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ud(o, n);
  }, ...e };
}, Dx = (t) => {
  const e = id(t);
  return c(e);
}, cd = (t, e) => a.get(
  "/partner_dashboard/webhook_logs/events",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), yd = (t) => ["/partner_dashboard/webhook_logs/events", ...t ? [t] : []], dd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? yd(t), queryFn: ({ signal: u }) => cd(t, { signal: u, ...r }), ...n };
};
function Ax(t, e) {
  const n = dd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const pd = (t, e) => a.get(
  `/partner_dashboard/webhook_logs/event_info/${t}`,
  e
), _d = (t) => [`/partner_dashboard/webhook_logs/event_info/${t}`], md = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _d(t), queryFn: ({ signal: u }) => pd(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Ux(t, e) {
  const n = md(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ld = (t, e, n) => a.get(
  `/partner_dashboard/webhook_logs/event_info/${t}/attempts`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), gd = (t, e) => [`/partner_dashboard/webhook_logs/event_info/${t}/attempts`, ...e ? [e] : []], qd = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? gd(t, e), queryFn: ({ signal: y }) => ld(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function Nx(t, e, n) {
  const r = qd(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const Od = (t, e) => a.get(
  `/partner_dashboard/webhook_logs/event_info/${t}/delivery_attempts`,
  e
), fd = (t) => [`/partner_dashboard/webhook_logs/event_info/${t}/delivery_attempts`], hd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? fd(t), queryFn: ({ signal: u }) => Od(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Gx(t, e) {
  const n = hd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Kd = (t, e) => a.post(
  "/partner_dashboard/webhook_logs/resend_event",
  t,
  e
), Pd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Kd(o, n);
  }, ...e };
}, Tx = (t) => {
  const e = Pd(t);
  return c(e);
}, bd = (t, e) => a.get(
  "/partner_dashboard/crm/events",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), xd = (t) => ["/partner_dashboard/crm/events", ...t ? [t] : []], Md = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? xd(t), queryFn: ({ signal: u }) => bd(t, { signal: u, ...r }), ...n };
};
function Ix(t, e) {
  const n = Md(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Fd = (t, e, n) => a.get(
  `/partner_dashboard/crm/event_info/${t}/attempts`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), kd = (t, e) => [`/partner_dashboard/crm/event_info/${t}/attempts`, ...e ? [e] : []], Rd = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? kd(t, e), queryFn: ({ signal: y }) => Fd(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function wx(t, e, n) {
  const r = Rd(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const Bd = (t, e) => a.post(
  "/partner_dashboard/crm/resend",
  t,
  e
), Cd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Bd(o, n);
  }, ...e };
}, Qx = (t) => {
  const e = Cd(t);
  return c(e);
}, Ed = (t, e) => a.get(
  `/partner_dashboard/crm/event_info/${t}`,
  e
), Sd = (t) => [`/partner_dashboard/crm/event_info/${t}`], vd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Sd(t), queryFn: ({ signal: u }) => Ed(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Lx(t, e) {
  const n = vd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Dd = (t) => a.post(
  "/partner_dashboard/crm/trigger_sync",
  void 0,
  t
), Ad = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Dd(n), ...e };
}, Wx = (t) => {
  const e = Ad(t);
  return c(e);
}, Ud = (t) => a.get(
  "/partner_dashboard/crm/sync",
  t
), Nd = () => ["/partner_dashboard/crm/sync"], Gd = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Nd(), queryFn: ({ signal: o }) => Ud({ signal: o, ...n }), ...e };
};
function Vx(t) {
  const e = Gd(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Td = (t) => a.post(
  "/partner_dashboard/crm/sync",
  void 0,
  t
), Id = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Td(n), ...e };
}, $x = (t) => {
  const e = Id(t);
  return c(e);
}, wd = (t, e) => a.get(
  "/partner_dashboard/webhook_endpoints",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Qd = (t) => ["/partner_dashboard/webhook_endpoints", ...t ? [t] : []], Ld = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qd(t), queryFn: ({ signal: u }) => wd(t, { signal: u, ...r }), ...n };
};
function zx(t, e) {
  const n = Ld(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Wd = (t, e) => a.post(
  "/partner_dashboard/webhook_endpoints",
  t,
  e
), Vd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Wd(o, n);
  }, ...e };
}, Hx = (t) => {
  const e = Vd(t);
  return c(e);
}, $d = (t, e) => a.delete(
  `/partner_dashboard/webhook_endpoints/${t}`,
  e
), zd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return $d(o, n);
  }, ...e };
}, Yx = (t) => {
  const e = zd(t);
  return c(e);
}, Hd = (t, e, n) => a.patch(
  `/partner_dashboard/webhook_endpoints/${t}`,
  e,
  n
), Yd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o, data: u } = s ?? {};
    return Hd(o, u, n);
  }, ...e };
}, Xx = (t) => {
  const e = Yd(t);
  return c(e);
}, Xd = (t, e) => a.get(
  `/partner_dashboard/capital_tab/csv_export/${t}`,
  e
), jd = (t) => [`/partner_dashboard/capital_tab/csv_export/${t}`], Zd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? jd(t), queryFn: ({ signal: u }) => Xd(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function jx(t, e) {
  const n = Zd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Jd = (t, e) => a.get(
  "/partner_dashboard/capital_tab/csv_export",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), tp = (t) => ["/partner_dashboard/capital_tab/csv_export", ...t ? [t] : []], ep = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? tp(t), queryFn: ({ signal: u }) => Jd(t, { signal: u, ...r }), ...n };
};
function Zx(t, e) {
  const n = ep(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const np = (t, e) => a.post(
  "/partner_dashboard/capital_tab/csv_export",
  t,
  e
), rp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return np(o, n);
  }, ...e };
}, Jx = (t) => {
  const e = rp(t);
  return c(e);
}, sp = (t) => a.get(
  "/partner_dashboard/fee_discounts/request_access",
  t
), op = () => ["/partner_dashboard/fee_discounts/request_access"], ap = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? op(), queryFn: ({ signal: o }) => sp({ signal: o, ...n }), ...e };
};
function tM(t) {
  const e = ap(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const up = (t, e) => a.post(
  "/partner_dashboard/fee_discounts/request_access",
  t,
  e
), ip = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return up(o, n);
  }, ...e };
}, eM = (t) => {
  const e = ip(t);
  return c(e);
}, cp = (t, e) => a.get(
  "/partner_dashboard/fee_discounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), yp = (t) => ["/partner_dashboard/fee_discounts", ...t ? [t] : []], dp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? yp(t), queryFn: ({ signal: u }) => cp(t, { signal: u, ...r }), ...n };
};
function nM(t, e) {
  const n = dp(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const pp = (t, e) => a.post(
  "/partner_dashboard/fee_discounts",
  t,
  e
), _p = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return pp(o, n);
  }, ...e };
}, rM = (t) => {
  const e = _p(t);
  return c(e);
}, mp = (t, e) => a.get(
  `/partner_dashboard/fee_discounts/${t}`,
  e
), lp = (t) => [`/partner_dashboard/fee_discounts/${t}`], gp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? lp(t), queryFn: ({ signal: u }) => mp(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function sM(t, e) {
  const n = gp(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const qp = (t, e, n) => a.get(
  `/partner_dashboard/fee_discounts/${t}/businesses`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Op = (t, e) => [`/partner_dashboard/fee_discounts/${t}/businesses`, ...e ? [e] : []], fp = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Op(t, e), queryFn: ({ signal: y }) => qp(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function oM(t, e, n) {
  const r = fp(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const hp = (t) => a.get(
  "/partner_dashboard/revenue_share",
  t
), Kp = () => ["/partner_dashboard/revenue_share"], Pp = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Kp(), queryFn: ({ signal: o }) => hp({ signal: o, ...n }), ...e };
};
function aM(t) {
  const e = Pp(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const bp = (t, e) => a.post(
  "/partner_dashboard/create_order",
  t,
  e
), xp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return bp(o, n);
  }, ...e };
}, uM = (t) => {
  const e = xp(t);
  return c(e);
}, Mp = (t, e) => a.post(
  "/partner_dashboard/finalize_order",
  t,
  e
), Fp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Mp(o, n);
  }, ...e };
}, iM = (t) => {
  const e = Fp(t);
  return c(e);
}, kp = (t, e) => a.post(
  "/partner_dashboard/cancel_order",
  t,
  e
), Rp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return kp(o, n);
  }, ...e };
}, cM = (t) => {
  const e = Rp(t);
  return c(e);
}, Bp = (t, e) => a.get(
  `/partner_dashboard/get_business_details/${t}`,
  e
), Cp = (t) => [`/partner_dashboard/get_business_details/${t}`], Ep = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Cp(t), queryFn: ({ signal: u }) => Bp(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function yM(t, e) {
  const n = Ep(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Sp = (t, e) => a.get(
  "/rutter_connections",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), vp = (t) => ["/rutter_connections", ...t ? [t] : []], Dp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? vp(t), queryFn: ({ signal: u }) => Sp(t, { signal: u, ...r }), ...n };
};
function dM(t, e) {
  const n = Dp(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ap = (t, e) => a.post(
  "/rutter_connections",
  t,
  e
), Up = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ap(o, n);
  }, ...e };
}, pM = (t) => {
  const e = Up(t);
  return c(e);
}, Np = (t, e) => a.get(
  "/rutter_connection_options",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Gp = (t) => ["/rutter_connection_options", ...t ? [t] : []], Tp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Gp(t), queryFn: ({ signal: u }) => Np(t, { signal: u, ...r }), ...n };
};
function _M(t, e) {
  const n = Tp(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ip = (t, e) => a.post(
  "/mark_accounting_integration_seen",
  t,
  e
), wp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ip(o, n);
  }, ...e };
}, mM = (t) => {
  const e = wp(t);
  return c(e);
}, Qp = (t) => a.get(
  "/sardine/session_id",
  t
), Lp = () => ["/sardine/session_id"], Wp = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Lp(), queryFn: ({ signal: o }) => Qp({ signal: o, ...n }), ...e };
};
function lM(t) {
  const e = Wp(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Vp = (t, e) => a.post(
  "/sardine/session_id",
  t,
  e
), $p = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Vp(o, n);
  }, ...e };
}, gM = (t) => {
  const e = $p(t);
  return c(e);
}, zp = (t) => a.get(
  "/partner/widget_configs",
  t
), Hp = () => ["/partner/widget_configs"], Yp = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Hp(), queryFn: ({ signal: o }) => zp({ signal: o, ...n }), ...e };
};
function qM(t) {
  const e = Yp(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Xp = (t, e) => a.patch(
  "/partner/widget_configs",
  t,
  e
), jp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Xp(o, n);
  }, ...e };
}, OM = (t) => {
  const e = jp(t);
  return c(e);
}, Zp = (t, e) => a.get(
  `/merchant_bank/applications/${t}`,
  e
), Jp = (t) => [`/merchant_bank/applications/${t}`], t_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Jp(t), queryFn: ({ signal: u }) => Zp(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function fM(t, e) {
  const n = t_(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const e_ = (t, e) => a.get(
  "/merchant_bank/applications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), n_ = (t) => ["/merchant_bank/applications", ...t ? [t] : []], r_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? n_(t), queryFn: ({ signal: u }) => e_(t, { signal: u, ...r }), ...n };
};
function hM(t, e) {
  const n = r_(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const s_ = (t, e) => a.post(
  "/merchant_bank/applications",
  t,
  e
), o_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return s_(o, n);
  }, ...e };
}, KM = (t) => {
  const e = o_(t);
  return c(e);
}, a_ = (t, e) => a.post(
  "/webhooks/plaid/general",
  t,
  e
), u_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return a_(o, n);
  }, ...e };
}, PM = (t) => {
  const e = u_(t);
  return c(e);
}, i_ = (t, e) => a.get(
  "/amazon/funding/account_lock",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), c_ = (t) => ["/amazon/funding/account_lock", ...t ? [t] : []], y_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? c_(t), queryFn: ({ signal: u }) => i_(t, { signal: u, ...r }), ...n };
};
function bM(t, e) {
  const n = y_(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const d_ = (t) => a.get(
  "/naics",
  t
), p_ = () => ["/naics"], __ = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? p_(), queryFn: ({ signal: o }) => d_({ signal: o, ...n }), ...e };
};
function xM(t) {
  const e = __(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const m_ = (t, e, n, r) => a.post(
  `/mfa/otp/send/${t}/${e}`,
  n,
  r
), l_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { personId: o, phoneNumberType: u, data: y } = s ?? {};
    return m_(o, u, y, n);
  }, ...e };
}, MM = (t) => {
  const e = l_(t);
  return c(e);
}, g_ = (t, e, n, r) => a.post(
  `/mfa/otp/verify/${t}/${e}`,
  n,
  r
), q_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { personId: o, phoneNumberType: u, data: y } = s ?? {};
    return g_(o, u, y, n);
  }, ...e };
}, FM = (t) => {
  const e = q_(t);
  return c(e);
}, O_ = (t, e) => a.get(
  "/funding/pad_agreement",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), f_ = (t) => ["/funding/pad_agreement", ...t ? [t] : []], h_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? f_(t), queryFn: ({ signal: u }) => O_(t, { signal: u, ...r }), ...n };
};
function kM(t, e) {
  const n = h_(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const K_ = (t, e) => a.post(
  "/funding/mark_pad_agreement_signed",
  t,
  e
), P_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return K_(o, n);
  }, ...e };
}, RM = (t) => {
  const e = P_(t);
  return c(e);
}, b_ = (t, e) => a.get(
  "/templates",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), x_ = (t) => ["/templates", ...t ? [t] : []], M_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? x_(t), queryFn: ({ signal: u }) => b_(t, { signal: u, ...r }), ...n };
};
function BM(t, e) {
  const n = M_(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const F_ = (t, e) => a.post(
  "/templates",
  t,
  e
), k_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return F_(o, n);
  }, ...e };
}, CM = (t) => {
  const e = k_(t);
  return c(e);
}, R_ = (t, e) => a.patch(
  "/templates",
  t,
  e
), B_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return R_(o, n);
  }, ...e };
}, EM = (t) => {
  const e = B_(t);
  return c(e);
}, C_ = (t, e) => a.get(
  "/template_mock",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), E_ = (t) => ["/template_mock", ...t ? [t] : []], S_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? E_(t), queryFn: ({ signal: u }) => C_(t, { signal: u, ...r }), ...n };
};
function SM(t, e) {
  const n = S_(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const v_ = (t, e) => a.get(
  "/template_snapshots",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), D_ = (t) => ["/template_snapshots", ...t ? [t] : []], A_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? D_(t), queryFn: ({ signal: u }) => v_(t, { signal: u, ...r }), ...n };
};
function vM(t, e) {
  const n = A_(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const U_ = (t, e) => a.get(
  "/template_snapshot_urls",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), N_ = (t) => ["/template_snapshot_urls", ...t ? [t] : []], G_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? N_(t), queryFn: ({ signal: u }) => U_(t, { signal: u, ...r }), ...n };
};
function DM(t, e) {
  const n = G_(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const T_ = (t) => a.get(
  "/template_types",
  t
), I_ = () => ["/template_types"], w_ = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? I_(), queryFn: ({ signal: o }) => T_({ signal: o, ...n }), ...e };
};
function AM(t) {
  const e = w_(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Q_ = (t, e) => a.post(
  "/template_types",
  t,
  e
), L_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Q_(o, n);
  }, ...e };
}, UM = (t) => {
  const e = L_(t);
  return c(e);
}, W_ = (t, e) => a.post(
  "/manual_repayment",
  t,
  e
), V_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return W_(o, n);
  }, ...e };
}, NM = (t) => {
  const e = V_(t);
  return c(e);
}, $_ = (t, e) => a.post(
  "/mfa/verify_account_details",
  t,
  e
), z_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return $_(o, n);
  }, ...e };
}, GM = (t) => {
  const e = z_(t);
  return c(e);
}, H_ = (t) => a.patch(
  "/mfa/extend_session",
  void 0,
  t
), Y_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => H_(n), ...e };
}, TM = (t) => {
  const e = Y_(t);
  return c(e);
}, X_ = (t, e) => a.get(
  "/merchant_bank/offers",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), j_ = (t) => ["/merchant_bank/offers", ...t ? [t] : []], Z_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? j_(t), queryFn: ({ signal: u }) => X_(t, { signal: u, ...r }), ...n };
};
function IM(t, e) {
  const n = Z_(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const J_ = (t, e) => a.post(
  "/merchant_bank/offers",
  t,
  e
), tm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return J_(o, n);
  }, ...e };
}, wM = (t) => {
  const e = tm(t);
  return c(e);
}, em = (t, e) => a.get(
  "/internal_api/sardine/score_details",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), nm = (t) => ["/internal_api/sardine/score_details", ...t ? [t] : []], rm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? nm(t), queryFn: ({ signal: u }) => em(t, { signal: u, ...r }), ...n };
};
function QM(t, e) {
  const n = rm(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const sm = (t, e) => a.patch(
  "/merchant_bank/merchant_config",
  t,
  e
), om = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return sm(o, n);
  }, ...e };
}, LM = (t) => {
  const e = om(t);
  return c(e);
}, am = (t, e) => a.post(
  "/auth/login/validate_otp",
  t,
  e
), um = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return am(o, n);
  }, ...e };
}, WM = (t) => {
  const e = um(t);
  return c(e);
}, im = (t, e) => a.post(
  "/auth/login/start",
  t,
  e
), cm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return im(o, n);
  }, ...e };
}, VM = (t) => {
  const e = cm(t);
  return c(e);
}, ym = (t, e) => a.post(
  "/auth/token/exchange",
  t,
  e
), dm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ym(o, n);
  }, ...e };
}, $M = (t) => {
  const e = dm(t);
  return c(e);
}, pm = (t, e) => a.get(
  `/auth/management/${t}/saml`,
  e
), _m = (t) => [`/auth/management/${t}/saml`], mm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _m(t), queryFn: ({ signal: u }) => pm(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function zM(t, e) {
  const n = mm(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const lm = (t, e, n) => a.post(
  `/auth/management/${t}/saml`,
  e,
  n
), gm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { userId: o, data: u } = s ?? {};
    return lm(o, u, n);
  }, ...e };
}, HM = (t) => {
  const e = gm(t);
  return c(e);
}, qm = (t, e, n) => a.delete(
  `/auth/management/${t}/saml/${e}`,
  n
), Om = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { userId: o, samlConfigId: u } = s ?? {};
    return qm(o, u, n);
  }, ...e };
}, YM = (t) => {
  const e = Om(t);
  return c(e);
}, fm = (t, e, n, r) => a.patch(
  `/auth/management/${t}/saml/${e}`,
  n,
  r
), hm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { userId: o, samlConfigId: u, data: y } = s ?? {};
    return fm(o, u, y, n);
  }, ...e };
}, XM = (t) => {
  const e = hm(t);
  return c(e);
}, Km = (t, e) => a.post(
  "/auth/email_otp/verify",
  t,
  e
), Pm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Km(o, n);
  }, ...e };
}, jM = (t) => {
  const e = Pm(t);
  return c(e);
}, bm = (t, e) => a.post(
  "/auth/email_otp/v2/verify",
  t,
  e
), xm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return bm(o, n);
  }, ...e };
}, ZM = (t) => {
  const e = xm(t);
  return c(e);
}, Mm = (t, e) => a.post(
  "/auth/email_otp/start",
  t,
  e
), Fm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Mm(o, n);
  }, ...e };
}, JM = (t) => {
  const e = Fm(t);
  return c(e);
}, km = (t, e) => a.post(
  "/auth/email_otp/send",
  t,
  e
), Rm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return km(o, n);
  }, ...e };
}, tF = (t) => {
  const e = Rm(t);
  return c(e);
}, Bm = (t, e) => a.get(
  `/introductory_offer/underwrite/${t}`,
  e
), Cm = (t) => [`/introductory_offer/underwrite/${t}`], Em = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Cm(t), queryFn: ({ signal: u }) => Bm(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function eF(t, e) {
  const n = Em(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Sm = (t, e) => a.post(
  "/introductory_offer/underwrite",
  t,
  e
), vm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Sm(o, n);
  }, ...e };
}, nF = (t) => {
  const e = vm(t);
  return c(e);
}, Dm = (t, e) => a.get(
  "/card/balances",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Am = (t) => ["/card/balances", ...t ? [t] : []], Um = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Am(t), queryFn: ({ signal: u }) => Dm(t, { signal: u, ...r }), ...n };
};
function rF(t, e) {
  const n = Um(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Nm = (t) => a.get(
  "/cardholders",
  t
), Gm = () => ["/cardholders"], Tm = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Gm(), queryFn: ({ signal: o }) => Nm({ signal: o, ...n }), ...e };
};
function sF(t) {
  const e = Tm(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Im = (t, e, n) => a.patch(
  `/cardholders/${t}`,
  e,
  n
), wm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { cardholderId: o, data: u } = s ?? {};
    return Im(o, u, n);
  }, ...e };
}, oF = (t) => {
  const e = wm(t);
  return c(e);
}, Qm = (t, e) => a.get(
  "/card/access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Lm = (t) => ["/card/access_token", ...t ? [t] : []], Wm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Lm(t), queryFn: ({ signal: u }) => Qm(t, { signal: u, ...r }), ...n };
};
function aF(t, e) {
  const n = Wm(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Vm = (t, e) => a.get(
  "/card/lifecycle_states",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $m = (t) => ["/card/lifecycle_states", ...t ? [t] : []], zm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $m(t), queryFn: ({ signal: u }) => Vm(t, { signal: u, ...r }), ...n };
};
function uF(t, e) {
  const n = zm(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Hm = (t, e) => a.get(
  "/card/transactions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ym = (t) => ["/card/transactions", ...t ? [t] : []], Xm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ym(t), queryFn: ({ signal: u }) => Hm(t, { signal: u, ...r }), ...n };
};
function iF(t, e) {
  const n = Xm(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const jm = (t, e) => a.get(
  "/card/pending_transactions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Zm = (t) => ["/card/pending_transactions", ...t ? [t] : []], Jm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Zm(t), queryFn: ({ signal: u }) => jm(t, { signal: u, ...r }), ...n };
};
function cF(t, e) {
  const n = Jm(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const tl = (t, e) => a.get(
  `/orders/${t}`,
  e
), el = (t) => [`/orders/${t}`], nl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? el(t), queryFn: ({ signal: u }) => tl(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function yF(t, e) {
  const n = nl(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const rl = (t, e) => a.post(
  `/orders/${t}/cancel`,
  void 0,
  e
), sl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: o } = s ?? {};
    return rl(o, n);
  }, ...e };
}, dF = (t) => {
  const e = sl(t);
  return c(e);
}, ol = (t, e) => a.get(
  "/orders",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), al = (t) => ["/orders", ...t ? [t] : []], ul = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? al(t), queryFn: ({ signal: u }) => ol(t, { signal: u, ...r }), ...n };
};
function pF(t, e) {
  const n = ul(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const il = (t, e) => a.get(
  `/refund/${t}`,
  e
), cl = (t) => [`/refund/${t}`], yl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? cl(t), queryFn: ({ signal: u }) => il(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function _F(t, e) {
  const n = yl(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const dl = (t, e) => a.get(
  "/refund",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), pl = (t) => ["/refund", ...t ? [t] : []], _l = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? pl(t), queryFn: ({ signal: u }) => dl(t, { signal: u, ...r }), ...n };
};
function mF(t, e) {
  const n = _l(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ml = (t, e) => a.get(
  "/opt_ins",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ll = (t) => ["/opt_ins", ...t ? [t] : []], gl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ll(t), queryFn: ({ signal: u }) => ml(t, { signal: u, ...r }), ...n };
};
function lF(t, e) {
  const n = gl(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ql = (t, e) => a.post(
  "/opt_ins",
  t,
  e
), Ol = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return ql(o, n);
  }, ...e };
}, gF = (t) => {
  const e = Ol(t);
  return c(e);
}, fl = (t, e) => a.post(
  "/bulk_opt_in",
  t,
  e
), hl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return fl(o, n);
  }, ...e };
}, qF = (t) => {
  const e = hl(t);
  return c(e);
}, Kl = (t, e) => a.get(
  "/opt_out_eligibility",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Pl = (t) => ["/opt_out_eligibility", ...t ? [t] : []], bl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Pl(t), queryFn: ({ signal: u }) => Kl(t, { signal: u, ...r }), ...n };
};
function OF(t, e) {
  const n = bl(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const xl = (t, e) => a.post(
  "/opt_out",
  t,
  e
), Ml = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return xl(o, n);
  }, ...e };
}, fF = (t) => {
  const e = Ml(t);
  return c(e);
}, Fl = (t, e) => a.put(
  "/sandbox/capital_product_test_offer",
  t,
  e
), kl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Fl(o, n);
  }, ...e };
}, hF = (t) => {
  const e = kl(t);
  return c(e);
}, Rl = (t, e) => a.patch(
  "/sandbox/capital_product_fund",
  t,
  e
), Bl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Rl(o, n);
  }, ...e };
}, KF = (t) => {
  const e = Bl(t);
  return c(e);
}, Cl = (t, e) => a.patch(
  "/sandbox/capital_product_funding_checklist",
  t,
  e
), El = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Cl(o, n);
  }, ...e };
}, PF = (t) => {
  const e = El(t);
  return c(e);
}, Sl = (t, e) => a.patch(
  "/sandbox/capital_product_top_up",
  t,
  e
), vl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Sl(o, n);
  }, ...e };
}, bF = (t) => {
  const e = vl(t);
  return c(e);
}, Dl = (t, e) => a.post(
  "/sandbox/capital_product_pay_off",
  t,
  e
), Al = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Dl(o, n);
  }, ...e };
}, xF = (t) => {
  const e = Al(t);
  return c(e);
}, Ul = (t, e) => a.post(
  "/sandbox/amazon_lock_bank_account",
  t,
  e
), Nl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ul(o, n);
  }, ...e };
}, MF = (t) => {
  const e = Nl(t);
  return c(e);
}, Gl = (t, e) => a.post(
  "/sandbox/break_bank_account",
  t,
  e
), Tl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Gl(o, n);
  }, ...e };
}, FF = (t) => {
  const e = Tl(t);
  return c(e);
}, Il = (t, e) => a.post(
  "/sandbox/repayments",
  t,
  e
), wl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Il(o, n);
  }, ...e };
}, kF = (t) => {
  const e = wl(t);
  return c(e);
}, Ql = (t, e) => a.patch(
  "/sandbox/repayments",
  t,
  e
), Ll = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Ql(o, n);
  }, ...e };
}, RF = (t) => {
  const e = Ll(t);
  return c(e);
}, Wl = (t, e) => a.post(
  "/sandbox/merchant_bank/setup_business",
  t,
  e
), Vl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Wl(o, n);
  }, ...e };
}, BF = (t) => {
  const e = Vl(t);
  return c(e);
}, $l = (t, e) => a.put(
  "/sandbox/fee_discount",
  t,
  e
), zl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return $l(o, n);
  }, ...e };
}, CF = (t) => {
  const e = zl(t);
  return c(e);
}, Hl = (t, e) => a.get(
  "/repayments",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Yl = (t) => ["/repayments", ...t ? [t] : []], Xl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Yl(t), queryFn: ({ signal: u }) => Hl(t, { signal: u, ...r }), ...n };
};
function EF(t, e) {
  const n = Xl(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const jl = (t, e) => a.get(
  "/businesses/show_top_up_offer",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Zl = (t) => ["/businesses/show_top_up_offer", ...t ? [t] : []], Jl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Zl(t), queryFn: ({ signal: u }) => jl(t, { signal: u, ...r }), ...n };
};
function SF(t, e) {
  const n = Jl(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const tg = (t, e) => a.get(
  "/funding/plaid_link_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), eg = (t) => ["/funding/plaid_link_token", ...t ? [t] : []], ng = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? eg(t), queryFn: ({ signal: u }) => tg(t, { signal: u, ...r }), ...n };
};
function vF(t, e) {
  const n = ng(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const rg = (t, e) => a.get(
  "/statements",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), sg = (t) => ["/statements", ...t ? [t] : []], og = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? sg(t), queryFn: ({ signal: u }) => rg(t, { signal: u, ...r }), ...n };
};
function DF(t, e) {
  const n = og(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ag = (t, e) => a.get(
  "/early_manual_repayment_whitelist",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ug = (t) => ["/early_manual_repayment_whitelist", ...t ? [t] : []], ig = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ug(t), queryFn: ({ signal: u }) => ag(t, { signal: u, ...r }), ...n };
};
function AF(t, e) {
  const n = ig(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const cg = (t, e) => a.patch(
  "/",
  t,
  e
), yg = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return cg(o, n);
  }, ...e };
}, UF = (t) => {
  const e = yg(t);
  return c(e);
}, dg = (t, e) => a.get(
  "/bnpl/underwrite",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), pg = (t) => ["/bnpl/underwrite", ...t ? [t] : []], _g = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? pg(t), queryFn: ({ signal: u }) => dg(t, { signal: u, ...r }), ...n };
};
function NF(t, e) {
  const n = _g(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const mg = (t, e) => a.post(
  "/bnpl/underwrite",
  t,
  e
), lg = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return mg(o, n);
  }, ...e };
}, GF = (t) => {
  const e = lg(t);
  return c(e);
}, gg = (t, e) => a.get(
  "/bnpl/spending_power",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), qg = (t) => ["/bnpl/spending_power", ...t ? [t] : []], Og = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? qg(t), queryFn: ({ signal: u }) => gg(t, { signal: u, ...r }), ...n };
};
function TF(t, e) {
  const n = Og(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const fg = (t, e) => a.get(
  "/net_settlement_opt_in",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), hg = (t) => ["/net_settlement_opt_in", ...t ? [t] : []], Kg = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hg(t), queryFn: ({ signal: u }) => fg(t, { signal: u, ...r }), ...n };
};
function IF(t, e) {
  const n = Kg(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Pg = (t, e) => a.post(
  "/net_settlement_opt_in",
  t,
  e
), bg = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: o } = s ?? {};
    return Pg(o, n);
  }, ...e };
}, wF = (t) => {
  const e = bg(t);
  return c(e);
}, xg = (t, e) => a.get(
  "/funding/bank_linking_error",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Mg = (t) => ["/funding/bank_linking_error", ...t ? [t] : []], Fg = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Mg(t), queryFn: ({ signal: u }) => xg(t, { signal: u, ...r }), ...n };
};
function QF(t, e) {
  const n = Fg(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const kg = (t, e) => a.get(
  `/business_dashboard/applications/${t}`,
  e
), Rg = (t) => [`/business_dashboard/applications/${t}`], Bg = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Rg(t), queryFn: ({ signal: u }) => kg(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function LF(t, e) {
  const n = Bg(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Cg = (t, e) => a.get(
  "/business_dashboard/applications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Eg = (t) => ["/business_dashboard/applications", ...t ? [t] : []], Sg = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Eg(t), queryFn: ({ signal: u }) => Cg(t, { signal: u, ...r }), ...n };
};
function WF(t, e) {
  const n = Sg(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const vg = (t, e) => a.get(
  "/funding/should_plaid_link_with_investigation",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Dg = (t) => ["/funding/should_plaid_link_with_investigation", ...t ? [t] : []], Ag = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Dg(t), queryFn: ({ signal: u }) => vg(t, { signal: u, ...r }), ...n };
};
function VF(t, e) {
  const n = Ag(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ug = (t, e) => a.get(
  "/partner_dashboard/marketing/send_logs/email_batch",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ng = (t) => ["/partner_dashboard/marketing/send_logs/email_batch", ...t ? [t] : []], Gg = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ng(t), queryFn: ({ signal: u }) => Ug(t, { signal: u, ...r }), ...n };
};
function $F(t, e) {
  const n = Gg(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const zF = (t) => c({
  mutationFn: (e) => a.post("/beneficial_owners", e, t == null ? void 0 : t.axios)
}), HF = (t) => c({
  mutationFn: ({
    id: e,
    ...n
  }) => a.patch(`/beneficial_owners/${e}`, n, t == null ? void 0 : t.axios)
}), YF = (t) => c({
  mutationFn: (e) => a.delete(`/beneficial_owners/${e}`, t == null ? void 0 : t.axios)
}), XF = (t) => c({
  mutationFn: (e) => a.post(`/business/${e.business_id}/kyc`, e, t == null ? void 0 : t.axios)
}), jF = (t) => c({
  mutationFn: (e) => a.post("/funding/bank_account_info", e, t == null ? void 0 : t.axios)
}), ZF = (t) => c({
  mutationFn: (e) => a.post("/amazon/funding/bank_account_info", e, t == null ? void 0 : t.axios)
}), JF = (t) => c({
  mutationFn: (e) => a.post("/funding/bank_statement_upload", e, t == null ? void 0 : t.axios)
}), tk = (t) => c({
  mutationFn: (e) => a.post("/underwriting_attempts", { context: e }, t == null ? void 0 : t.axios)
}), ek = (t) => c({
  mutationFn: (e) => a.post(
    "/amazonlending/create_or_update_amazon_resources_pre_login",
    e,
    t == null ? void 0 : t.axios
  )
}), nk = (t) => c({
  mutationFn: (e) => a.patch("/boost_complete", e, t == null ? void 0 : t.axios)
}), rk = (t) => c({
  mutationFn: (e) => a.post("/card/disputes", e, t == null ? void 0 : t.axios)
}), sk = (t) => c({
  mutationFn: (e) => a.post(
    "/funding/create_unverified_bank_accounts_from_plaid_linkage",
    e,
    t == null ? void 0 : t.axios
  )
}), ok = (t) => c({
  mutationFn: (e) => a.post("/amazon/funding/verify_bank_account", e, t == null ? void 0 : t.axios)
}), ak = (t) => c({
  mutationFn: (e) => a.post("/funding/plaid_linkages", e, t == null ? void 0 : t.axios)
}), uk = (t) => c({
  mutationFn: (e) => a.post("/tickets", e, t == null ? void 0 : t.axios)
}), ik = [
  {
    path: "/v1/auth/redeem_token",
    method: "post",
    name: "Redeem Auth Token"
  },
  {
    path: "/v1/bank_accounts",
    method: "get",
    name: "List Bank Accounts"
  },
  {
    path: "/v1/bank_accounts",
    method: "post",
    name: "Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/batch_create",
    method: "post",
    name: "Batch Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/{id}",
    method: "get",
    name: "Get Bank Account"
  },
  {
    path: "/v1/sandbox/bank_account/{id}/verify",
    method: "post",
    name: "Verify Bank Account (Sandbox)"
  },
  {
    path: "/v1/businesses",
    method: "get",
    name: "List Businesses"
  },
  {
    path: "/v1/businesses",
    method: "post",
    name: "Create Business"
  },
  {
    path: "/v1/businesses/batch_create",
    method: "post",
    name: "Batch Create Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "get",
    name: "Get Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "patch",
    name: "Update Business"
  },
  {
    path: "/v1/capital_product_applications",
    method: "get",
    name: "List Capital Product Applications"
  },
  {
    path: "/v1/capital_product_applications/{id}",
    method: "get",
    name: "Get Capital Product Application"
  },
  {
    path: "/v1/sandbox/capital_product_application/{id}/approve",
    method: "post",
    name: "Approve Capital Product Application in Manual Review (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_application/{id}/deny",
    method: "post",
    name: "Deny Capital Product Application in Manual Review (Sandbox)"
  },
  {
    path: "/v1/capital_product_offers/{id}",
    method: "get",
    name: "Get Capital Product Offer"
  },
  {
    path: "/v1/capital_product_offers",
    method: "get",
    name: "List Capital Product Offers"
  },
  {
    path: "/v1/capital_product_payments",
    method: "get",
    name: "List Capital Product Payments"
  },
  {
    path: "/v1/capital_products/{id}/loan_minimum_repayment",
    method: "get",
    name: "Get Loan Minimum Repayment Details For A Capital Product"
  },
  {
    path: "/v1/capital_products/{id}",
    method: "get",
    name: "Get Capital Product"
  },
  {
    path: "/v1/capital_products",
    method: "get",
    name: "List Capital Products"
  },
  {
    path: "/v1/daily_sales_records",
    method: "get",
    name: "List Daily Sales Records"
  },
  {
    path: "/v1/daily_sales_records",
    method: "post",
    name: "Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/batch_create",
    method: "post",
    name: "Batch Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/bulk_ingest",
    method: "post",
    name: "Bulk Ingest Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "get",
    name: "Get Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "patch",
    name: "Update Daily Sale Record"
  },
  {
    path: "/v1/net_settlement_payment_requests/{id}",
    method: "get",
    name: "Get Net Settlement Payment Request"
  },
  {
    path: "/v1/net_settlement_payment_requests",
    method: "get",
    name: "List Net Settlement Payment Requests"
  },
  {
    path: "/v1/net_settlement_payment_requests/{id}/fulfill",
    method: "patch",
    name: "Fulfill Net Settlement Payment Request"
  },
  {
    path: "/v1/net_settlement_payment_requests/initiate",
    method: "patch",
    name: "Initiate Net Settlement Payment Request"
  },
  {
    path: "/v1/sandbox/net_settlement_payment_requests",
    method: "post",
    name: "Create Net Settlement Payment Request"
  },
  {
    path: "/v1/orders/{id}",
    method: "get",
    name: "Get Order"
  },
  {
    path: "/v1/orders",
    method: "get",
    name: "List Orders"
  },
  {
    path: "/v1/orders",
    method: "post",
    name: "Create Order"
  },
  {
    path: "/v1/orders/{id}/cancel",
    method: "post",
    name: "Cancel Order"
  },
  {
    path: "/v1/orders/{id}/finalize",
    method: "post",
    name: "Finalize Order"
  },
  {
    path: "/v1/persons",
    method: "get",
    name: "List Persons"
  },
  {
    path: "/v1/persons",
    method: "post",
    name: "Create Person"
  },
  {
    path: "/v1/persons/batch_create",
    method: "post",
    name: "Batch Create Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "get",
    name: "Get Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "patch",
    name: "Update Person"
  },
  {
    path: "/v1/person_business_relationships",
    method: "get",
    name: "List Person Business Relationships"
  },
  {
    path: "/v1/person_business_relationships",
    method: "post",
    name: "Create Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/batch_create",
    method: "post",
    name: "Batch Create Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/{id}",
    method: "get",
    name: "Get Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/change_representative",
    method: "post",
    name: "Change Representative"
  },
  {
    path: "/v1/refunds",
    method: "get",
    name: "List Refunds"
  },
  {
    path: "/v1/refunds",
    method: "post",
    name: "Create Refund"
  },
  {
    path: "/v1/refunds/{id}",
    method: "get",
    name: "Get Refund"
  },
  {
    path: "/v1/sandbox/trigger_webhook",
    method: "post",
    name: "Trigger Webhook"
  },
  {
    path: "/v1/sandbox/fund_capital_product",
    method: "post",
    name: "Fund Capital Product"
  },
  {
    path: "/v1/sandbox/capital_product_offer",
    method: "post",
    name: "[deprecated] Generate Capital Product Offer"
  },
  {
    path: "/v1/sandbox/cash_account/balance",
    method: "post",
    name: "Add Cash Account Balance"
  },
  {
    path: "/v1/sandbox/capital_product_payments",
    method: "post",
    name: "Create Capital Product Payment (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_offers",
    method: "post",
    name: "Create Capital Product Offer (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_offers/{id}/close",
    method: "post",
    name: "Close Capital Product Offer (Sandbox)"
  },
  {
    path: "/v1/sandbox/generate_event/capital_product_offer/created",
    method: "post",
    name: "[Experimental] Capital Product Offer Created (Sandbox)"
  },
  {
    path: "/v1/sandbox/generate_event/capital_product_offer/closed",
    method: "post",
    name: "[Experimental] Capital Product Offer Closed (Sandbox)"
  },
  {
    path: "/v1/webhook_endpoints/{id}",
    method: "get",
    name: "Get Webhook Endpoint"
  },
  {
    path: "/v1/webhook_endpoints/{id}",
    method: "patch",
    name: "Update Webhook Endpoint"
  },
  {
    path: "/v1/webhook_endpoints",
    method: "get",
    name: "List Webhook Endpoints"
  },
  {
    path: "/v1/webhook_endpoints",
    method: "post",
    name: "Create Webhook Endpoint"
  },
  {
    path: "/v1/payments",
    method: "get",
    name: "List Payments "
  },
  {
    path: "/v1/payments",
    method: "post",
    name: "Create Payment"
  },
  {
    path: "/v1/payments/{id}",
    method: "get",
    name: "Get Payment"
  },
  {
    path: "/v1/payments/batch_create",
    method: "post",
    name: "Batch Create Payment"
  },
  {
    path: "/v1/payment_processor_entity_business_links",
    method: "get",
    name: "List Payment Processor Entity Business Links"
  },
  {
    path: "/v1/payment_processor_entity_business_links",
    method: "post",
    name: "Create Payment Processor Entity Business Link"
  },
  {
    path: "/v1/payment_processor_entity_business_links/batch_create",
    method: "post",
    name: "Batch Create Payment Processor Entity Business Link"
  }
];
export {
  Jf as AccountOwner,
  Zf as AddressType,
  jf as AdverseActionReason,
  Xf as ApplicationAbandonedReason,
  Yf as BankAccountInfoDataShare,
  Hf as BankAccountStatus,
  zf as BusinessProductType,
  $f as CapitalProductActivityType,
  Vf as CapitalProductApplicationStatus,
  Wf as CapitalProductApplicationStatus1,
  Lf as CapitalProductApplicationTransitionReason,
  Qf as CapitalProductApplicationType,
  wf as CapitalProductHealthStatus,
  If as CapitalProductInternalRepaymentPlanType,
  Tf as CapitalProductOfferLabel,
  Gf as CapitalProductOfferType,
  Nf as CapitalProductState,
  Uf as CapitalProductSuperApplicationActionType,
  Af as CapitalProductSuperApplicationStatus,
  Df as CapitalProductType,
  vf as CardBusinessTableRowStatus,
  Sf as CardDeserializerCardType,
  Ef as CardLifecycleStateSerializerLifecycleState,
  kf as CardSerializer1FulfillmentStatus,
  Ff as CardSerializer1State,
  Cf as CardSerializerCardType,
  Bf as CardSerializerFulfillmentStatus,
  Rf as CardSerializerState,
  Mf as CardShippingMethod,
  xf as CardTransactionType,
  bf as CardTransitionChannel,
  Pf as CardTransitionReasonCode,
  Kf as CardTransitionState,
  hf as CardTransitionType,
  ff as CountryCode,
  Of as CrmDeliveryAttemptState,
  qf as CrmEventType,
  gf as CrmVendor,
  lf as Currency,
  mf as Currency1,
  _f as CurrencyCode,
  pf as DataRequirement,
  df as DatasetType,
  yf as DatasetUploadStatus,
  cf as DatashareMethod,
  uf as DayOfWeek,
  af as DecisionStatus,
  of as DeniedReason,
  sf as Direction,
  rf as DiscountProgramFundingType,
  nf as DiscountProgramState,
  ef as DiscountProgramType,
  tf as DisputeReason,
  JO as DisputeState,
  ZO as DisputeState1,
  jO as DisputeTransactionType,
  XO as DocumentUploadPurpose,
  YO as DoordashAdminUserMxRole,
  HO as EditSource,
  zO as EmailBatchState,
  $O as EmailSubscriptionState,
  VO as EmailTriggerSource,
  WO as EntityType,
  LO as FeeDiscountProgramAccessRequestState,
  QO as FieldType,
  wO as FinancialRecordDocType,
  IO as FutureActivityType,
  TO as IdentityDataShare,
  GO as IncorporationJurisdiction,
  NO as IncorporationJurisdiction1,
  UO as IncorporationType,
  AO as IncorporationType1,
  DO as InfoNeededStatus,
  vO as IngestionStatus,
  SO as InnerTransferType,
  EO as InvalidInputReason,
  CO as KycApplicationStatus,
  BO as KycBusinessReportFieldType,
  RO as KycIndividualReportFieldType,
  kO as LienReviewState,
  FO as LienStatus,
  MO as LienType,
  xO as LoanBusinessTableRowStatus,
  bO as ManualReviewReason,
  PO as MarketingEmailType,
  KO as McaBusinessTableRowStatus,
  hO as MerchantBankAccountType,
  fO as MerchantBankApplicationState,
  OO as MerchantBankCardDisputeReason,
  qO as MerchantBankCardDisputeState,
  gO as MerchantBankCardPaymentPreference,
  lO as MerchantBankCardReplacementReason,
  mO as MerchantBankCardType,
  _O as MerchantBankExternalAccountState,
  pO as MerchantBankExternalBankAccountType,
  dO as MerchantBankOfferProductType,
  yO as MerchantBankReceivedTransferType,
  cO as MerchantBankRecipientPaymentType,
  iO as MerchantBankRepaymentType,
  uO as MerchantBankTransactionDestinationAccountType,
  aO as MerchantBankTransactionPaymentDirection,
  oO as MerchantBankTransactionState,
  sO as MerchantBankTransactionType,
  rO as MerchantBankTransferPaymentType,
  nO as MicroDepositVerificationState,
  eO as MinimumRepaymentAmountDueReason,
  tO as NetSettlementOptInState,
  Jq as NetSettlementState,
  Zq as NotificationType,
  jq as OnboardingAction,
  Xq as OnboardingProductType,
  Yq as OnboardingState,
  Hq as OneOffActionType,
  zq as OptInState,
  $q as OrderStatus,
  Vq as OrderStatus1,
  Wq as ParafinProduct,
  Lq as PartnerEventType,
  Qq as PdUserRole,
  wq as PersonaInquiryType,
  Iq as PhoneNumberCountryCode,
  Tq as PhoneNumberType,
  Gq as PlaidInvestigationDeniedReason,
  Nq as PlaidLinkageWithInvestigationSerializerState,
  Uq as RefundFailureReason,
  Aq as RefundStatus,
  Dq as RepaymentDetailsRepaymentPlanType,
  vq as RepaymentDisbursementType,
  Sq as RepaymentMethodType,
  Eq as RepaymentPauseSerializerPauseType,
  Cq as RepaymentSchedule,
  Bq as RepaymentSerializer1RepaymentType,
  Rq as RepaymentSerializer1State,
  kq as RepaymentsPatchDeserializerState,
  Fq as RepaymentsPostDeserializerRepaymentType,
  Mq as RepaymentsPostDeserializerState,
  xq as ReplacementDetailsReplacementReason,
  bq as RestrictionExternalReason,
  Pq as RestrictionReason,
  Kq as RestrictionSource,
  hq as RestrictionType,
  fq as ReturnCode,
  Oq as RevenueShareAccountType,
  qq as RevenueShareBasisType,
  gq as ReversalReason,
  lq as RutterConnectionState,
  mq as RutterIntegrationType,
  _q as RutterPlatform,
  pq as S3PartnerDashboardCsvStatus,
  dq as S3PartnerDashboardCsvTableType,
  yq as SalesDataShare,
  cq as SardineCustomerScoreState,
  iq as SardineFlowName,
  uq as SardineLevel,
  aq as Schedule,
  oq as SessionType,
  sq as State,
  rq as StripeSaleRecordIngestionType,
  nq as TermsOfServiceLinkType,
  eq as Timezone,
  tq as UnderwritingDesiredOutputState,
  Jg as UnifiedRealtimeUnderwritingAttemptFailureReason,
  Zg as UnifiedRealtimeUnderwritingAttemptStatus,
  jg as UserRole,
  Xg as ValidationStatus,
  Yg as VerificationStatus,
  Hg as WebNotificationState,
  zg as WebSubscriptionState,
  $g as WebhookDeliveryAttemptState,
  Vg as WebhookEndpointStatus,
  Wg as WebhookEventDeliveryState,
  Lg as WebhookEventMode,
  Qg as WebhookEventType,
  Dd as attemptSync,
  Dy as batchCreatePdPartnerEventMarkAllNotificationsRead,
  Kd as batchResendWebhookAttempt,
  Rr as benchmarkGetCapitalProductOwnership,
  rl as cancelOrder,
  $c as countPdBusinessTableRows,
  yy as countPdCapitalProductApplicationRow,
  gy as countPdCapitalProductOfferRow,
  Zc as countPdCapitalProductRow,
  sy as countPdOrderRow,
  mg as createAttemptToUnderwriteBnplOrder,
  Sm as createAttemptToUnderwriteIntroductoryOffers,
  Ms as createBankAccountInfo,
  Tr as createCapitalProductIntents,
  Hr as createCapitalProductSuperApplicationActions,
  $r as createCapitalProductSuperApplications,
  Hn as createCapitalProducts,
  Dr as createOfferedCapitalProductFeeDiscount,
  yt as createOrganization,
  ot as createPartnerApiKey,
  bp as createPdCreateOrder,
  pp as createPdFeeDiscountProgram,
  up as createPdFeeDiscountProgramAccessRequest,
  Uy as createPdPartnerEventMarkNotificationRead,
  Wd as createPdWebhookEndpoint,
  ca as createPersonOptIn,
  Ns as createPlaidInvestigation,
  rc as createS3OfferCsv,
  np as createS3PartnerDashboardTableCsv,
  qm as deleteAuthManagementUseridSamlSamlconfigid,
  Oe as deleteBeneficialOwnersId,
  ze as deleteBusinessOfficersId,
  U as deleteCashAdvancePausesP1,
  oe as deleteMarketingIntegrationsVendor,
  di as deleteMerchantBankExternalAccountsId,
  iu as deleteMerchantBankRecipientsId,
  vt as deleteOrganizationDeleteUsers,
  Et as deletePartnerDeleteUsers,
  $d as deletePdWebhookEndpoint,
  Pt as deleteSlackChannelsId,
  ud as experimentalCapitalProductOfferClosedSandbox,
  od as experimentalCapitalProductOfferCreatedSandbox,
  Ui as getAccountingInfo,
  i_ as getAmazonFundingAccountLock,
  Hi as getAnalyticsCubeToken,
  kg as getApplication,
  Ad as getAttemptSyncMutationOptions,
  pm as getAuthManagementP1Saml,
  Ps as getBankAccountInfos,
  ke as getBanks,
  Ay as getBatchCreatePdPartnerEventMarkAllNotificationsReadMutationOptions,
  Pd as getBatchResendWebhookAttemptMutationOptions,
  Br as getBenchmarkGetCapitalProductOwnershipQueryKey,
  Cr as getBenchmarkGetCapitalProductOwnershipQueryOptions,
  _e as getBeneficialOwners,
  gg as getBnplSpendingPower,
  b as getBusinessAnnotations,
  O as getBusinessCores,
  io as getBusinessCount,
  Bp as getBusinessDetailsForOrder,
  Zs as getBusinessIdentities,
  Qe as getBusinessOfficers,
  Te as getBusinessTableRowsCard,
  Ue as getBusinessTableRowsLoan,
  ve as getBusinessTableRowsMca,
  Ce as getBusinessTableRowsProductAgnostic,
  da as getBusinessesDailySalesRecords,
  lc as getBusinessesNotifications,
  Ao as getBusinessesProducts,
  jl as getBusinessesShowTopUpOffer,
  Xr as getCalculatedChunkValues,
  sl as getCancelOrderMutationOptions,
  cr as getCapitalProductActivities,
  er as getCapitalProductApplicationAmountSummary,
  qr as getCapitalProductApplicationSnapshots,
  pr as getCapitalProductApplications,
  ss as getCapitalProductDisputes,
  Jr as getCapitalProductDisputesDisputeid,
  Ur as getCapitalProductIntents,
  Pr as getCapitalProductNetTopUpItemization,
  sr as getCapitalProductOfferCollection,
  Qn as getCapitalProductProspectiveTermsOfService,
  Er as getCapitalProductRepaymentSchedule,
  Mr as getCapitalProductStateDisclosure,
  Vn as getCapitalProducts,
  Zn as getCapitalProductsSummary,
  Qm as getCardAccessToken,
  Dm as getCardBalances,
  Vm as getCardLifecycleStates,
  jm as getCardPendingTransactions,
  Hm as getCardTransactions,
  Nm as getCardholders,
  Co as getCards,
  E as getCashAdvancePauses,
  zc as getCountPdBusinessTableRowsQueryKey,
  Hc as getCountPdBusinessTableRowsQueryOptions,
  dy as getCountPdCapitalProductApplicationRowQueryKey,
  py as getCountPdCapitalProductApplicationRowQueryOptions,
  qy as getCountPdCapitalProductOfferRowQueryKey,
  Oy as getCountPdCapitalProductOfferRowQueryOptions,
  Jc as getCountPdCapitalProductRowQueryKey,
  ty as getCountPdCapitalProductRowQueryOptions,
  oy as getCountPdOrderRowQueryKey,
  ay as getCountPdOrderRowQueryOptions,
  Lr as getCounteroffer,
  lg as getCreateAttemptToUnderwriteBnplOrderMutationOptions,
  vm as getCreateAttemptToUnderwriteIntroductoryOffersMutationOptions,
  Fs as getCreateBankAccountInfoMutationOptions,
  Ir as getCreateCapitalProductIntentsMutationOptions,
  Yr as getCreateCapitalProductSuperApplicationActionsMutationOptions,
  zr as getCreateCapitalProductSuperApplicationsMutationOptions,
  Yn as getCreateCapitalProductsMutationOptions,
  Ar as getCreateOfferedCapitalProductFeeDiscountMutationOptions,
  dt as getCreateOrganizationMutationOptions,
  at as getCreatePartnerApiKeyMutationOptions,
  xp as getCreatePdCreateOrderMutationOptions,
  ip as getCreatePdFeeDiscountProgramAccessRequestMutationOptions,
  _p as getCreatePdFeeDiscountProgramMutationOptions,
  Ny as getCreatePdPartnerEventMarkNotificationReadMutationOptions,
  Vd as getCreatePdWebhookEndpointMutationOptions,
  ya as getCreatePersonOptInMutationOptions,
  Gs as getCreatePlaidInvestigationMutationOptions,
  sc as getCreateS3OfferCsvMutationOptions,
  rp as getCreateS3PartnerDashboardTableCsvMutationOptions,
  Pn as getDataingestionS3DatasetIngestionConfigs,
  pn as getDataingestionS3DatasetIntegrations,
  cn as getDataingestionS3DatasetUploadValidationResults,
  on as getDataingestionS3DatasetUploads,
  qn as getDataingestionS3OrgDatasetIntegrations,
  Sn as getDataingestionStripeIngestions,
  Fi as getDebtChecks,
  fi as getDebts,
  Om as getDeleteAuthManagementUseridSamlSamlconfigidMutationOptions,
  fe as getDeleteBeneficialOwnersIdMutationOptions,
  He as getDeleteBusinessOfficersIdMutationOptions,
  N as getDeleteCashAdvancePausesP1MutationOptions,
  ae as getDeleteMarketingIntegrationsVendorMutationOptions,
  pi as getDeleteMerchantBankExternalAccountsIdMutationOptions,
  cu as getDeleteMerchantBankRecipientsIdMutationOptions,
  Dt as getDeleteOrganizationDeleteUsersMutationOptions,
  St as getDeletePartnerDeleteUsersMutationOptions,
  zd as getDeletePdWebhookEndpointMutationOptions,
  bt as getDeleteSlackChannelsIdMutationOptions,
  Io as getDocumentUploadGroup,
  Vo as getDocumentUploadList,
  ag as getEarlyManualRepaymentWhitelist,
  id as getExperimentalCapitalProductOfferClosedSandboxMutationOptions,
  ad as getExperimentalCapitalProductOfferCreatedSandboxMutationOptions,
  Ei as getFinancialReviews,
  cs as getFlexLoanMinimumRepaymentSchedule,
  Bs as getFundingAccounts,
  tg as getFundingPlaidLinkToken,
  L as getFutureActivities,
  Ni as getGetAccountingInfoQueryKey,
  Gi as getGetAccountingInfoQueryOptions,
  c_ as getGetAmazonFundingAccountLockQueryKey,
  y_ as getGetAmazonFundingAccountLockQueryOptions,
  Yi as getGetAnalyticsCubeTokenQueryKey,
  Xi as getGetAnalyticsCubeTokenQueryOptions,
  Rg as getGetApplicationQueryKey,
  Bg as getGetApplicationQueryOptions,
  _m as getGetAuthManagementP1SamlQueryKey,
  mm as getGetAuthManagementP1SamlQueryOptions,
  bs as getGetBankAccountInfosQueryKey,
  xs as getGetBankAccountInfosQueryOptions,
  Re as getGetBanksQueryKey,
  Be as getGetBanksQueryOptions,
  me as getGetBeneficialOwnersQueryKey,
  le as getGetBeneficialOwnersQueryOptions,
  qg as getGetBnplSpendingPowerQueryKey,
  Og as getGetBnplSpendingPowerQueryOptions,
  x as getGetBusinessAnnotationsQueryKey,
  M as getGetBusinessAnnotationsQueryOptions,
  f as getGetBusinessCoresQueryKey,
  h as getGetBusinessCoresQueryOptions,
  co as getGetBusinessCountQueryKey,
  yo as getGetBusinessCountQueryOptions,
  Cp as getGetBusinessDetailsForOrderQueryKey,
  Ep as getGetBusinessDetailsForOrderQueryOptions,
  Js as getGetBusinessIdentitiesQueryKey,
  to as getGetBusinessIdentitiesQueryOptions,
  Le as getGetBusinessOfficersQueryKey,
  We as getGetBusinessOfficersQueryOptions,
  Ie as getGetBusinessTableRowsCardQueryKey,
  we as getGetBusinessTableRowsCardQueryOptions,
  Ne as getGetBusinessTableRowsLoanQueryKey,
  Ge as getGetBusinessTableRowsLoanQueryOptions,
  De as getGetBusinessTableRowsMcaQueryKey,
  Ae as getGetBusinessTableRowsMcaQueryOptions,
  Ee as getGetBusinessTableRowsProductAgnosticQueryKey,
  Se as getGetBusinessTableRowsProductAgnosticQueryOptions,
  pa as getGetBusinessesDailySalesRecordsQueryKey,
  _a as getGetBusinessesDailySalesRecordsQueryOptions,
  gc as getGetBusinessesNotificationsQueryKey,
  qc as getGetBusinessesNotificationsQueryOptions,
  Uo as getGetBusinessesProductsQueryKey,
  No as getGetBusinessesProductsQueryOptions,
  Zl as getGetBusinessesShowTopUpOfferQueryKey,
  Jl as getGetBusinessesShowTopUpOfferQueryOptions,
  jr as getGetCalculatedChunkValuesQueryKey,
  Zr as getGetCalculatedChunkValuesQueryOptions,
  yr as getGetCapitalProductActivitiesQueryKey,
  dr as getGetCapitalProductActivitiesQueryOptions,
  nr as getGetCapitalProductApplicationAmountSummaryQueryKey,
  rr as getGetCapitalProductApplicationAmountSummaryQueryOptions,
  Or as getGetCapitalProductApplicationSnapshotsQueryKey,
  fr as getGetCapitalProductApplicationSnapshotsQueryOptions,
  _r as getGetCapitalProductApplicationsQueryKey,
  mr as getGetCapitalProductApplicationsQueryOptions,
  ts as getGetCapitalProductDisputesDisputeidQueryKey,
  es as getGetCapitalProductDisputesDisputeidQueryOptions,
  os as getGetCapitalProductDisputesQueryKey,
  as as getGetCapitalProductDisputesQueryOptions,
  Nr as getGetCapitalProductIntentsQueryKey,
  Gr as getGetCapitalProductIntentsQueryOptions,
  br as getGetCapitalProductNetTopUpItemizationQueryKey,
  xr as getGetCapitalProductNetTopUpItemizationQueryOptions,
  or as getGetCapitalProductOfferCollectionQueryKey,
  ar as getGetCapitalProductOfferCollectionQueryOptions,
  Ln as getGetCapitalProductProspectiveTermsOfServiceQueryKey,
  Wn as getGetCapitalProductProspectiveTermsOfServiceQueryOptions,
  Sr as getGetCapitalProductRepaymentScheduleQueryKey,
  vr as getGetCapitalProductRepaymentScheduleQueryOptions,
  Fr as getGetCapitalProductStateDisclosureQueryKey,
  kr as getGetCapitalProductStateDisclosureQueryOptions,
  $n as getGetCapitalProductsQueryKey,
  zn as getGetCapitalProductsQueryOptions,
  Jn as getGetCapitalProductsSummaryQueryKey,
  tr as getGetCapitalProductsSummaryQueryOptions,
  Lm as getGetCardAccessTokenQueryKey,
  Wm as getGetCardAccessTokenQueryOptions,
  Am as getGetCardBalancesQueryKey,
  Um as getGetCardBalancesQueryOptions,
  $m as getGetCardLifecycleStatesQueryKey,
  zm as getGetCardLifecycleStatesQueryOptions,
  Zm as getGetCardPendingTransactionsQueryKey,
  Jm as getGetCardPendingTransactionsQueryOptions,
  Ym as getGetCardTransactionsQueryKey,
  Xm as getGetCardTransactionsQueryOptions,
  Gm as getGetCardholdersQueryKey,
  Tm as getGetCardholdersQueryOptions,
  Eo as getGetCardsQueryKey,
  So as getGetCardsQueryOptions,
  S as getGetCashAdvancePausesQueryKey,
  v as getGetCashAdvancePausesQueryOptions,
  Wr as getGetCounterofferQueryKey,
  Vr as getGetCounterofferQueryOptions,
  bn as getGetDataingestionS3DatasetIngestionConfigsQueryKey,
  xn as getGetDataingestionS3DatasetIngestionConfigsQueryOptions,
  _n as getGetDataingestionS3DatasetIntegrationsQueryKey,
  mn as getGetDataingestionS3DatasetIntegrationsQueryOptions,
  yn as getGetDataingestionS3DatasetUploadValidationResultsQueryKey,
  dn as getGetDataingestionS3DatasetUploadValidationResultsQueryOptions,
  an as getGetDataingestionS3DatasetUploadsQueryKey,
  un as getGetDataingestionS3DatasetUploadsQueryOptions,
  On as getGetDataingestionS3OrgDatasetIntegrationsQueryKey,
  fn as getGetDataingestionS3OrgDatasetIntegrationsQueryOptions,
  vn as getGetDataingestionStripeIngestionsQueryKey,
  Dn as getGetDataingestionStripeIngestionsQueryOptions,
  ki as getGetDebtChecksQueryKey,
  Ri as getGetDebtChecksQueryOptions,
  hi as getGetDebtsQueryKey,
  Ki as getGetDebtsQueryOptions,
  wo as getGetDocumentUploadGroupQueryKey,
  Qo as getGetDocumentUploadGroupQueryOptions,
  $o as getGetDocumentUploadListQueryKey,
  zo as getGetDocumentUploadListQueryOptions,
  ug as getGetEarlyManualRepaymentWhitelistQueryKey,
  ig as getGetEarlyManualRepaymentWhitelistQueryOptions,
  Si as getGetFinancialReviewsQueryKey,
  vi as getGetFinancialReviewsQueryOptions,
  ys as getGetFlexLoanMinimumRepaymentScheduleQueryKey,
  ds as getGetFlexLoanMinimumRepaymentScheduleQueryOptions,
  Cs as getGetFundingAccountsQueryKey,
  Es as getGetFundingAccountsQueryOptions,
  eg as getGetFundingPlaidLinkTokenQueryKey,
  ng as getGetFundingPlaidLinkTokenQueryOptions,
  W as getGetFutureActivitiesQueryKey,
  V as getGetFutureActivitiesQueryOptions,
  Qs as getGetKycRetryFieldsQueryKey,
  Ls as getGetKycRetryFieldsQueryOptions,
  Cm as getGetLatestAttemptToUnderwriteIntroductoryOffersQueryKey,
  Em as getGetLatestAttemptToUnderwriteIntroductoryOffersQueryOptions,
  ra as getGetLiensQueryKey,
  sa as getGetLiensQueryOptions,
  ie as getGetMarketingGtmToolkitQueryKey,
  ce as getGetMarketingGtmToolkitQueryOptions,
  ee as getGetMarketingIntegrationsQueryKey,
  ne as getGetMarketingIntegrationsQueryOptions,
  de as getGetMarketingParagonTokenQueryKey,
  pe as getGetMarketingParagonTokenQueryOptions,
  Ca as getGetMerchantBankAccountsIdBalancesQueryKey,
  Ea as getGetMerchantBankAccountsIdBalancesQueryOptions,
  ka as getGetMerchantBankAccountsIdQueryKey,
  Ra as getGetMerchantBankAccountsIdQueryOptions,
  va as getGetMerchantBankAccountsQueryKey,
  Da as getGetMerchantBankAccountsQueryOptions,
  Jp as getGetMerchantBankApplicationsIdQueryKey,
  t_ as getGetMerchantBankApplicationsIdQueryOptions,
  n_ as getGetMerchantBankApplicationsQueryKey,
  r_ as getGetMerchantBankApplicationsQueryOptions,
  Eu as getGetMerchantBankCardAccessTokenQueryKey,
  Su as getGetMerchantBankCardAccessTokenQueryOptions,
  Uu as getGetMerchantBankCardCardholderAccessTokenQueryKey,
  Nu as getGetMerchantBankCardCardholderAccessTokenQueryOptions,
  Fu as getGetMerchantBankCardCardholdersQueryKey,
  ku as getGetMerchantBankCardCardholdersQueryOptions,
  Lu as getGetMerchantBankCardDisputesQueryKey,
  Wu as getGetMerchantBankCardDisputesQueryOptions,
  fu as getGetMerchantBankCardsQueryKey,
  hu as getGetMerchantBankCardsQueryOptions,
  ei as getGetMerchantBankExternalAccountsIdPlaidLinkTokenQueryKey,
  ni as getGetMerchantBankExternalAccountsIdPlaidLinkTokenQueryOptions,
  ci as getGetMerchantBankExternalAccountsIdQueryKey,
  yi as getGetMerchantBankExternalAccountsIdQueryOptions,
  Zu as getGetMerchantBankExternalAccountsPlaidLinkTokenQueryKey,
  Ju as getGetMerchantBankExternalAccountsPlaidLinkTokenQueryOptions,
  si as getGetMerchantBankExternalAccountsQueryKey,
  oi as getGetMerchantBankExternalAccountsQueryOptions,
  Za as getGetMerchantBankInterestQueryKey,
  Ja as getGetMerchantBankInterestQueryOptions,
  j_ as getGetMerchantBankOffersQueryKey,
  Z_ as getGetMerchantBankOffersQueryOptions,
  au as getGetMerchantBankRecipientsIdQueryKey,
  uu as getGetMerchantBankRecipientsIdQueryOptions,
  eu as getGetMerchantBankRecipientsQueryKey,
  nu as getGetMerchantBankRecipientsQueryOptions,
  Ia as getGetMerchantBankTransactionsIdQueryKey,
  wa as getGetMerchantBankTransactionsIdQueryOptions,
  La as getGetMerchantBankTransactionsQueryKey,
  Wa as getGetMerchantBankTransactionsQueryOptions,
  $a as getGetMerchantBankTransferFeesQueryKey,
  za as getGetMerchantBankTransferFeesQueryOptions,
  Ya as getGetMerchantBankTransferLimitsQueryKey,
  Xa as getGetMerchantBankTransferLimitsQueryOptions,
  p_ as getGetNaicsQueryKey,
  __ as getGetNaicsQueryOptions,
  hg as getGetNetSettlementsOptInStatusQueryKey,
  Kg as getGetNetSettlementsOptInStatusQueryOptions,
  Wi as getGetOfficerSubsidiaryRecordsQueryKey,
  Vi as getGetOfficerSubsidiaryRecordsQueryOptions,
  In as getGetOnboardingBusinessRequirementsQueryKey,
  wn as getGetOnboardingBusinessRequirementsQueryOptions,
  Xs as getGetOnboardingInfoNeededQueryKey,
  js as getGetOnboardingInfoNeededQueryOptions,
  zs as getGetOnboardingStatesQueryKey,
  Hs as getGetOnboardingStatesQueryOptions,
  ll as getGetOptInsQueryKey,
  gl as getGetOptInsQueryOptions,
  Pl as getGetOptOutEligibilityQueryKey,
  bl as getGetOptOutEligibilityQueryOptions,
  el as getGetOrderQueryKey,
  nl as getGetOrderQueryOptions,
  lt as getGetOrganizationQueryKey,
  gt as getGetOrganizationQueryOptions,
  Yy as getGetOwnerAuthQueryKey,
  Xy as getGetOwnerAuthQueryOptions,
  be as getGetOwnerIsBeneficialOwnerQueryKey,
  xe as getGetOwnerIsBeneficialOwnerQueryOptions,
  so as getGetOwnersQueryKey,
  oo as getGetOwnersQueryOptions,
  f_ as getGetPADAgreementQueryKey,
  h_ as getGetPADAgreementQueryOptions,
  Oa as getGetParafinderBusinessTypesQueryKey,
  fa as getGetParafinderBusinessTypesQueryOptions,
  xa as getGetParafinderCapitalInfoQueryKey,
  Ma as getGetParafinderCapitalInfoQueryOptions,
  la as getGetParafinderQueryKey,
  ga as getGetParafinderQueryOptions,
  Ka as getGetParafinderSummaryQueryKey,
  Pa as getGetParafinderSummaryQueryOptions,
  rt as getGetPartnerApiKeyQueryKey,
  st as getGetPartnerApiKeyQueryOptions,
  Lt as getGetPartnerConfigsQueryKey,
  Wt as getGetPartnerConfigsQueryOptions,
  It as getGetPartnerPublicConfigsQueryKey,
  wt as getGetPartnerPublicConfigsQueryOptions,
  Mt as getGetPartnerUsersQueryKey,
  Ft as getGetPartnerUsersQueryOptions,
  Hp as getGetPartnerWidgetConfigsQueryKey,
  Yp as getGetPartnerWidgetConfigsQueryOptions,
  B as getGetPartnersDoordashAdminUsersQueryKey,
  C as getGetPartnersDoordashAdminUsersQueryOptions,
  z as getGetPartnersQueryKey,
  H as getGetPartnersQueryOptions,
  Z as getGetPartnersUnderwritingQueryKey,
  J as getGetPartnersUnderwritingQueryOptions,
  op as getGetPdFeeDiscountProgramAccessRequestQueryKey,
  ap as getGetPdFeeDiscountProgramAccessRequestQueryOptions,
  lp as getGetPdFeeDiscountProgramQueryKey,
  gp as getGetPdFeeDiscountProgramQueryOptions,
  Fy as getGetPdOfferUrlQueryKey,
  ky as getGetPdOfferUrlQueryOptions,
  Kp as getGetPdRevenueShareQueryKey,
  Pp as getGetPdRevenueShareQueryOptions,
  $y as getGetPdUtilityInfoQueryKey,
  zy as getGetPdUtilityInfoQueryOptions,
  As as getGetPlaidInvestigationsQueryKey,
  Us as getGetPlaidInvestigationsQueryOptions,
  pl as getGetRefundCapitalProductIdQueryKey,
  _l as getGetRefundCapitalProductIdQueryOptions,
  cl as getGetRefundQueryKey,
  yl as getGetRefundQueryOptions,
  w as getGetRepaymentPlanParametersQueryKey,
  Q as getGetRepaymentPlanParametersQueryOptions,
  Yl as getGetRepaymentsQueryKey,
  Xl as getGetRepaymentsQueryOptions,
  Yo as getGetRestrictionQueryKey,
  Xo as getGetRestrictionQueryOptions,
  ta as getGetRestrictionsDetailsQueryKey,
  ea as getGetRestrictionsDetailsQueryOptions,
  ls as getGetRunOfacScreeningResultsQueryKey,
  gs as getGetRunOfacScreeningResultsQueryOptions,
  Gp as getGetRutterConnectionOptionsQueryKey,
  Tp as getGetRutterConnectionOptionsQueryOptions,
  vp as getGetRutterConnectionsQueryKey,
  Dp as getGetRutterConnectionsQueryOptions,
  jd as getGetS3PartnerDashboardTableCsvQueryKey,
  Zd as getGetS3PartnerDashboardTableCsvQueryOptions,
  Lp as getGetSardineSessionIdQueryKey,
  Wp as getGetSardineSessionIdQueryOptions,
  ht as getGetSlackChannelsQueryKey,
  Kt as getGetSlackChannelsQueryOptions,
  sg as getGetStatementsQueryKey,
  og as getGetStatementsQueryOptions,
  Xt as getGetStatementsV2QueryKey,
  jt as getGetStatementsV2QueryOptions,
  Id as getGetSyncAndMaybeMarkFailedMutationOptions,
  Nd as getGetSyncQueryKey,
  Gd as getGetSyncQueryOptions,
  E_ as getGetTemplateMockQueryKey,
  S_ as getGetTemplateMockQueryOptions,
  N_ as getGetTemplateSnapshotQueryKey,
  G_ as getGetTemplateSnapshotQueryOptions,
  D_ as getGetTemplateSnapshotsQueryKey,
  A_ as getGetTemplateSnapshotsQueryOptions,
  I_ as getGetTemplateTypesQueryKey,
  w_ as getGetTemplateTypesQueryOptions,
  x_ as getGetTemplatesQueryKey,
  M_ as getGetTemplatesQueryOptions,
  hs as getGetTermLoanProspectiveRepaymentsQueryKey,
  Ks as getGetTermLoanProspectiveRepaymentsQueryOptions,
  pg as getGetUnderwritingStatusForOrderQueryKey,
  _g as getGetUnderwritingStatusForOrderQueryOptions,
  ws as getKycRetryFields,
  Bm as getLatestAttemptToUnderwriteIntroductoryOffers,
  na as getLiens,
  _t as getLinkPartnerMutationOptions,
  Zi as getListApiRequestsQueryKey,
  Ji as getListApiRequestsQueryOptions,
  Eg as getListApplicationsQueryKey,
  Sg as getListApplicationsQueryOptions,
  Mg as getListBankLinkingErrorQueryKey,
  Fg as getListBankLinkingErrorQueryOptions,
  tn as getListDatasetUploadsQueryKey,
  en as getListDatasetUploadsQueryOptions,
  rn as getListManualDatasetUploadValidationResultsQueryKey,
  sn as getListManualDatasetUploadValidationResultsQueryOptions,
  al as getListOrdersQueryKey,
  ul as getListOrdersQueryOptions,
  it as getListOrganizationsQueryKey,
  ct as getListOrganizationsQueryOptions,
  dc as getListPartnerScheduledMarketingEmailConfigsQueryKey,
  pc as getListPartnerScheduledMarketingEmailConfigsQueryOptions,
  bc as getListPdBusinessDetailsApplicationsQueryKey,
  xc as getListPdBusinessDetailsApplicationsQueryOptions,
  Fc as getListPdBusinessDetailsBankInfoQueryKey,
  kc as getListPdBusinessDetailsBankInfoQueryOptions,
  Bc as getListPdBusinessDetailsBusinessInfoQueryKey,
  Cc as getListPdBusinessDetailsBusinessInfoQueryOptions,
  Ac as getListPdBusinessDetailsFundedProductDetailsQueryKey,
  Uc as getListPdBusinessDetailsFundedProductDetailsQueryOptions,
  Gc as getListPdBusinessDetailsFundedProductPaymentProgressQueryKey,
  Tc as getListPdBusinessDetailsFundedProductPaymentProgressQueryOptions,
  Sc as getListPdBusinessDetailsFundedProductsQueryKey,
  vc as getListPdBusinessDetailsFundedProductsQueryOptions,
  wc as getListPdBusinessDetailsOffersQueryKey,
  Qc as getListPdBusinessDetailsOffersQueryOptions,
  Wc as getListPdBusinessDetailsOwnerInfoQueryKey,
  Vc as getListPdBusinessDetailsOwnerInfoQueryOptions,
  Xc as getListPdBusinessTableRowsQueryKey,
  jc as getListPdBusinessTableRowsQueryOptions,
  my as getListPdCapitalProductApplicationRowsQueryKey,
  ly as getListPdCapitalProductApplicationRowsQueryOptions,
  hy as getListPdCapitalProductOfferRowsQueryKey,
  Ky as getListPdCapitalProductOfferRowsQueryOptions,
  ny as getListPdCapitalProductRowsQueryKey,
  ry as getListPdCapitalProductRowsQueryOptions,
  kd as getListPdCrmDeliveryAttemptsQueryKey,
  Rd as getListPdCrmDeliveryAttemptsQueryOptions,
  Sd as getListPdCrmEventInfoQueryKey,
  vd as getListPdCrmEventInfoQueryOptions,
  xd as getListPdCrmEventsQueryKey,
  Md as getListPdCrmEventsQueryOptions,
  Ly as getListPdCrmIntegrationsQueryKey,
  Wy as getListPdCrmIntegrationsQueryOptions,
  Op as getListPdFeeDiscountProgramCohortTableRowsQueryKey,
  fp as getListPdFeeDiscountProgramCohortTableRowsQueryOptions,
  yp as getListPdFeeDiscountProgramQueryKey,
  dp as getListPdFeeDiscountProgramQueryOptions,
  Zy as getListPdMarketingCampaignsQueryKey,
  Jy as getListPdMarketingCampaignsQueryOptions,
  Ng as getListPdMarketingEmailBatchLogsQueryKey,
  Gg as getListPdMarketingEmailBatchLogsQueryOptions,
  iy as getListPdOrderRowsQueryKey,
  cy as getListPdOrderRowsQueryOptions,
  By as getListPdPartnerEventListUserSubscriptionsQueryKey,
  Cy as getListPdPartnerEventListUserSubscriptionsQueryOptions,
  Sy as getListPdPartnerEventListWebNotificationsQueryKey,
  vy as getListPdPartnerEventListWebNotificationsQueryOptions,
  ed as getListPdScheduledMarketingConfigsQueryKey,
  nd as getListPdScheduledMarketingConfigsQueryOptions,
  by as getListPdSupportInfoQueryKey,
  xy as getListPdSupportInfoQueryOptions,
  Qd as getListPdWebhookEndpointsQueryKey,
  Ld as getListPdWebhookEndpointsQueryOptions,
  gd as getListPdWebhookLogsDeliveryAttemptsQueryKey,
  qd as getListPdWebhookLogsDeliveryAttemptsQueryOptions,
  _d as getListPdWebhookLogsEventInfoQueryKey,
  md as getListPdWebhookLogsEventInfoQueryOptions,
  yd as getListPdWebhookLogsEventsQueryKey,
  dd as getListPdWebhookLogsEventsQueryOptions,
  ec as getListS3OfferCsvsQueryKey,
  nc as getListS3OfferCsvsQueryOptions,
  tp as getListS3PartnerDashboardTableCsvsQueryKey,
  ep as getListS3PartnerDashboardTableCsvsQueryOptions,
  nm as getListSardineDetailsQueryKey,
  rm as getListSardineDetailsQueryOptions,
  ac as getListSendMarketingEmailBatchesQueryKey,
  uc as getListSendMarketingEmailBatchesQueryOptions,
  Cn as getListVersionedSchemasQueryKey,
  En as getListVersionedSchemasQueryOptions,
  P_ as getMarkPADAgreementSignedMutationOptions,
  ue as getMarketingGtmToolkit,
  te as getMarketingIntegrations,
  ye as getMarketingParagonToken,
  Sa as getMerchantBankAccounts,
  Fa as getMerchantBankAccountsId,
  Ba as getMerchantBankAccountsIdBalances,
  e_ as getMerchantBankApplications,
  Zp as getMerchantBankApplicationsId,
  Cu as getMerchantBankCardAccessToken,
  Au as getMerchantBankCardCardholderAccessToken,
  Mu as getMerchantBankCardCardholders,
  Qu as getMerchantBankCardDisputes,
  Ou as getMerchantBankCards,
  ri as getMerchantBankExternalAccounts,
  ii as getMerchantBankExternalAccountsId,
  ti as getMerchantBankExternalAccountsIdPlaidLinkToken,
  ju as getMerchantBankExternalAccountsPlaidLinkToken,
  ja as getMerchantBankInterest,
  X_ as getMerchantBankOffers,
  tu as getMerchantBankRecipients,
  ou as getMerchantBankRecipientsId,
  Qa as getMerchantBankTransactions,
  Ta as getMerchantBankTransactionsId,
  Va as getMerchantBankTransferFees,
  Ha as getMerchantBankTransferLimits,
  fd as getMultiWebhookPdWebhookLogsDeliveryAttemptsQueryKey,
  hd as getMultiWebhookPdWebhookLogsDeliveryAttemptsQueryOptions,
  d_ as getNaics,
  bg as getNetSettlementsOptInMutationOptions,
  fg as getNetSettlementsOptInStatus,
  Li as getOfficerSubsidiaryRecords,
  Tn as getOnboardingBusinessRequirements,
  Ys as getOnboardingInfoNeeded,
  $s as getOnboardingStates,
  ml as getOptIns,
  Kl as getOptOutEligibility,
  tl as getOrder,
  mt as getOrganization,
  Hy as getOwnerAuth,
  Pe as getOwnerIsBeneficialOwner,
  ro as getOwners,
  O_ as getPADAgreement,
  Rp as getPDCancelOrderMutationOptions,
  Fp as getPDFinalizeOrderMutationOptions,
  ma as getParafinder,
  qa as getParafinderBusinessTypes,
  ba as getParafinderCapitalInfo,
  ha as getParafinderSummary,
  nt as getPartnerApiKey,
  Qt as getPartnerConfigs,
  Tt as getPartnerPublicConfigs,
  mc as getPartnerScheduledMarketingEmailConfigMutationOptions,
  xt as getPartnerUsers,
  zp as getPartnerWidgetConfigs,
  $ as getPartners,
  R as getPartnersDoordashAdminUsers,
  j as getPartnersUnderwriting,
  hm as getPatchAuthManagementUseridSamlSamlconfigidMutationOptions,
  Ke as getPatchBeneficialOwnersIdMutationOptions,
  no as getPatchBusinessIdentitiesIdMutationOptions,
  Xe as getPatchBusinessOfficersIdMutationOptions,
  rs as getPatchCapitalProductDisputesDisputeidMutationOptions,
  Qr as getPatchCapitalProductIntentsMutationOptions,
  jn as getPatchCapitalProductsMutationOptions,
  wm as getPatchCardholdersCardholderidMutationOptions,
  T as getPatchCashAdvancePausesP1MutationOptions,
  Os as getPatchClearWatchlistHitsIdMutationOptions,
  Gn as getPatchDataingestionStripeIngestionsMutationOptions,
  bo as getPatchFlexLoanAgeMutationOptions,
  ko as getPatchFlexLoanFundMutationOptions,
  Mo as getPatchFlexLoanOwnershipMutationOptions,
  aa as getPatchLiensIdMutationOptions,
  xu as getPatchMerchantBankCardCardholdersIdMutationOptions,
  mi as getPatchMerchantBankExternalAccountsIdMutationOptions,
  Oi as getPatchMerchantBankExternalBankTransfersIdMutationOptions,
  om as getPatchMerchantBankMerchantConfigMutationOptions,
  qu as getPatchMerchantBankRecipientTransfersIdMutationOptions,
  du as getPatchMerchantBankRecipientsIdMutationOptions,
  Y_ as getPatchMfaExtendSessionMutationOptions,
  Fe as getPatchOwnerIsBeneficialOwnerMutationOptions,
  uo as getPatchOwnersIdMutationOptions,
  $t as getPatchPartnerConfigsIdMutationOptions,
  Ct as getPatchPartnerUsersMutationOptions,
  Gt as getPatchPartnerUsersV2MutationOptions,
  jp as getPatchPartnerWidgetConfigsMutationOptions,
  et as getPatchPartnersIdMutationOptions,
  Is as getPatchPersonaFallbackInquiryMutationOptions,
  yg as getPatchRootMutationOptions,
  Bl as getPatchSandboxCapitalProductFundMutationOptions,
  El as getPatchSandboxCapitalProductFundingChecklistMutationOptions,
  vl as getPatchSandboxCapitalProductTopUpMutationOptions,
  Ll as getPatchSandboxRepaymentsMutationOptions,
  B_ as getPatchTemplatesMutationOptions,
  mp as getPdFeeDiscountProgram,
  sp as getPdFeeDiscountProgramAccessRequest,
  My as getPdOfferUrl,
  hp as getPdRevenueShare,
  Vy as getPdUtilityInfo,
  Ds as getPlaidInvestigations,
  Ii as getPostAccountingInfoMutationOptions,
  qo as getPostAmazonRedirectUrlMutationOptions,
  Rm as getPostAuthEmailOtpSendMutationOptions,
  Fm as getPostAuthEmailOtpStartMutationOptions,
  xm as getPostAuthEmailOtpV2VerifyMutationOptions,
  Pm as getPostAuthEmailOtpVerifyMutationOptions,
  cm as getPostAuthLoginStartMutationOptions,
  um as getPostAuthLoginValidateOtpMutationOptions,
  gm as getPostAuthManagementUseridSamlMutationOptions,
  dm as getPostAuthTokenExchangeMutationOptions,
  Ht as getPostAuthTokensMutationOptions,
  qe as getPostBeneficialOwnersMutationOptions,
  hl as getPostBulkOptInMutationOptions,
  k as getPostBusinessAnnotationsMutationOptions,
  To as getPostBusinessAuthLinkMutationOptions,
  P as getPostBusinessCoresMutationOptions,
  _o as getPostBusinessMutationOptions,
  $e as getPostBusinessOfficersMutationOptions,
  fc as getPostBusinessesNotificationsMutationOptions,
  gr as getPostCapitalProductApplicationMutationOptions,
  Ko as getPostCapitalProductBusinessMutationOptions,
  is as getPostCapitalProductDisputesMutationOptions,
  Kr as getPostCapitalProductNetTopUpMutationOptions,
  ir as getPostCapitalProductOfferCollectionMutationOptions,
  Ci as getPostCardCloseAccountMutationOptions,
  Do as getPostCardsMutationOptions,
  A as getPostCashAdvancePausesMutationOptions,
  Ze as getPostDataingestionOneschemaGenerateJwtMutationOptions,
  gn as getPostDataingestionS3DatasetIntegrationsMutationOptions,
  Kn as getPostDataingestionS3OrgDatasetIntegrationsMutationOptions,
  Rn as getPostDataingestionS3RunDatasetIngestionMutationOptions,
  Un as getPostDataingestionStripeIngestionsMutationOptions,
  Mi as getPostDebtsCheckCompleteMutationOptions,
  bi as getPostDebtsMutationOptions,
  Wo as getPostDocumentUploadGroupMutationOptions,
  Ai as getPostFinancialReviewsMutationOptions,
  vs as getPostFundingAccountsMutationOptions,
  Vs as getPostKycRetryMutationOptions,
  V_ as getPostManualRepaymentMutationOptions,
  wp as getPostMarkAccountingIntegrationSeenMutationOptions,
  se as getPostMarketingIntegrationsMutationOptions,
  Jt as getPostMarketingReviewMutationOptions,
  Ua as getPostMerchantBankAccountsMutationOptions,
  o_ as getPostMerchantBankApplicationsMutationOptions,
  lo as getPostMerchantBankBusinessMutationOptions,
  Bu as getPostMerchantBankCardActivateMutationOptions,
  Du as getPostMerchantBankCardApplePayMutationOptions,
  Xu as getPostMerchantBankCardDisputeChargebackCreditMutationOptions,
  Hu as getPostMerchantBankCardDisputeStateTransitionMutationOptions,
  $u as getPostMerchantBankCardDisputesMutationOptions,
  wu as getPostMerchantBankCardGooglePayMutationOptions,
  Tu as getPostMerchantBankCardStateTransitionMutationOptions,
  Pu as getPostMerchantBankCardsMutationOptions,
  ui as getPostMerchantBankExternalAccountsMutationOptions,
  gi as getPostMerchantBankExternalBankTransfersMutationOptions,
  Ga as getPostMerchantBankInnerTransfersMutationOptions,
  tm as getPostMerchantBankOffersMutationOptions,
  lu as getPostMerchantBankRecipientTransfersMutationOptions,
  _u as getPostMerchantBankRecipientsIdPaymentRailsMutationOptions,
  su as getPostMerchantBankRecipientsMutationOptions,
  l_ as getPostMfaOtpSendPersonidPhonenumbertypeMutationOptions,
  q_ as getPostMfaOtpVerifyPersonidPhonenumbertypeMutationOptions,
  z_ as getPostMfaVerifyAccountDetailsMutationOptions,
  zi as getPostOfficerSubsidiaryRecordsMutationOptions,
  ia as getPostOptInMutationOptions,
  Ol as getPostOptInsMutationOptions,
  Ml as getPostOptOutMutationOptions,
  Rt as getPostPartnerUsersMutationOptions,
  Ut as getPostPartnerUsersV2MutationOptions,
  X as getPostPartnersMutationOptions,
  Zo as getPostRestrictionMutationOptions,
  Up as getPostRutterConnectionsMutationOptions,
  Nl as getPostSandboxAmazonLockBankAccountMutationOptions,
  Tl as getPostSandboxBreakBankAccountMutationOptions,
  Al as getPostSandboxCapitalProductPayOffMutationOptions,
  Vl as getPostSandboxMerchantBankSetupBusinessMutationOptions,
  wl as getPostSandboxRepaymentsMutationOptions,
  $p as getPostSardineSessionIdMutationOptions,
  Bo as getPostSetupAmazonTopUpMutationOptions,
  Ot as getPostSlackAuthorizeMutationOptions,
  fo as getPostSpdMutationOptions,
  Qi as getPostTaxRecordsMutationOptions,
  L_ as getPostTemplateTypesMutationOptions,
  k_ as getPostTemplatesMutationOptions,
  Kc as getPostTermsOfServiceMarkShownMutationOptions,
  _s as getPostTriggerRunCelticsChecklistMutationOptions,
  u_ as getPostWebhooksPlaidGeneralMutationOptions,
  Fn as getPutDataingestionS3DatasetIngestionConfigsMutationOptions,
  kl as getPutSandboxCapitalProductTestOfferMutationOptions,
  zl as getPutSandboxFeeDiscountMutationOptions,
  il as getRefund,
  dl as getRefundCapitalProductId,
  I as getRepaymentPlanParameters,
  Hl as getRepayments,
  Cd as getResendCrmAttemptMutationOptions,
  Ho as getRestriction,
  Jo as getRestrictionsDetails,
  ms as getRunOfacScreeningResults,
  Np as getRutterConnectionOptions,
  Sp as getRutterConnections,
  Xd as getS3PartnerDashboardTableCsv,
  Qp as getSardineSessionId,
  cc as getSendMarketingEmailsMutationOptions,
  Dg as getShouldPlaidLinkWithInvestigationQueryKey,
  Ag as getShouldPlaidLinkWithInvestigationQueryOptions,
  ft as getSlackChannels,
  rg as getStatements,
  Yt as getStatementsV2,
  Ud as getSync,
  Td as getSyncAndMaybeMarkFailed,
  C_ as getTemplateMock,
  U_ as getTemplateSnapshot,
  v_ as getTemplateSnapshots,
  T_ as getTemplateTypes,
  b_ as getTemplates,
  fs as getTermLoanProspectiveRepayments,
  dg as getUnderwritingStatusForOrder,
  Rs as getUpdateBankAccountInfoMutationOptions,
  Ty as getUpdatePdPartnerEventUpdateUserSubscriptionMutationOptions,
  wy as getUpdatePdPartnerEventUpdateUserSubscriptionsForEventTypeMutationOptions,
  sd as getUpdatePdScheduledMarketingConfigMutationOptions,
  Yd as getUpdatePdWebhookEndpointMutationOptions,
  wg as kitchen,
  pt as linkPartner,
  ji as listApiRequests,
  Cg as listApplications,
  xg as listBankLinkingError,
  Je as listDatasetUploads,
  nn as listManualDatasetUploadValidationResults,
  ol as listOrders,
  ut as listOrganizations,
  yc as listPartnerScheduledMarketingEmailConfigs,
  Pc as listPdBusinessDetailsApplications,
  Mc as listPdBusinessDetailsBankInfo,
  Rc as listPdBusinessDetailsBusinessInfo,
  Dc as listPdBusinessDetailsFundedProductDetails,
  Nc as listPdBusinessDetailsFundedProductPaymentProgress,
  Ec as listPdBusinessDetailsFundedProducts,
  Ic as listPdBusinessDetailsOffers,
  Lc as listPdBusinessDetailsOwnerInfo,
  Yc as listPdBusinessTableRows,
  _y as listPdCapitalProductApplicationRows,
  fy as listPdCapitalProductOfferRows,
  ey as listPdCapitalProductRows,
  Fd as listPdCrmDeliveryAttempts,
  Ed as listPdCrmEventInfo,
  bd as listPdCrmEvents,
  Qy as listPdCrmIntegrations,
  cp as listPdFeeDiscountProgram,
  qp as listPdFeeDiscountProgramCohortTableRows,
  jy as listPdMarketingCampaigns,
  Ug as listPdMarketingEmailBatchLogs,
  uy as listPdOrderRows,
  Ry as listPdPartnerEventListUserSubscriptions,
  Ey as listPdPartnerEventListWebNotifications,
  td as listPdScheduledMarketingConfigs,
  Py as listPdSupportInfo,
  wd as listPdWebhookEndpoints,
  ld as listPdWebhookLogsDeliveryAttempts,
  pd as listPdWebhookLogsEventInfo,
  cd as listPdWebhookLogsEvents,
  tc as listS3OfferCsvs,
  Jd as listS3PartnerDashboardTableCsvs,
  em as listSardineDetails,
  oc as listSendMarketingEmailBatches,
  Bn as listVersionedSchemas,
  K_ as markPADAgreementSigned,
  Od as multiWebhookPdWebhookLogsDeliveryAttempts,
  Pg as netSettlementsOptIn,
  kp as pDCancelOrder,
  Mp as pDFinalizeOrder,
  _c as partnerScheduledMarketingEmailConfig,
  fm as patchAuthManagementUseridSamlSamlconfigid,
  he as patchBeneficialOwnersId,
  eo as patchBusinessIdentitiesId,
  Ye as patchBusinessOfficersId,
  ns as patchCapitalProductDisputesDisputeid,
  wr as patchCapitalProductIntents,
  Xn as patchCapitalProducts,
  Im as patchCardholdersCardholderid,
  G as patchCashAdvancePausesP1,
  qs as patchClearWatchlistHitsId,
  Nn as patchDataingestionStripeIngestions,
  Po as patchFlexLoanAge,
  Fo as patchFlexLoanFund,
  xo as patchFlexLoanOwnership,
  oa as patchLiensId,
  bu as patchMerchantBankCardCardholdersId,
  _i as patchMerchantBankExternalAccountsId,
  qi as patchMerchantBankExternalBankTransfersId,
  sm as patchMerchantBankMerchantConfig,
  gu as patchMerchantBankRecipientTransfersId,
  yu as patchMerchantBankRecipientsId,
  H_ as patchMfaExtendSession,
  Me as patchOwnerIsBeneficialOwner,
  ao as patchOwnersId,
  Vt as patchPartnerConfigsId,
  Bt as patchPartnerUsers,
  Nt as patchPartnerUsersV2,
  Xp as patchPartnerWidgetConfigs,
  tt as patchPartnersId,
  Ts as patchPersonaFallbackInquiry,
  cg as patchRoot,
  Rl as patchSandboxCapitalProductFund,
  Cl as patchSandboxCapitalProductFundingChecklist,
  Sl as patchSandboxCapitalProductTopUp,
  Ql as patchSandboxRepayments,
  R_ as patchTemplates,
  Ti as postAccountingInfo,
  go as postAmazonRedirectUrl,
  km as postAuthEmailOtpSend,
  Mm as postAuthEmailOtpStart,
  bm as postAuthEmailOtpV2Verify,
  Km as postAuthEmailOtpVerify,
  im as postAuthLoginStart,
  am as postAuthLoginValidateOtp,
  lm as postAuthManagementUseridSaml,
  ym as postAuthTokenExchange,
  zt as postAuthTokens,
  ge as postBeneficialOwners,
  fl as postBulkOptIn,
  po as postBusiness,
  F as postBusinessAnnotations,
  Go as postBusinessAuthLink,
  K as postBusinessCores,
  Ve as postBusinessOfficers,
  Oc as postBusinessesNotifications,
  lr as postCapitalProductApplication,
  ho as postCapitalProductBusiness,
  us as postCapitalProductDisputes,
  hr as postCapitalProductNetTopUp,
  ur as postCapitalProductOfferCollection,
  Bi as postCardCloseAccount,
  vo as postCards,
  D as postCashAdvancePauses,
  je as postDataingestionOneschemaGenerateJwt,
  ln as postDataingestionS3DatasetIntegrations,
  hn as postDataingestionS3OrgDatasetIntegrations,
  kn as postDataingestionS3RunDatasetIngestion,
  An as postDataingestionStripeIngestions,
  Pi as postDebts,
  xi as postDebtsCheckComplete,
  Lo as postDocumentUploadGroup,
  Di as postFinancialReviews,
  Ss as postFundingAccounts,
  Ws as postKycRetry,
  W_ as postManualRepayment,
  Ip as postMarkAccountingIntegrationSeen,
  re as postMarketingIntegrations,
  Zt as postMarketingReview,
  Aa as postMerchantBankAccounts,
  s_ as postMerchantBankApplications,
  mo as postMerchantBankBusiness,
  Ru as postMerchantBankCardActivate,
  vu as postMerchantBankCardApplePay,
  Yu as postMerchantBankCardDisputeChargebackCredit,
  zu as postMerchantBankCardDisputeStateTransition,
  Vu as postMerchantBankCardDisputes,
  Iu as postMerchantBankCardGooglePay,
  Gu as postMerchantBankCardStateTransition,
  Ku as postMerchantBankCards,
  ai as postMerchantBankExternalAccounts,
  li as postMerchantBankExternalBankTransfers,
  Na as postMerchantBankInnerTransfers,
  J_ as postMerchantBankOffers,
  mu as postMerchantBankRecipientTransfers,
  ru as postMerchantBankRecipients,
  pu as postMerchantBankRecipientsIdPaymentRails,
  m_ as postMfaOtpSendPersonidPhonenumbertype,
  g_ as postMfaOtpVerifyPersonidPhonenumbertype,
  $_ as postMfaVerifyAccountDetails,
  $i as postOfficerSubsidiaryRecords,
  ua as postOptIn,
  ql as postOptIns,
  xl as postOptOut,
  kt as postPartnerUsers,
  At as postPartnerUsersV2,
  Y as postPartners,
  jo as postRestriction,
  Ap as postRutterConnections,
  Ul as postSandboxAmazonLockBankAccount,
  Gl as postSandboxBreakBankAccount,
  Dl as postSandboxCapitalProductPayOff,
  Wl as postSandboxMerchantBankSetupBusiness,
  Il as postSandboxRepayments,
  Vp as postSardineSessionId,
  Ro as postSetupAmazonTopUp,
  qt as postSlackAuthorize,
  Oo as postSpd,
  wi as postTaxRecords,
  Q_ as postTemplateTypes,
  F_ as postTemplates,
  hc as postTermsOfServiceMarkShown,
  ps as postTriggerRunCelticsChecklist,
  a_ as postWebhooksPlaidGeneral,
  ik as publicApiPaths,
  Mn as putDataingestionS3DatasetIngestionConfigs,
  Fl as putSandboxCapitalProductTestOffer,
  $l as putSandboxFeeDiscount,
  Bd as resendCrmAttempt,
  ic as sendMarketingEmails,
  vg as shouldPlaidLinkWithInvestigation,
  ks as updateBankAccountInfo,
  Gy as updatePdPartnerEventUpdateUserSubscription,
  Iy as updatePdPartnerEventUpdateUserSubscriptionsForEventType,
  rd as updatePdScheduledMarketingConfig,
  Hd as updatePdWebhookEndpoint,
  ek as useAmazonRPCPreLogin,
  ok as useAttemptAmazonBankVerification,
  Wx as useAttemptSync,
  bx as useBatchCreatePdPartnerEventMarkAllNotificationsRead,
  Tx as useBatchResendWebhookAttempt,
  AK as useBenchmarkGetCapitalProductOwnership,
  dF as useCancelOrder,
  cx as useCountPdBusinessTableRows,
  lx as useCountPdCapitalProductApplicationRow,
  qx as useCountPdCapitalProductOfferRow,
  dx as useCountPdCapitalProductRow,
  _x as useCountPdOrderRow,
  GF as useCreateAttemptToUnderwriteBnplOrder,
  nF as useCreateAttemptToUnderwriteIntroductoryOffers,
  eP as useCreateBankAccountInfo,
  zF as useCreateBeneficialOwner,
  TK as useCreateCapitalProductIntents,
  LK as useCreateCapitalProductSuperApplicationActions,
  QK as useCreateCapitalProductSuperApplications,
  PK as useCreateCapitalProducts,
  NK as useCreateOfferedCapitalProductFeeDiscount,
  Oh as useCreateOrganization,
  gh as useCreatePartnerApiKey,
  uM as useCreatePdCreateOrder,
  rM as useCreatePdFeeDiscountProgram,
  eM as useCreatePdFeeDiscountProgramAccessRequest,
  xx as useCreatePdPartnerEventMarkNotificationRead,
  Hx as useCreatePdWebhookEndpoint,
  TP as useCreatePersonOptIn,
  aP as useCreatePlaidInvestigation,
  ak as useCreatePlaidLinkage,
  zb as useCreateS3OfferCsv,
  Jx as useCreateS3PartnerDashboardTableCsv,
  tk as useCreateUnderwritingAttempt,
  ZF as useCreateUnverifiedAmazonFundingAccount,
  jF as useCreateUnverifiedFundingAccount,
  sk as useCreateUnverifiedFundingAccountFromPlaidLinkage,
  uk as useCreateZendeskTicket,
  YM as useDeleteAuthManagementUseridSamlSamlconfigid,
  YF as useDeleteBeneficialOwner,
  Wh as useDeleteBeneficialOwnersId,
  eK as useDeleteBusinessOfficersId,
  uh as useDeleteCashAdvancePausesP1,
  Th as useDeleteMarketingIntegrationsVendor,
  Rb as useDeleteMerchantBankExternalAccountsId,
  sb as useDeleteMerchantBankRecipientsId,
  Rh as useDeleteOrganizationDeleteUsers,
  kh as useDeletePartnerDeleteUsers,
  Yx as useDeletePdWebhookEndpoint,
  bh as useDeleteSlackChannelsId,
  rk as useDisputeTransaction,
  Dx as useExperimentalCapitalProductOfferClosedSandbox,
  vx as useExperimentalCapitalProductOfferCreatedSandbox,
  Tb as useGetAccountingInfo,
  bM as useGetAmazonFundingAccountLock,
  Wb as useGetAnalyticsCubeToken,
  LF as useGetApplication,
  zM as useGetAuthManagementP1Saml,
  tP as useGetBankAccountInfos,
  Hh as useGetBanks,
  Qh as useGetBeneficialOwners,
  TF as useGetBnplSpendingPower,
  nh as useGetBusinessAnnotations,
  th as useGetBusinessCores,
  gP as useGetBusinessCount,
  yM as useGetBusinessDetailsForOrder,
  pP as useGetBusinessIdentities,
  Jh as useGetBusinessOfficers,
  Zh as useGetBusinessTableRowsCard,
  jh as useGetBusinessTableRowsLoan,
  Xh as useGetBusinessTableRowsMca,
  Yh as useGetBusinessTableRowsProductAgnostic,
  IP as useGetBusinessesDailySalesRecords,
  Zb as useGetBusinessesNotifications,
  RP as useGetBusinessesProducts,
  SF as useGetBusinessesShowTopUpOffer,
  WK as useGetCalculatedChunkValues,
  RK as useGetCapitalProductActivities,
  MK as useGetCapitalProductApplicationAmountSummary,
  EK as useGetCapitalProductApplicationSnapshots,
  BK as useGetCapitalProductApplications,
  zK as useGetCapitalProductDisputes,
  VK as useGetCapitalProductDisputesDisputeid,
  GK as useGetCapitalProductIntents,
  vK as useGetCapitalProductNetTopUpItemization,
  FK as useGetCapitalProductOfferCollection,
  hK as useGetCapitalProductProspectiveTermsOfService,
  UK as useGetCapitalProductRepaymentSchedule,
  DK as useGetCapitalProductStateDisclosure,
  KK as useGetCapitalProducts,
  xK as useGetCapitalProductsSummary,
  aF as useGetCardAccessToken,
  rF as useGetCardBalances,
  uF as useGetCardLifecycleStates,
  cF as useGetCardPendingTransactions,
  iF as useGetCardTransactions,
  sF as useGetCardholders,
  FP as useGetCards,
  oh as useGetCashAdvancePauses,
  wK as useGetCounteroffer,
  pK as useGetDataingestionS3DatasetIngestionConfigs,
  iK as useGetDataingestionS3DatasetIntegrations,
  uK as useGetDataingestionS3DatasetUploadValidationResults,
  aK as useGetDataingestionS3DatasetUploads,
  yK as useGetDataingestionS3OrgDatasetIntegrations,
  gK as useGetDataingestionStripeIngestions,
  Ab as useGetDebtChecks,
  Sb as useGetDebts,
  CP as useGetDocumentUploadGroup,
  SP as useGetDocumentUploadList,
  AF as useGetEarlyManualRepaymentWhitelist,
  Nb as useGetFinancialReviews,
  YK as useGetFlexLoanMinimumRepaymentSchedule,
  rP as useGetFundingAccounts,
  vF as useGetFundingPlaidLinkToken,
  yh as useGetFutureActivities,
  iP as useGetKycRetryFields,
  eF as useGetLatestAttemptToUnderwriteIntroductoryOffers,
  UP as useGetLiens,
  Ih as useGetMarketingGtmToolkit,
  Nh as useGetMarketingIntegrations,
  wh as useGetMarketingParagonToken,
  zP as useGetMerchantBankAccounts,
  VP as useGetMerchantBankAccountsId,
  $P as useGetMerchantBankAccountsIdBalances,
  hM as useGetMerchantBankApplications,
  fM as useGetMerchantBankApplicationsId,
  mb as useGetMerchantBankCardAccessToken,
  gb as useGetMerchantBankCardCardholderAccessToken,
  pb as useGetMerchantBankCardCardholders,
  fb as useGetMerchantBankCardDisputes,
  cb as useGetMerchantBankCards,
  Mb as useGetMerchantBankExternalAccounts,
  kb as useGetMerchantBankExternalAccountsId,
  xb as useGetMerchantBankExternalAccountsIdPlaidLinkToken,
  bb as useGetMerchantBankExternalAccountsPlaidLinkToken,
  tb as useGetMerchantBankInterest,
  IM as useGetMerchantBankOffers,
  eb as useGetMerchantBankRecipients,
  rb as useGetMerchantBankRecipientsId,
  jP as useGetMerchantBankTransactions,
  XP as useGetMerchantBankTransactionsId,
  ZP as useGetMerchantBankTransferFees,
  JP as useGetMerchantBankTransferLimits,
  xM as useGetNaics,
  IF as useGetNetSettlementsOptInStatus,
  Qb as useGetOfficerSubsidiaryRecords,
  fK as useGetOnboardingBusinessRequirements,
  dP as useGetOnboardingInfoNeeded,
  yP as useGetOnboardingStates,
  lF as useGetOptIns,
  OF as useGetOptOutEligibility,
  yF as useGetOrder,
  hh as useGetOrganization,
  Bx as useGetOwnerAuth,
  $h as useGetOwnerIsBeneficialOwner,
  mP as useGetOwners,
  kM as useGetPADAgreement,
  wP as useGetParafinder,
  QP as useGetParafinderBusinessTypes,
  WP as useGetParafinderCapitalInfo,
  LP as useGetParafinderSummary,
  lh as useGetPartnerApiKey,
  Sh as useGetPartnerConfigs,
  Eh as useGetPartnerPublicConfigs,
  xh as useGetPartnerUsers,
  qM as useGetPartnerWidgetConfigs,
  dh as useGetPartners,
  sh as useGetPartnersDoordashAdminUsers,
  _h as useGetPartnersUnderwriting,
  sM as useGetPdFeeDiscountProgram,
  tM as useGetPdFeeDiscountProgramAccessRequest,
  hx as useGetPdOfferUrl,
  aM as useGetPdRevenueShare,
  Rx as useGetPdUtilityInfo,
  oP as useGetPlaidInvestigations,
  _F as useGetRefund,
  mF as useGetRefundCapitalProductId,
  ch as useGetRepaymentPlanParameters,
  EF as useGetRepayments,
  vP as useGetRestriction,
  AP as useGetRestrictionsDetails,
  jK as useGetRunOfacScreeningResults,
  _M as useGetRutterConnectionOptions,
  dM as useGetRutterConnections,
  jx as useGetS3PartnerDashboardTableCsv,
  lM as useGetSardineSessionId,
  Ph as useGetSlackChannels,
  DF as useGetStatements,
  Ah as useGetStatementsV2,
  Vx as useGetSync,
  $x as useGetSyncAndMaybeMarkFailed,
  SM as useGetTemplateMock,
  DM as useGetTemplateSnapshot,
  vM as useGetTemplateSnapshots,
  AM as useGetTemplateTypes,
  BM as useGetTemplates,
  JK as useGetTermLoanProspectiveRepayments,
  NF as useGetUnderwritingStatusForOrder,
  fh as useLinkPartner,
  Vb as useListApiRequests,
  WF as useListApplications,
  QF as useListBankLinkingError,
  sK as useListDatasetUploads,
  oK as useListManualDatasetUploadValidationResults,
  pF as useListOrders,
  qh as useListOrganizations,
  Xb as useListPartnerScheduledMarketingEmailConfigs,
  ex as useListPdBusinessDetailsApplications,
  nx as useListPdBusinessDetailsBankInfo,
  rx as useListPdBusinessDetailsBusinessInfo,
  ox as useListPdBusinessDetailsFundedProductDetails,
  ax as useListPdBusinessDetailsFundedProductPaymentProgress,
  sx as useListPdBusinessDetailsFundedProducts,
  ux as useListPdBusinessDetailsOffers,
  ix as useListPdBusinessDetailsOwnerInfo,
  yx as useListPdBusinessTableRows,
  gx as useListPdCapitalProductApplicationRows,
  Ox as useListPdCapitalProductOfferRows,
  px as useListPdCapitalProductRows,
  wx as useListPdCrmDeliveryAttempts,
  Lx as useListPdCrmEventInfo,
  Ix as useListPdCrmEvents,
  kx as useListPdCrmIntegrations,
  nM as useListPdFeeDiscountProgram,
  oM as useListPdFeeDiscountProgramCohortTableRows,
  Cx as useListPdMarketingCampaigns,
  $F as useListPdMarketingEmailBatchLogs,
  mx as useListPdOrderRows,
  Kx as useListPdPartnerEventListUserSubscriptions,
  Px as useListPdPartnerEventListWebNotifications,
  Ex as useListPdScheduledMarketingConfigs,
  fx as useListPdSupportInfo,
  zx as useListPdWebhookEndpoints,
  Nx as useListPdWebhookLogsDeliveryAttempts,
  Ux as useListPdWebhookLogsEventInfo,
  Ax as useListPdWebhookLogsEvents,
  $b as useListS3OfferCsvs,
  Zx as useListS3PartnerDashboardTableCsvs,
  QM as useListSardineDetails,
  Hb as useListSendMarketingEmailBatches,
  lK as useListVersionedSchemas,
  RM as useMarkPADAgreementSigned,
  Gx as useMultiWebhookPdWebhookLogsDeliveryAttempts,
  wF as useNetSettlementsOptIn,
  cM as usePDCancelOrder,
  iM as usePDFinalizeOrder,
  jb as usePartnerScheduledMarketingEmailConfig,
  XM as usePatchAuthManagementUseridSamlSamlconfigid,
  HF as usePatchBeneficialOwner,
  Vh as usePatchBeneficialOwnersId,
  _P as usePatchBusinessIdentitiesId,
  nK as usePatchBusinessOfficersId,
  $K as usePatchCapitalProductDisputesDisputeid,
  IK as usePatchCapitalProductIntents,
  bK as usePatchCapitalProducts,
  oF as usePatchCardholdersCardholderid,
  ih as usePatchCashAdvancePausesP1,
  ZK as usePatchClearWatchlistHitsId,
  OK as usePatchDataingestionStripeIngestions,
  PP as usePatchFlexLoanAge,
  xP as usePatchFlexLoanFund,
  bP as usePatchFlexLoanOwnership,
  NP as usePatchLiensId,
  db as usePatchMerchantBankCardCardholdersId,
  Bb as usePatchMerchantBankExternalAccountsId,
  Eb as usePatchMerchantBankExternalBankTransfersId,
  LM as usePatchMerchantBankMerchantConfig,
  ib as usePatchMerchantBankRecipientTransfersId,
  ob as usePatchMerchantBankRecipientsId,
  TM as usePatchMfaExtendSession,
  zh as usePatchOwnerIsBeneficialOwner,
  lP as usePatchOwnersId,
  vh as usePatchPartnerConfigsId,
  Fh as usePatchPartnerUsers,
  Ch as usePatchPartnerUsersV2,
  OM as usePatchPartnerWidgetConfigs,
  mh as usePatchPartnersId,
  uP as usePatchPersonaFallbackInquiry,
  UF as usePatchRoot,
  KF as usePatchSandboxCapitalProductFund,
  PF as usePatchSandboxCapitalProductFundingChecklist,
  bF as usePatchSandboxCapitalProductTopUp,
  RF as usePatchSandboxRepayments,
  EM as usePatchTemplates,
  Ib as usePostAccountingInfo,
  fP as usePostAmazonRedirectUrl,
  tF as usePostAuthEmailOtpSend,
  JM as usePostAuthEmailOtpStart,
  ZM as usePostAuthEmailOtpV2Verify,
  jM as usePostAuthEmailOtpVerify,
  VM as usePostAuthLoginStart,
  WM as usePostAuthLoginValidateOtp,
  HM as usePostAuthManagementUseridSaml,
  $M as usePostAuthTokenExchange,
  Dh as usePostAuthTokens,
  Lh as usePostBeneficialOwners,
  qF as usePostBulkOptIn,
  qP as usePostBusiness,
  rh as usePostBusinessAnnotations,
  BP as usePostBusinessAuthLink,
  eh as usePostBusinessCores,
  tK as usePostBusinessOfficers,
  Jb as usePostBusinessesNotifications,
  CK as usePostCapitalProductApplication,
  KP as usePostCapitalProductBusiness,
  HK as usePostCapitalProductDisputes,
  SK as usePostCapitalProductNetTopUp,
  kK as usePostCapitalProductOfferCollection,
  Ub as usePostCardCloseAccount,
  kP as usePostCards,
  ah as usePostCashAdvancePauses,
  rK as usePostDataingestionOneschemaGenerateJwt,
  cK as usePostDataingestionS3DatasetIntegrations,
  dK as usePostDataingestionS3OrgDatasetIntegrations,
  mK as usePostDataingestionS3RunDatasetIngestion,
  qK as usePostDataingestionStripeIngestions,
  vb as usePostDebts,
  Db as usePostDebtsCheckComplete,
  EP as usePostDocumentUploadGroup,
  Gb as usePostFinancialReviews,
  sP as usePostFundingAccounts,
  cP as usePostKycRetry,
  NM as usePostManualRepayment,
  mM as usePostMarkAccountingIntegrationSeen,
  Gh as usePostMarketingIntegrations,
  Uh as usePostMarketingReview,
  HP as usePostMerchantBankAccounts,
  KM as usePostMerchantBankApplications,
  OP as usePostMerchantBankBusiness,
  _b as usePostMerchantBankCardActivate,
  lb as usePostMerchantBankCardApplePay,
  Pb as usePostMerchantBankCardDisputeChargebackCredit,
  Kb as usePostMerchantBankCardDisputeStateTransition,
  hb as usePostMerchantBankCardDisputes,
  Ob as usePostMerchantBankCardGooglePay,
  qb as usePostMerchantBankCardStateTransition,
  yb as usePostMerchantBankCards,
  Fb as usePostMerchantBankExternalAccounts,
  Cb as usePostMerchantBankExternalBankTransfers,
  YP as usePostMerchantBankInnerTransfers,
  wM as usePostMerchantBankOffers,
  ub as usePostMerchantBankRecipientTransfers,
  nb as usePostMerchantBankRecipients,
  ab as usePostMerchantBankRecipientsIdPaymentRails,
  MM as usePostMfaOtpSendPersonidPhonenumbertype,
  FM as usePostMfaOtpVerifyPersonidPhonenumbertype,
  GM as usePostMfaVerifyAccountDetails,
  Lb as usePostOfficerSubsidiaryRecords,
  GP as usePostOptIn,
  gF as usePostOptIns,
  fF as usePostOptOut,
  Mh as usePostPartnerUsers,
  Bh as usePostPartnerUsersV2,
  ph as usePostPartners,
  DP as usePostRestriction,
  pM as usePostRutterConnections,
  MF as usePostSandboxAmazonLockBankAccount,
  FF as usePostSandboxBreakBankAccount,
  xF as usePostSandboxCapitalProductPayOff,
  BF as usePostSandboxMerchantBankSetupBusiness,
  kF as usePostSandboxRepayments,
  gM as usePostSardineSessionId,
  MP as usePostSetupAmazonTopUp,
  Kh as usePostSlackAuthorize,
  hP as usePostSpd,
  wb as usePostTaxRecords,
  UM as usePostTemplateTypes,
  CM as usePostTemplates,
  tx as usePostTermsOfServiceMarkShown,
  XK as usePostTriggerRunCelticsChecklist,
  PM as usePostWebhooksPlaidGeneral,
  _K as usePutDataingestionS3DatasetIngestionConfigs,
  hF as usePutSandboxCapitalProductTestOffer,
  CF as usePutSandboxFeeDiscount,
  Qx as useResendCrmAttempt,
  Yb as useSendMarketingEmails,
  VF as useShouldPlaidLinkWithInvestigation,
  XF as useSubmitKYC,
  nP as useUpdateBankAccountInfo,
  nk as useUpdateBoostCompletion,
  Mx as useUpdatePdPartnerEventUpdateUserSubscription,
  Fx as useUpdatePdPartnerEventUpdateUserSubscriptionsForEventType,
  Sx as useUpdatePdScheduledMarketingConfig,
  Xx as useUpdatePdWebhookEndpoint,
  JF as useUploadBankStatements
};
