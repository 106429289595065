import { useState as p, useEffect as h, useCallback as oe, useRef as ie } from "react";
const he = Object.prototype.toString;
function fe(e, t) {
  return he.call(e) === `[object ${t}]`;
}
function ge(e) {
  return fe(e, "Object");
}
function ye(e) {
  return !!(e && e.then && typeof e.then == "function");
}
const k = "8.35.0", w = globalThis;
function G(e, t, n) {
  const r = n || w, a = r.__SENTRY__ = r.__SENTRY__ || {}, s = a[k] = a[k] || {};
  return s[e] || (s[e] = t());
}
const ce = typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__, ve = "Sentry Logger ", H = [
  "debug",
  "info",
  "warn",
  "error",
  "log",
  "assert",
  "trace"
], q = {};
function we(e) {
  if (!("console" in w))
    return e();
  const t = w.console, n = {}, r = Object.keys(q);
  r.forEach((a) => {
    const s = q[a];
    n[a] = t[a], t[a] = s;
  });
  try {
    return e();
  } finally {
    r.forEach((a) => {
      t[a] = n[a];
    });
  }
}
function be() {
  let e = !1;
  const t = {
    enable: () => {
      e = !0;
    },
    disable: () => {
      e = !1;
    },
    isEnabled: () => e
  };
  return ce ? H.forEach((n) => {
    t[n] = (...r) => {
      e && we(() => {
        w.console[n](`${ve}[${n}]:`, ...r);
      });
    };
  }) : H.forEach((n) => {
    t[n] = () => {
    };
  }), t;
}
const x = G("logger", be);
function Se(e, t, n) {
  try {
    Object.defineProperty(e, t, {
      // enumerable: false, // the default, so we can save on bundle size by not explicitly setting it
      value: n,
      writable: !0,
      configurable: !0
    });
  } catch {
    ce && x.log(`Failed to add non-enumerable property "${t}" to object`, e);
  }
}
const ue = 1e3;
function de() {
  return Date.now() / ue;
}
function De() {
  const { performance: e } = w;
  if (!e || !e.now)
    return de;
  const t = Date.now() - e.now(), n = e.timeOrigin == null ? t : e.timeOrigin;
  return () => (n + e.now()) / ue;
}
const Ee = De();
(() => {
  const { performance: e } = w;
  if (!e || !e.now)
    return;
  const t = 3600 * 1e3, n = e.now(), r = Date.now(), a = e.timeOrigin ? Math.abs(e.timeOrigin + n - r) : t, s = a < t, o = e.timing && e.timing.navigationStart, c = typeof o == "number" ? Math.abs(o + n - r) : t, m = c < t;
  return s || m ? a <= c ? e.timeOrigin : o : r;
})();
function D() {
  const e = w, t = e.crypto || e.msCrypto;
  let n = () => Math.random() * 16;
  try {
    if (t && t.randomUUID)
      return t.randomUUID().replace(/-/g, "");
    t && t.getRandomValues && (n = () => {
      const r = new Uint8Array(1);
      return t.getRandomValues(r), r[0];
    });
  } catch {
  }
  return ([1e7] + 1e3 + 4e3 + 8e3 + 1e11).replace(
    /[018]/g,
    (r) => (
      // eslint-disable-next-line no-bitwise
      (r ^ (n() & 15) >> r / 4).toString(16)
    )
  );
}
function K() {
  return {
    traceId: D(),
    spanId: D().substring(16)
  };
}
function le() {
  return j(w), w;
}
function j(e) {
  const t = e.__SENTRY__ = e.__SENTRY__ || {};
  return t.version = t.version || k, t[k] = t[k] || {};
}
function Ce(e, t = {}) {
  if (t.user && (!e.ipAddress && t.user.ip_address && (e.ipAddress = t.user.ip_address), !e.did && !t.did && (e.did = t.user.id || t.user.email || t.user.username)), e.timestamp = t.timestamp || Ee(), t.abnormal_mechanism && (e.abnormal_mechanism = t.abnormal_mechanism), t.ignoreDuration && (e.ignoreDuration = t.ignoreDuration), t.sid && (e.sid = t.sid.length === 32 ? t.sid : D()), t.init !== void 0 && (e.init = t.init), !e.did && t.did && (e.did = `${t.did}`), typeof t.started == "number" && (e.started = t.started), e.ignoreDuration)
    e.duration = void 0;
  else if (typeof t.duration == "number")
    e.duration = t.duration;
  else {
    const n = e.timestamp - e.started;
    e.duration = n >= 0 ? n : 0;
  }
  t.release && (e.release = t.release), t.environment && (e.environment = t.environment), !e.ipAddress && t.ipAddress && (e.ipAddress = t.ipAddress), !e.userAgent && t.userAgent && (e.userAgent = t.userAgent), typeof t.errors == "number" && (e.errors = t.errors), t.status && (e.status = t.status);
}
const F = "_sentrySpan";
function z(e, t) {
  t ? Se(e, F, t) : delete e[F];
}
function X(e) {
  return e[F];
}
const Te = 100;
class V {
  /** Flag if notifying is happening. */
  /** Callback for client to receive scope changes. */
  /** Callback list that will be called during event processing. */
  /** Array of breadcrumbs. */
  /** User */
  /** Tags */
  /** Extra */
  /** Contexts */
  /** Attachments */
  /** Propagation Context for distributed tracing */
  /**
   * A place to stash data which is needed at some point in the SDK's event processing pipeline but which shouldn't get
   * sent to Sentry
   */
  /** Fingerprint */
  /** Severity */
  /**
   * Transaction Name
   *
   * IMPORTANT: The transaction name on the scope has nothing to do with root spans/transaction objects.
   * It's purpose is to assign a transaction to the scope that's added to non-transaction events.
   */
  /** Session */
  /** Request Mode Session Status */
  /** The client on this scope */
  /** Contains the last event id of a captured event.  */
  // NOTE: Any field which gets added here should get added not only to the constructor but also to the `clone` method.
  constructor() {
    this._notifyingListeners = !1, this._scopeListeners = [], this._eventProcessors = [], this._breadcrumbs = [], this._attachments = [], this._user = {}, this._tags = {}, this._extra = {}, this._contexts = {}, this._sdkProcessingMetadata = {}, this._propagationContext = K();
  }
  /**
   * @inheritDoc
   */
  clone() {
    const t = new V();
    return t._breadcrumbs = [...this._breadcrumbs], t._tags = { ...this._tags }, t._extra = { ...this._extra }, t._contexts = { ...this._contexts }, t._user = this._user, t._level = this._level, t._session = this._session, t._transactionName = this._transactionName, t._fingerprint = this._fingerprint, t._eventProcessors = [...this._eventProcessors], t._requestSession = this._requestSession, t._attachments = [...this._attachments], t._sdkProcessingMetadata = { ...this._sdkProcessingMetadata }, t._propagationContext = { ...this._propagationContext }, t._client = this._client, t._lastEventId = this._lastEventId, z(t, X(this)), t;
  }
  /**
   * @inheritDoc
   */
  setClient(t) {
    this._client = t;
  }
  /**
   * @inheritDoc
   */
  setLastEventId(t) {
    this._lastEventId = t;
  }
  /**
   * @inheritDoc
   */
  getClient() {
    return this._client;
  }
  /**
   * @inheritDoc
   */
  lastEventId() {
    return this._lastEventId;
  }
  /**
   * @inheritDoc
   */
  addScopeListener(t) {
    this._scopeListeners.push(t);
  }
  /**
   * @inheritDoc
   */
  addEventProcessor(t) {
    return this._eventProcessors.push(t), this;
  }
  /**
   * @inheritDoc
   */
  setUser(t) {
    return this._user = t || {
      email: void 0,
      id: void 0,
      ip_address: void 0,
      username: void 0
    }, this._session && Ce(this._session, { user: t }), this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  getUser() {
    return this._user;
  }
  /**
   * @inheritDoc
   */
  getRequestSession() {
    return this._requestSession;
  }
  /**
   * @inheritDoc
   */
  setRequestSession(t) {
    return this._requestSession = t, this;
  }
  /**
   * @inheritDoc
   */
  setTags(t) {
    return this._tags = {
      ...this._tags,
      ...t
    }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setTag(t, n) {
    return this._tags = { ...this._tags, [t]: n }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setExtras(t) {
    return this._extra = {
      ...this._extra,
      ...t
    }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setExtra(t, n) {
    return this._extra = { ...this._extra, [t]: n }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setFingerprint(t) {
    return this._fingerprint = t, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setLevel(t) {
    return this._level = t, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setTransactionName(t) {
    return this._transactionName = t, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setContext(t, n) {
    return n === null ? delete this._contexts[t] : this._contexts[t] = n, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setSession(t) {
    return t ? this._session = t : delete this._session, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  getSession() {
    return this._session;
  }
  /**
   * @inheritDoc
   */
  update(t) {
    if (!t)
      return this;
    const n = typeof t == "function" ? t(this) : t, [r, a] = n instanceof E ? [n.getScopeData(), n.getRequestSession()] : ge(n) ? [t, t.requestSession] : [], { tags: s, extra: o, user: i, contexts: c, level: m, fingerprint: f = [], propagationContext: y } = r || {};
    return this._tags = { ...this._tags, ...s }, this._extra = { ...this._extra, ...o }, this._contexts = { ...this._contexts, ...c }, i && Object.keys(i).length && (this._user = i), m && (this._level = m), f.length && (this._fingerprint = f), y && (this._propagationContext = y), a && (this._requestSession = a), this;
  }
  /**
   * @inheritDoc
   */
  clear() {
    return this._breadcrumbs = [], this._tags = {}, this._extra = {}, this._user = {}, this._contexts = {}, this._level = void 0, this._transactionName = void 0, this._fingerprint = void 0, this._requestSession = void 0, this._session = void 0, z(this, void 0), this._attachments = [], this._propagationContext = K(), this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  addBreadcrumb(t, n) {
    const r = typeof n == "number" ? n : Te;
    if (r <= 0)
      return this;
    const a = {
      timestamp: de(),
      ...t
    }, s = this._breadcrumbs;
    return s.push(a), this._breadcrumbs = s.length > r ? s.slice(-r) : s, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  getLastBreadcrumb() {
    return this._breadcrumbs[this._breadcrumbs.length - 1];
  }
  /**
   * @inheritDoc
   */
  clearBreadcrumbs() {
    return this._breadcrumbs = [], this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  addAttachment(t) {
    return this._attachments.push(t), this;
  }
  /**
   * @inheritDoc
   */
  clearAttachments() {
    return this._attachments = [], this;
  }
  /** @inheritDoc */
  getScopeData() {
    return {
      breadcrumbs: this._breadcrumbs,
      attachments: this._attachments,
      contexts: this._contexts,
      tags: this._tags,
      extra: this._extra,
      user: this._user,
      level: this._level,
      fingerprint: this._fingerprint || [],
      eventProcessors: this._eventProcessors,
      propagationContext: this._propagationContext,
      sdkProcessingMetadata: this._sdkProcessingMetadata,
      transactionName: this._transactionName,
      span: X(this)
    };
  }
  /**
   * @inheritDoc
   */
  setSDKProcessingMetadata(t) {
    return this._sdkProcessingMetadata = { ...this._sdkProcessingMetadata, ...t }, this;
  }
  /**
   * @inheritDoc
   */
  setPropagationContext(t) {
    return this._propagationContext = t, this;
  }
  /**
   * @inheritDoc
   */
  getPropagationContext() {
    return this._propagationContext;
  }
  /**
   * @inheritDoc
   */
  captureException(t, n) {
    const r = n && n.event_id ? n.event_id : D();
    if (!this._client)
      return x.warn("No client configured on scope - will not capture exception!"), r;
    const a = new Error("Sentry syntheticException");
    return this._client.captureException(
      t,
      {
        originalException: t,
        syntheticException: a,
        ...n,
        event_id: r
      },
      this
    ), r;
  }
  /**
   * @inheritDoc
   */
  captureMessage(t, n, r) {
    const a = r && r.event_id ? r.event_id : D();
    if (!this._client)
      return x.warn("No client configured on scope - will not capture message!"), a;
    const s = new Error(t);
    return this._client.captureMessage(
      t,
      n,
      {
        originalException: t,
        syntheticException: s,
        ...r,
        event_id: a
      },
      this
    ), a;
  }
  /**
   * @inheritDoc
   */
  captureEvent(t, n) {
    const r = n && n.event_id ? n.event_id : D();
    return this._client ? (this._client.captureEvent(t, { ...n, event_id: r }, this), r) : (x.warn("No client configured on scope - will not capture event!"), r);
  }
  /**
   * This will be called on every set call.
   */
  _notifyScopeListeners() {
    this._notifyingListeners || (this._notifyingListeners = !0, this._scopeListeners.forEach((t) => {
      t(this);
    }), this._notifyingListeners = !1);
  }
}
const E = V;
function ke() {
  return G("defaultCurrentScope", () => new E());
}
function Ie() {
  return G("defaultIsolationScope", () => new E());
}
class Ne {
  constructor(t, n) {
    let r;
    t ? r = t : r = new E();
    let a;
    n ? a = n : a = new E(), this._stack = [{ scope: r }], this._isolationScope = a;
  }
  /**
   * Fork a scope for the stack.
   */
  withScope(t) {
    const n = this._pushScope();
    let r;
    try {
      r = t(n);
    } catch (a) {
      throw this._popScope(), a;
    }
    return ye(r) ? r.then(
      (a) => (this._popScope(), a),
      (a) => {
        throw this._popScope(), a;
      }
    ) : (this._popScope(), r);
  }
  /**
   * Get the client of the stack.
   */
  getClient() {
    return this.getStackTop().client;
  }
  /**
   * Returns the scope of the top stack.
   */
  getScope() {
    return this.getStackTop().scope;
  }
  /**
   * Get the isolation scope for the stack.
   */
  getIsolationScope() {
    return this._isolationScope;
  }
  /**
   * Returns the topmost scope layer in the order domain > local > process.
   */
  getStackTop() {
    return this._stack[this._stack.length - 1];
  }
  /**
   * Push a scope to the stack.
   */
  _pushScope() {
    const t = this.getScope().clone();
    return this._stack.push({
      client: this.getClient(),
      scope: t
    }), t;
  }
  /**
   * Pop a scope from the stack.
   */
  _popScope() {
    return this._stack.length <= 1 ? !1 : !!this._stack.pop();
  }
}
function C() {
  const e = le(), t = j(e);
  return t.stack = t.stack || new Ne(ke(), Ie());
}
function xe(e) {
  return C().withScope(e);
}
function Le(e, t) {
  const n = C();
  return n.withScope(() => (n.getStackTop().scope = e, t(e)));
}
function Z(e) {
  return C().withScope(() => e(C().getIsolationScope()));
}
function Ae() {
  return {
    withIsolationScope: Z,
    withScope: xe,
    withSetScope: Le,
    withSetIsolationScope: (e, t) => Z(t),
    getCurrentScope: () => C().getScope(),
    getIsolationScope: () => C().getIsolationScope()
  };
}
function Pe(e) {
  const t = j(e);
  return t.acs ? t.acs : Ae();
}
function Re() {
  const e = le();
  return Pe(e).getCurrentScope();
}
function Oe(e) {
  if (e)
    return Me(e) ? { captureContext: e } : Ue(e) ? {
      captureContext: e
    } : e;
}
function Me(e) {
  return e instanceof E || typeof e == "function";
}
const $e = [
  "user",
  "level",
  "extra",
  "contexts",
  "tags",
  "fingerprint",
  "requestSession",
  "propagationContext"
];
function Ue(e) {
  return Object.keys(e).some((t) => $e.includes(t));
}
function Be(e, t) {
  return Re().captureException(e, Oe(t));
}
let N;
const Fe = new Uint8Array(16);
function Ye() {
  if (!N && (N = typeof crypto < "u" && crypto.getRandomValues && crypto.getRandomValues.bind(crypto), !N))
    throw new Error("crypto.getRandomValues() not supported. See https://github.com/uuidjs/uuid#getrandomvalues-not-supported");
  return N(Fe);
}
const l = [];
for (let e = 0; e < 256; ++e)
  l.push((e + 256).toString(16).slice(1));
function We(e, t = 0) {
  return (l[e[t + 0]] + l[e[t + 1]] + l[e[t + 2]] + l[e[t + 3]] + "-" + l[e[t + 4]] + l[e[t + 5]] + "-" + l[e[t + 6]] + l[e[t + 7]] + "-" + l[e[t + 8]] + l[e[t + 9]] + "-" + l[e[t + 10]] + l[e[t + 11]] + l[e[t + 12]] + l[e[t + 13]] + l[e[t + 14]] + l[e[t + 15]]).toLowerCase();
}
const Ge = typeof crypto < "u" && crypto.randomUUID && crypto.randomUUID.bind(crypto), J = {
  randomUUID: Ge
};
function je(e, t, n) {
  if (J.randomUUID && !t && !e)
    return J.randomUUID();
  e = e || {};
  const r = e.random || (e.rng || Ye)();
  if (r[6] = r[6] & 15 | 64, r[8] = r[8] & 63 | 128, t) {
    n = n || 0;
    for (let a = 0; a < 16; ++a)
      t[n + a] = r[a];
    return t;
  }
  return We(r);
}
const Ve = (e, t = 2) => `${(e * 100).toFixed(t)}%`, g = (e, t = 2, n = "standard") => {
  try {
    return new Intl.NumberFormat(["en-US"], {
      style: "currency",
      currency: "usd",
      currencyDisplay: "symbol",
      minimumFractionDigits: t,
      maximumFractionDigits: t,
      notation: n
    }).format(Number(e));
  } catch {
    return `$${Number(e)}`;
  }
}, gt = (e, t = "standard") => {
  try {
    return new Intl.NumberFormat("en", {
      notation: t
    }).format(+e);
  } catch {
    return e.toString();
  }
}, yt = (e) => {
  const n = ("" + e).replace(/\D/g, "").match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  return n ? [n[1] ? "+1 " : "", "(", n[2], ") ", n[3], "-", n[4]].join("") : null;
}, vt = (e) => {
  const n = ("" + e).replace(/\D/g, "").match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (n)
    return [n[1] ? "+1 " : "", "••• ••• ", n[4]].join("");
}, wt = (e, t = 1) => {
  const n = e.indexOf("@");
  if (n <= 0 || t < 0)
    return e;
  const r = e.slice(0, Math.min(n, t)), a = "*".repeat(
    Math.max(n - t, 0)
  ), s = e.slice(n);
  return `${r}${a}${s}`;
}, bt = (e) => e.toISOString().split("T")[0], St = (e) => {
  const [t, n, r] = e.split("-");
  return new Date(Date.UTC(parseInt(t), parseInt(n) - 1, parseInt(r)));
}, Dt = (e, t) => {
  const n = new Date(e), r = /* @__PURE__ */ new Date();
  return n.setUTCHours(0, 0, 0, 0), r.setUTCHours(0, 0, 0, 0), (r.getTime() - n.getTime()) / (1e3 * 3600 * 24) <= t;
}, Et = (e) => {
  const t = new Date(e), r = (/* @__PURE__ */ new Date()).getFullYear(), a = new Date(r, 0, 1);
  return t >= a;
}, Ct = (e, t) => {
  const n = /* @__PURE__ */ new Date(), r = new Date(e);
  return n.getFullYear() - r.getFullYear() <= t;
}, Tt = (e, {
  days: t = 0,
  months: n = 0,
  years: r = 0,
  maintainOffset: a = !0
}) => {
  const s = e.getTimezoneOffset(), o = new Date(e);
  t !== 0 && o.setDate(o.getDate() + t), n !== 0 && o.setMonth(o.getMonth() + n), r !== 0 && o.setFullYear(o.getFullYear() + r);
  const i = o.getTimezoneOffset(), c = s - i;
  return a && o.setMinutes(o.getMinutes() + c), o;
}, u = {
  local: {
    get: (e) => {
      try {
        return window.localStorage.getItem(e);
      } catch {
      }
    },
    set: (e, t) => {
      try {
        return window.localStorage.setItem(e, t.toString());
      } catch {
      }
    },
    remove: (e) => {
      try {
        return window.localStorage.removeItem(e);
      } catch {
      }
    },
    clear: () => {
      try {
        return window.localStorage.clear();
      } catch {
      }
    }
  },
  session: {
    get: (e) => {
      try {
        return window.sessionStorage.getItem(e);
      } catch {
        return;
      }
    },
    set: (e, t) => {
      try {
        return window.sessionStorage.setItem(e, t.toString());
      } catch {
      }
    },
    remove: (e) => {
      try {
        return window.sessionStorage.removeItem(e);
      } catch {
      }
    },
    clear: () => {
      try {
        return window.sessionStorage.clear();
      } catch {
      }
    }
  }
}, kt = () => {
  const e = u.session.get("session_id"), t = e ?? je();
  return e || u.session.set("session_id", t), t;
}, It = (e) => {
  const t = e.split("-");
  return `${t[1]}/${t[2]}/${t[0]}`;
}, me = (e) => ({
  month: (e == null ? void 0 : e.month) ?? "long",
  day: e && "day" in e ? e.day : "numeric",
  year: e && "year" in e ? e.year : "numeric",
  hour: e == null ? void 0 : e.hour,
  minute: e == null ? void 0 : e.minute,
  hour12: e != null && e.hour ? !0 : void 0,
  weekday: e == null ? void 0 : e.weekday,
  timeZone: e != null && e.local ? void 0 : "UTC"
}), Nt = (e) => new Intl.DateTimeFormat("en-US", {
  hour: "numeric",
  minute: "numeric",
  hour12: !0,
  timeZoneName: "short"
}).format(new Date(e)), I = (e, t) => new Intl.DateTimeFormat(
  "en-US",
  me(t)
).format(new Date(e)), He = (e, t, n) => {
  const r = new Intl.DateTimeFormat(
    "en-US",
    me(n)
  );
  return r.formatRange ? r.formatRange(e, t).replace(/ – /, "–") : e.getTime() === t.getTime() ? I(e, n) : `${I(e, n)} – ${I(t, n)}`;
}, xt = (e) => e % 10 == 1 && e % 100 != 11 ? "st" : e % 10 == 2 && e % 100 != 12 ? "nd" : e % 10 == 3 && e % 100 != 13 ? "rd" : "th", qe = (e) => {
  const t = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  e = e.replace(t, (r, a, s, o) => a + a + s + s + o + o);
  const n = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(e);
  return n ? {
    r: parseInt(n[1], 16),
    g: parseInt(n[2], 16),
    b: parseInt(n[3], 16)
  } : {
    r: parseInt("#000", 16),
    g: parseInt("#000", 16),
    b: parseInt("#000", 16)
  };
}, Lt = (e, t) => {
  const n = qe(e);
  return `rgba(${n.r}, ${n.g}, ${n.b}, ${t})`;
}, At = (e) => {
  var n;
  const t = (n = e.offers) == null ? void 0 : n.map(
    (r) => {
      var a;
      return r.chunks ? (a = r.chunks) == null ? void 0 : a.map((s) => {
        var o;
        return s && Number((o = s.amount_range) == null ? void 0 : o[1]);
      }) : 0;
    }
  ).flat();
  return t ? Math.max(...t) : 0;
}, Pt = (e) => {
  var n;
  const t = (n = e.offers) == null ? void 0 : n.map(
    (r) => {
      var a;
      return r.chunks ? (a = r.chunks) == null ? void 0 : a.map((s) => {
        var o;
        return s && Number((o = s.amount_range) == null ? void 0 : o[0]);
      }) : 0;
    }
  ).flat();
  return t ? Math.min(...t) : 0;
}, Rt = (e, t, n) => {
  var s;
  const r = e.offers, a = r == null ? void 0 : r.find((o) => o.label === n);
  return (s = a == null ? void 0 : a.chunks) == null ? void 0 : s.find((o) => {
    if (o.amount_range)
      return t > +o.amount_range[0] && t <= +o.amount_range[1];
  });
}, Ot = (e) => {
  const t = _e();
  return t.setDate(t.getDate() + e), t;
}, _e = () => {
  const e = /* @__PURE__ */ new Date();
  return new Date(
    e.getTime() - e.getTimezoneOffset() * 60 * 1e3
  );
}, Mt = () => {
  try {
    return window.self !== window.top;
  } catch {
    return !0;
  }
}, $t = (e) => e.startsWith("http://") || e.startsWith("https://") ? e : `https://${e}`, Ut = (e) => {
  try {
    return new URL(e).pathname;
  } catch {
    return e.split("?")[0];
  }
}, Bt = (e) => e.map((r) => {
  const [a, s, o] = r.split("-");
  return new Date(Date.UTC(Number(a), Number(s) - 1, Number(o)));
}).reduce((r, a) => a < r ? a : r), Ft = (e) => e.offers.map((t) => t.label).sort((t, n) => Number(t.slice(0, -5)) - Number(n.slice(0, -5))), Yt = (e, t) => e.offers.map((n) => {
  const r = n.chunks.flatMap((a) => a.amount_range.flat()).sort((a, s) => +a - +s);
  if (t > +r[0] && t <= +r[r.length - 1])
    return n.label;
}).filter((n) => n !== void 0), Wt = (e, t) => `${e}${t > 1 ? "s" : ""}`, Ke = [
  "zero",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine"
], Gt = (e, t = !1) => {
  if (e >= 0 && e <= 9 && Number.isInteger(e)) {
    const n = Ke[e];
    return t ? n.charAt(0).toUpperCase() + n.slice(1) : n;
  }
  return e;
}, jt = (e) => {
  if (!e)
    return "Unknown";
  const t = I(e.sales_period_start, {
    month: "short"
  });
  return e.schedule === "daily" ? t : He(
    new Date(e.sales_period_start),
    new Date(e.sales_period_end)
  ).split("–").join(" – ");
}, Vt = (e) => {
  const t = _e(), n = new Date(e), r = Math.abs(t.getTime() - n.getTime());
  return Math.floor(r / (1e3 * 3600 * 24));
}, Ht = (e) => e * 1.1 - e * 0.2 * Math.random(), qt = (e) => {
  var n;
  const t = (n = e.offers) == null ? void 0 : n.map((r) => r.chunks).flat();
  return t == null ? void 0 : t.reduce((r, a) => {
    var s, o;
    return Number((s = a == null ? void 0 : a.amount_range) == null ? void 0 : s[1]) > Number((o = r == null ? void 0 : r.amount_range) == null ? void 0 : o[1]) ? a : r;
  }, t[0]);
}, Kt = (e, t) => {
  const n = e.toString(), r = t.toString();
  return n.startsWith(r);
}, zt = (e) => async () => {
  var n;
  const t = window.open("https://app.parafin.com/loading", "_blank");
  try {
    const { data: r } = await e();
    r && t && ("results" in r && ((n = r.results) != null && n[0]) ? t.location = r.results[0].url : "url" in r && r.url && (t.location = r.url));
  } catch (r) {
    t == null || t.close(), Be(r);
  }
}, ze = (e) => e && /iPhone/.test(e) ? "iPhone" : e && /Android/.test(e) ? "Android" : "other", Q = (e) => e[0].toUpperCase() + e.slice(1), Xt = (e) => {
  switch (e) {
    case "cash_advance":
      return "merchant_cash_advance";
    case "card":
    case "merchant_bank":
      return "merchant_bank";
    case "flex_loan":
    case "term_loan":
    case "bnpl":
      return "loan";
    default:
      throw new Error("Unhandled product type: " + e);
  }
}, Xe = (e) => Math.round(+e * 100), Zt = (e, t) => `${Xe(e)}% of ${Je(t)}`, Ze = (e) => e === "biweekly" ? "every two weeks" : e, Jt = (e, t) => `${g(e)} ${Ze(t)}`, Qt = (e) => {
  const t = [];
  return e.forEach((n) => {
    const r = "date" in n ? n.date : n.timestamp, a = new Date(r).getFullYear(), s = t.findIndex(
      (o) => o.year === a
    );
    s === -1 ? t.push({ year: a, activities: [n] }) : t[s].activities.push(n);
  }), t;
}, en = (e) => {
  const t = [];
  return e.forEach((n) => {
    const r = new Date(n.effective_at), a = new Intl.DateTimeFormat("en-US", {
      month: "long",
      year: "numeric",
      timeZone: "UTC"
    }).format(r), s = t.findIndex(
      (o) => o.monthYear === a
    );
    s === -1 ? t.push({ monthYear: a, activities: [n] }) : t[s].activities.push(n);
  }), t;
}, tn = (e) => {
  switch (e) {
    case "cash_advance":
    case "flex_loan":
    case "term_loan":
    case "bnpl":
      return !0;
    case "card":
    case "merchant_bank":
      return !1;
  }
}, Je = (e) => Y({ slug: e.slug, desiredSlug: "tiktok" }) || Y({ slug: e.slug, desiredSlug: "amazon" }) ? "gross sales" : "sales", Y = ({
  slug: e,
  desiredSlug: t,
  allowSandbox: n = !0
}) => {
  const r = e === t, a = e === `sandbox-${t}`;
  return r || n && a;
}, Qe = (e) => {
  const t = e.replaceAll("_", " ");
  return t.charAt(0).toUpperCase() + t.slice(1).toLowerCase();
}, nn = (e) => e.replaceAll(" ", "_").toLowerCase(), rn = (e, t) => {
  var r;
  const n = (r = e.offers) == null ? void 0 : r.flatMap((a) => a.chunks);
  return n == null ? void 0 : n.find((a) => a && a.id === t);
}, an = (e) => {
  switch (e) {
    case "flex_loan":
    case "term_loan":
    case "bnpl":
      return "loan";
    case "merchant_cash_advance":
      return "advance";
    default:
      throw new Error("Unhandled product type: " + e);
  }
}, sn = (e, t) => {
  var n, r;
  return (r = (n = e.offers) == null ? void 0 : n.find(
    (a) => {
      var s;
      return (s = a.chunks) == null ? void 0 : s.find((o) => o.id === t);
    }
  )) == null ? void 0 : r.label;
}, on = (e) => `${e == null ? void 0 : e.first_name} ${e == null ? void 0 : e.last_name}`, cn = (e) => `${e.slice(0, -5)} months`, un = (e) => e === "available" || e === "pending", dn = (e) => {
  const t = e.active_products ?? [], n = e.exposed_products ?? [];
  return t.includes("flex_loan") ? "flex_loan" : t.includes("term_loan") ? "term_loan" : t.includes("cash_advance") || n.includes("cash_advance") && !n.includes("flex_loan") ? "cash_advance" : n.includes("term_loan") ? "term_loan" : "flex_loan";
}, ln = (e) => e.split("_")[1], mn = (e, t) => Y({ slug: t, desiredSlug: "amazon" }) ? "amazon_mca" : e, _n = "otp_phone_number", pn = (e, t) => !e || e > (() => {
  switch (t) {
    case "daily":
      return 1;
    case "weekly":
      return 7;
    case "biweekly":
      return 14;
    case "monthly":
      return 30;
  }
})() ? "Overdue" : `${e} ${e > 1 ? "days" : "day"} past due`, hn = (e) => e != null && e.past_due_amount ? Number(e.past_due_amount) > 0 : !1, P = {
  sm: 480,
  md: 768,
  lg: 1080,
  xl: 1440
}, fn = {
  llc: "LLC",
  corporation: "Corporation",
  sole_proprietorship: "Sole proprietorship",
  partnership: "Partnership",
  cooperative: "Cooperative",
  joint_venture: "Joint venture",
  business_trust: "Business trust",
  other: "Other"
}, gn = () => {
  const [e, t] = p(0);
  return h(() => {
    const n = () => {
      t(window.scrollY);
    };
    return window.addEventListener("scroll", n), () => window.removeEventListener("scroll", n);
  }, []), { scrollPosition: e };
}, yn = (e) => {
  const [t, n] = p("unknown"), r = oe(() => {
    var s;
    const a = {
      publicKey: e,
      userConsent: !0,
      context: "profile"
    };
    (s = window.devicer) == null || s.run(a, function(o) {
      n(o.sessionId);
    });
  }, []);
  return h(() => {
    const a = document.createElement("script");
    a.src = "https://js.dvnfo.com/devicer.min.js", a.async = !1, a.onload = () => r(), document.body.appendChild(a);
  }, [r]), t;
}, vn = () => {
  const [e, t] = p(!1);
  return h(() => {
    function n() {
      t(!0);
    }
    return window.addEventListener("resize", n), () => {
      window.removeEventListener("resize", n);
    };
  }, []), h(() => {
    if (e) {
      const n = setTimeout(() => t(!1), 500);
      return () => clearTimeout(n);
    }
  }, [e]), e;
};
var et = /* @__PURE__ */ ((e) => (e[e.SMALL = 0] = "SMALL", e[e.MEDIUM = 1] = "MEDIUM", e[e.LARGE = 2] = "LARGE", e[e.EXTRA_LARGE = 3] = "EXTRA_LARGE", e))(et || {});
const wn = (e) => {
  const [t, n] = p(window.innerWidth);
  return h(() => {
    const r = () => n(window.innerWidth);
    return window.addEventListener("resize", r), () => window.removeEventListener("resize", r);
  }, []), e ? t : t <= P.sm ? 0 : t <= P.md ? 1 : t <= P.lg ? 2 : 3;
}, bn = (e, t) => {
  h(() => {
    const n = (a) => {
      e.current && !e.current.contains(a.target) && t(a);
    }, r = (a) => {
      a.key === "Escape" && t(a);
    };
    return document.addEventListener("mousedown", n), document.addEventListener("keydown", r), () => {
      document.removeEventListener("mousedown", n), document.removeEventListener("keydown", r);
    };
  }, [e, t]);
};
function Sn(e, t) {
  const n = ie();
  h(() => {
    n.current = e;
  }, [e]), h(() => {
    function r() {
      n.current !== void 0 && n.current();
    }
    if (t !== null) {
      const a = setInterval(r, t);
      return () => clearInterval(a);
    }
  }, [t]);
}
function Dn(e, t = 1e3) {
  const n = ie();
  return h(() => () => {
    n.current && clearTimeout(n.current);
  }, []), (...a) => {
    const s = setTimeout(() => {
      e(...a);
    }, t);
    clearTimeout(n.current), n.current = s;
  };
}
const En = (e) => {
  const [t] = p(e), [n, r] = p(null);
  h(() => {
    const s = n ? setTimeout(() => r(null), n.duration) : null;
    return () => {
      s && clearTimeout(s);
    };
  }, [n]);
  const a = oe((s, o) => {
    r({ value: s, duration: o });
  }, []);
  return [(n == null ? void 0 : n.value) ?? t, a];
}, Cn = () => {
  const [e, t] = p();
  return h(() => {
    typeof navigator < "u" && t(ze(navigator.userAgent));
  }, []), { deviceType: e };
};
class T extends Error {
}
T.prototype.name = "InvalidTokenError";
function tt(e) {
  return decodeURIComponent(atob(e).replace(/(.)/g, (t, n) => {
    let r = n.charCodeAt(0).toString(16).toUpperCase();
    return r.length < 2 && (r = "0" + r), "%" + r;
  }));
}
function nt(e) {
  let t = e.replace(/-/g, "+").replace(/_/g, "/");
  switch (t.length % 4) {
    case 0:
      break;
    case 2:
      t += "==";
      break;
    case 3:
      t += "=";
      break;
    default:
      throw new Error("base64 string is not of the correct length");
  }
  try {
    return tt(t);
  } catch {
    return atob(t);
  }
}
function rt(e, t) {
  if (typeof e != "string")
    throw new T("Invalid token specified: must be a string");
  t || (t = {});
  const n = t.header === !0 ? 0 : 1, r = e.split(".")[n];
  if (typeof r != "string")
    throw new T(`Invalid token specified: missing part #${n + 1}`);
  let a;
  try {
    a = nt(r);
  } catch (s) {
    throw new T(`Invalid token specified: invalid base64 for part #${n + 1} (${s.message})`);
  }
  try {
    return JSON.parse(a);
  } catch (s) {
    throw new T(`Invalid token specified: invalid json for part #${n + 1} (${s.message})`);
  }
}
const ee = 36e6, L = "parafin_token", A = "parafin_token_expiration", Tn = (e, t) => {
  const n = new URLSearchParams(e.split("?")[1]).get("token") ?? void 0, r = u.local.get(L), a = n ?? r, s = pe(n, r), o = s - Date.now(), [i, c] = p(!0), [m, f] = p(!1), [y, v] = p(void 0);
  h(() => {
    const S = setTimeout(_, o);
    return document.addEventListener("visibilitychange", b), a && d(s) ? (a !== r && (t == null || t()), u.local.set(L, a), u.local.set(A, s), v(`${W(a) ? "Token" : "Bearer"} ${a}`)) : _(), c(!1), () => {
      clearTimeout(S), document.removeEventListener("visibilitychange", b);
    };
  }, []);
  const d = (S) => Date.now() < S, b = () => {
    d(s) || _();
  }, _ = () => {
    t == null || t(), u.local.remove(L), u.local.remove(A), f(!0);
  };
  return { isLoading: i, isExpired: m, data: y };
}, kn = (e) => {
  u.local.set(L, e), u.local.set(A, pe(e));
}, pe = (e, t) => {
  if (e)
    return W(e) ? Date.now() + ee : te(e);
  if (t)
    if (W(t)) {
      const n = u.local.get(A);
      return n ? +n : Date.now() + ee;
    } else
      return te(t);
  else
    return 0;
}, te = (e) => {
  try {
    const { exp: t } = rt(e);
    return t * 1e3;
  } catch {
    return 0;
  }
}, W = (e) => {
  const t = e.replaceAll("-", "");
  return t.length === 32 && Array.from(t).every(
    (n) => "0123456789abcdef".includes(n.toLowerCase())
  );
}, at = [
  {
    path: "/v1/auth/redeem_token",
    method: "post",
    name: "Redeem Auth Token"
  },
  {
    path: "/v1/bank_accounts",
    method: "get",
    name: "List Bank Accounts"
  },
  {
    path: "/v1/bank_accounts",
    method: "post",
    name: "Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/batch_create",
    method: "post",
    name: "Batch Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/{id}",
    method: "get",
    name: "Get Bank Account"
  },
  {
    path: "/v1/sandbox/bank_account/{id}/verify",
    method: "post",
    name: "Verify Bank Account (Sandbox)"
  },
  {
    path: "/v1/businesses",
    method: "get",
    name: "List Businesses"
  },
  {
    path: "/v1/businesses",
    method: "post",
    name: "Create Business"
  },
  {
    path: "/v1/businesses/batch_create",
    method: "post",
    name: "Batch Create Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "get",
    name: "Get Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "patch",
    name: "Update Business"
  },
  {
    path: "/v1/capital_product_applications",
    method: "get",
    name: "List Capital Product Applications"
  },
  {
    path: "/v1/capital_product_applications/{id}",
    method: "get",
    name: "Get Capital Product Application"
  },
  {
    path: "/v1/sandbox/capital_product_application/{id}/approve",
    method: "post",
    name: "Approve Capital Product Application in Manual Review (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_application/{id}/deny",
    method: "post",
    name: "Deny Capital Product Application in Manual Review (Sandbox)"
  },
  {
    path: "/v1/capital_product_offers/{id}",
    method: "get",
    name: "Get Capital Product Offer"
  },
  {
    path: "/v1/capital_product_offers",
    method: "get",
    name: "List Capital Product Offers"
  },
  {
    path: "/v1/capital_product_payments",
    method: "get",
    name: "List Capital Product Payments"
  },
  {
    path: "/v1/capital_products/{id}/loan_minimum_repayment",
    method: "get",
    name: "Get Loan Minimum Repayment Details For A Capital Product"
  },
  {
    path: "/v1/capital_products/{id}",
    method: "get",
    name: "Get Capital Product"
  },
  {
    path: "/v1/capital_products",
    method: "get",
    name: "List Capital Products"
  },
  {
    path: "/v1/daily_sales_records",
    method: "get",
    name: "List Daily Sales Records"
  },
  {
    path: "/v1/daily_sales_records",
    method: "post",
    name: "Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/batch_create",
    method: "post",
    name: "Batch Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/bulk_ingest",
    method: "post",
    name: "Bulk Ingest Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "get",
    name: "Get Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "patch",
    name: "Update Daily Sale Record"
  },
  {
    path: "/v1/net_settlement_payment_requests/{id}",
    method: "get",
    name: "Get Net Settlement Payment Request"
  },
  {
    path: "/v1/net_settlement_payment_requests",
    method: "get",
    name: "List Net Settlement Payment Requests"
  },
  {
    path: "/v1/net_settlement_payment_requests/{id}/fulfill",
    method: "patch",
    name: "Fulfill Net Settlement Payment Request"
  },
  {
    path: "/v1/net_settlement_payment_requests/initiate",
    method: "patch",
    name: "Initiate Net Settlement Payment Request"
  },
  {
    path: "/v1/sandbox/net_settlement_payment_requests",
    method: "post",
    name: "Create Net Settlement Payment Request"
  },
  {
    path: "/v1/orders/{id}",
    method: "get",
    name: "Get Order"
  },
  {
    path: "/v1/orders",
    method: "get",
    name: "List Orders"
  },
  {
    path: "/v1/orders",
    method: "post",
    name: "Create Order"
  },
  {
    path: "/v1/orders/{id}/cancel",
    method: "post",
    name: "Cancel Order"
  },
  {
    path: "/v1/orders/{id}/finalize",
    method: "post",
    name: "Finalize Order"
  },
  {
    path: "/v1/persons",
    method: "get",
    name: "List Persons"
  },
  {
    path: "/v1/persons",
    method: "post",
    name: "Create Person"
  },
  {
    path: "/v1/persons/batch_create",
    method: "post",
    name: "Batch Create Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "get",
    name: "Get Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "patch",
    name: "Update Person"
  },
  {
    path: "/v1/person_business_relationships",
    method: "get",
    name: "List Person Business Relationships"
  },
  {
    path: "/v1/person_business_relationships",
    method: "post",
    name: "Create Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/batch_create",
    method: "post",
    name: "Batch Create Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/{id}",
    method: "get",
    name: "Get Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/change_representative",
    method: "post",
    name: "Change Representative"
  },
  {
    path: "/v1/refunds",
    method: "get",
    name: "List Refunds"
  },
  {
    path: "/v1/refunds",
    method: "post",
    name: "Create Refund"
  },
  {
    path: "/v1/refunds/{id}",
    method: "get",
    name: "Get Refund"
  },
  {
    path: "/v1/sandbox/trigger_webhook",
    method: "post",
    name: "Trigger Webhook"
  },
  {
    path: "/v1/sandbox/fund_capital_product",
    method: "post",
    name: "Fund Capital Product"
  },
  {
    path: "/v1/sandbox/capital_product_offer",
    method: "post",
    name: "[deprecated] Generate Capital Product Offer"
  },
  {
    path: "/v1/sandbox/cash_account/balance",
    method: "post",
    name: "Add Cash Account Balance"
  },
  {
    path: "/v1/sandbox/capital_product_payments",
    method: "post",
    name: "Create Capital Product Payment (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_offers",
    method: "post",
    name: "Create Capital Product Offer (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_offers/{id}/close",
    method: "post",
    name: "Close Capital Product Offer (Sandbox)"
  },
  {
    path: "/v1/sandbox/generate_event/capital_product_offer/created",
    method: "post",
    name: "[Experimental] Capital Product Offer Created (Sandbox)"
  },
  {
    path: "/v1/sandbox/generate_event/capital_product_offer/closed",
    method: "post",
    name: "[Experimental] Capital Product Offer Closed (Sandbox)"
  },
  {
    path: "/v1/webhook_endpoints/{id}",
    method: "get",
    name: "Get Webhook Endpoint"
  },
  {
    path: "/v1/webhook_endpoints/{id}",
    method: "patch",
    name: "Update Webhook Endpoint"
  },
  {
    path: "/v1/webhook_endpoints",
    method: "get",
    name: "List Webhook Endpoints"
  },
  {
    path: "/v1/webhook_endpoints",
    method: "post",
    name: "Create Webhook Endpoint"
  },
  {
    path: "/v1/payments",
    method: "get",
    name: "List Payments "
  },
  {
    path: "/v1/payments",
    method: "post",
    name: "Create Payment"
  },
  {
    path: "/v1/payments/{id}",
    method: "get",
    name: "Get Payment"
  },
  {
    path: "/v1/payments/batch_create",
    method: "post",
    name: "Batch Create Payment"
  },
  {
    path: "/v1/payment_processor_entity_business_links",
    method: "get",
    name: "List Payment Processor Entity Business Links"
  },
  {
    path: "/v1/payment_processor_entity_business_links",
    method: "post",
    name: "Create Payment Processor Entity Business Link"
  },
  {
    path: "/v1/payment_processor_entity_business_links/batch_create",
    method: "post",
    name: "Batch Create Payment Processor Entity Business Link"
  }
], ne = (e, t) => {
  const n = Date.now(), r = new Date(n - 1e3 * 60 * 60 * 24 * e);
  return [t < r ? r : t, /* @__PURE__ */ new Date()];
}, R = (e, t) => {
  const n = /* @__PURE__ */ new Date(), r = new Date(
    n.getFullYear(),
    n.getMonth() - e,
    n.getDate()
  );
  return [t < r ? r : t, n];
}, In = (e, t) => {
  switch (e) {
    case "Today":
      return ne(0, t);
    case "All time":
      return [new Date(t), /* @__PURE__ */ new Date()];
    case "Past week":
      return ne(7, t);
    case "1 month":
      return R(1, t);
    case "3 months":
      return R(3, t);
    case "1 year":
      return R(12, t);
    case "Custom":
      return [new Date(t), /* @__PURE__ */ new Date()];
  }
}, Nn = (e) => new Date(e).toLocaleString("default", { month: "short" }), xn = (e, t, n) => new Date(e).toLocaleString("default", {
  month: t || "long",
  year: "numeric",
  ...n && { day: "2-digit" }
}), Ln = (e) => {
  const t = e.split("-");
  return new Date(Number(t[0]), Number(t[1]) - 1, 1).toLocaleDateString("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
}, An = (e) => {
  const t = e.split("-");
  return new Date(Number(t[0]), Number(t[1]), 0).toLocaleDateString("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  });
}, Pn = (e = "", t = "") => {
  const n = at.find((r) => r.path === t && r.method.toUpperCase() === e.toUpperCase());
  return n ? n.name : "Unknown API";
}, Rn = (e) => e >= 200 && e < 400 ? "accent200" : e >= 400 && e < 500 ? "accent400" : e >= 500 ? "accent500" : "mono400", On = ["GET", "POST", "PATCH"], Mn = ["2XX", "4XX", "5XX"], $n = [
  "Has failed attempt",
  "No failed attempt"
], Un = (e) => {
  switch (e) {
    case "normal":
      return "Push";
    case "sync":
      return "Pull";
  }
}, Bn = (e, t) => {
  if (e) {
    const r = new Date(e), a = {
      hour: "numeric",
      minute: "2-digit"
    }, s = new Intl.DateTimeFormat("en-US", a).format(r), o = (/* @__PURE__ */ new Date()).getDate() - r.getDate(), i = o == 0 ? "today" : o == 1 ? "yesterday" : "two days ago";
    var n = (/* @__PURE__ */ new Date()).toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2];
    return `Last ${t} ${i} at ${s} ${n}.
    `;
  }
  return "Error: Could not determine generated date.";
}, st = (e) => {
  const t = Math.floor(e / 1e3), n = Math.floor(t / 60), r = Math.floor(n / 60), a = n % 60, s = t % 60;
  return ot(r, a, s);
}, ot = (e, t, n) => e > 0 ? `${e}h ${t}m ${n}s` : t > 0 ? `${t}m ${n}s` : n > 0 ? `${n}s` : "", Fn = (e, t, n, r) => {
  if (e)
    return r;
  const a = st(n), s = Math.ceil(n / 6e4);
  s == t ? t = Math.ceil(t * 1.25) : s > t && (t += s * 2);
  const o = t - s;
  return o < 5 ? `Elapsed Time: ${a}. A few minutes remaining.` : o < 60 ? `Elapsed Time: ${a}. About ${o} minutes remaining.` : o <= 90 ? `Elapsed Time: ${a}. About 1 hour remaining.` : `Elapsed Time: ${a}. Several hours remaining.`;
}, Yn = (e) => e.length > 48 ? "..." + e.slice(-48) : e, Wn = (e) => e === "Error" ? "red" : e === "Warning" ? "yellow" : "gray", Gn = (e) => e.replace(/\./g, "@"), jn = (e) => e.replace(/@/g, "."), Vn = (e) => {
  const t = e.find(
    (n) => n.merchant_bank_account_type === "checking"
  );
  if (!t)
    throw new Error("User does not have a checking account");
  return t;
}, Hn = (e, t = { prioritizePending: !0 }) => e.sort((n, r) => {
  if (t.prioritizePending) {
    if (n.state === "pending" && r.state !== "pending")
      return -1;
    if (r.state === "pending" && n.state !== "pending")
      return 1;
  }
  return n.effective_at > r.effective_at ? -1 : r.effective_at > n.effective_at || n.id < r.id ? 1 : -1;
}), it = (e) => (e.nick_name ? `${e.nick_name} · ` : "") + `${e.bank_name} •••• ${e.account_number_last_four}`, qn = (e) => "name" in e ? e.name : e.account_nick_name ?? e.account_name, Kn = (e) => "name" in e ? it(e) : `${e.account_nick_name ? `${e.account_name} ` : ""}•••• ${e.account_number_last_four}`, zn = (e, t, n) => {
  switch (t) {
    case "rtp":
      return Math.round(e * n.instant_bank_transfer_rate * 100) / 100;
    case "same_day_ach":
      return Math.round(e * n.same_day_bank_transfer_rate * 100) / 100;
    case "wire":
      return n.wire_fee.amount;
    case "ach":
      return;
  }
}, ct = (e, t) => {
  switch (e) {
    case "single_ach_money_out_originated_limit":
      return `Exceeds limit of ${g(
        t.single_ach_money_out_originated_limit
      )} per bank transfer out`;
    case "single_wire_money_out_limit":
      return `Exceeds limit of ${g(
        t.single_wire_money_out_limit
      )} per wire out`;
    case "single_ach_money_in_originated_limit":
      return `Exceeds limit of ${g(
        t.single_ach_money_in_originated_limit
      )} per transfer`;
    case "daily_money_in_left":
      return `Exceeds remaining daily limit of ${g(
        t.daily_money_in_left
      )}`;
    case "daily_money_out_left":
      return `Exceeds remaining daily limit of ${g(
        t.daily_money_out_left
      )} for transfers out`;
    case "weekly_ach_money_in_originated_left":
      return `Exceeds remaining weekly limit of ${g(
        t.weekly_ach_money_in_originated_left
      )}`;
    case "minimum_transfer_amount":
      return `Minimum amount is ${g(
        t.minimum_transfer_amount
      )}`;
    case "single_rtp_money_out_limit":
      return `Exceeds limit of ${g(
        t.single_rtp_money_out_limit
      )} per instant transfer out`;
    default:
      throw new Error("Unexpected exceeded transfer limit: " + e);
  }
}, Xn = ({
  amount: e,
  limits: t,
  direction: n,
  availableBalance: r,
  fee: a,
  paymentType: s
}) => {
  if (n === "money_out") {
    if (e > r)
      return "Amount exceeds available balance";
    if (a !== void 0 && e + a > r)
      return "Amount and fee exceeds available balance";
  }
  const o = Object.keys(t).sort(
    (d, b) => t[d] - t[b]
  ), i = s === "rtp", c = s === "ach" || s === "same_day_ach", f = {
    minimum_transfer_amount: !0,
    single_ach_money_in_originated_limit: n === "money_in" && c,
    single_ach_money_out_originated_limit: n === "money_out" && c,
    single_rtp_money_out_limit: n === "money_out" && i,
    single_wire_money_out_limit: n === "money_out" && s === "wire",
    weekly_ach_money_in_originated_left: n === "money_in" && c,
    weekly_ach_money_in_originated_limit: !1,
    daily_money_in_left: n === "money_in",
    daily_money_in_limit: !1,
    daily_money_out_left: n === "money_out",
    daily_money_out_limit: !1
  }, v = o.filter(
    (d) => f[d]
  ).find((d) => d === "minimum_transfer_amount" ? e < t[d] : e > t[d]);
  return v ? ct(v, t) : !0;
}, Zn = (e, t) => {
  var r, a, s, o;
  const n = (r = e.card_transaction_details) == null ? void 0 : r.card_acceptor_details.card_acceptor_name;
  switch (e.transaction_type) {
    case "card_transaction":
      return n ? e.direction === "money_in" ? `${n} refund` : n : "Card transaction";
    case "external_bank_transfer":
      const i = e.external_account_transfer_details;
      return e.external_transfer_payment_type === "wire" ? "Wire transfer" : `Transfer ${e.direction === "money_in" ? "from" : "to"} ${i.external_account_nickname ?? i.external_account_name}`;
    case "recipient_transfer": {
      const c = e.external_transfer_payment_type === "wire" ? "Wire transfer" : "Bank transfer", m = e.recipient_transfer_details ? ` to ${e.recipient_transfer_details.recipient_nickname ?? ((a = e.recipient_transfer_details) == null ? void 0 : a.recipient_name)}` : "";
      return c + m;
    }
    case "received_transfer": {
      if (((s = e.received_transfer_details) == null ? void 0 : s.transfer_type) === "partner_payout")
        return `${t.name} payout`;
      const c = (o = e.received_transfer_details) == null ? void 0 : o.originator_name;
      return e.external_transfer_payment_type === "wire" ? c ? `Wire transfer ${e.direction === "money_in" ? "from" : "to"} ${c}` : "Wire transfer" : c || "Bank transfer";
    }
    case "repayment":
      return e.repayment_details.repayment_type ? "Automatic payment" : "Manual payment";
    case "card_dispute_credit":
      return n ? `${n} dispute credit` : "Dispute credit";
    case "transfer_return":
      return "Transfer return";
    case "transfer_reversal":
      return "Transfer reversal";
    default:
      throw new Error(
        `Unexpected transaction type: ${e.transaction_type}`
      );
  }
}, ut = (e) => e.original_transaction_details ? [
  {
    label: "Original transaction date",
    value: I(
      e.original_transaction_details.original_transaction_effective_at,
      { month: "short" }
    )
  },
  {
    label: "Original transaction ID",
    value: "#" + e.original_transaction_details.original_transaction_reference_id
  }
] : [], dt = (e) => {
  var t;
  switch (e.transaction_type) {
    case "card_transaction": {
      const a = e.card_transaction_details;
      if (!a)
        throw new Error(
          "Card transaction details are required for card transactions"
        );
      return [
        {
          label: "Card used",
          value: `${Q(
            a.card_details.card_type
          )} •••• ${a.card_details.card_last_four}`
        }
      ];
    }
    case "external_bank_transfer": {
      const a = e.external_account_transfer_details;
      return [
        {
          label: e.direction === "money_out" ? "To" : "From",
          value: `${a.external_account_bank_name} •••• ${a.external_account_account_number_last_four}`
        },
        ...re(e),
        ...O(e)
        // spend-audit: arrives by label/value
      ];
    }
    case "received_transfer":
      return O(e);
    case "recipient_transfer": {
      const a = e.recipient_transfer_details;
      if (!a)
        throw new Error(
          `Missing recipient details in activity. Expected recipient ${(t = e.destination_account) == null ? void 0 : t.account_id} transfer details.`
        );
      return [
        {
          label: "To",
          value: `${a.recipient_bank_name} •••• ${a.recipient_account_number_last_four}`
        },
        ...re(e),
        ...O(e)
      ];
    }
    case "repayment": {
      const a = e.repayment_details;
      if (!a)
        throw new Error("Missing repayment details in activity.");
      return [
        ...a.repayment_rate !== void 0 && a.payout_amount !== void 0 ? [
          {
            label: "Repayment rate",
            value: `${Ve(
              a.repayment_rate,
              0
            )} of ${g(a.payout_amount)}`
          }
        ] : []
      ];
    }
    case "transfer_return":
      const n = e.transfer_return_details;
      return n.reason ? [
        {
          label: "Reason",
          value: Q(n.reason.toLowerCase())
        }
      ] : [];
    case "transfer_reversal":
      const r = e.transfer_reversal_details;
      return [
        {
          label: "Reason",
          value: Qe(r.reason)
        }
      ];
    default:
      return [];
  }
}, Jn = (e) => {
  switch (e.transaction_type) {
    case "card_transaction":
      return "card";
    case "repayment":
      return "dollarCircle";
    case "external_bank_transfer":
      const t = e.external_account_transfer_details;
      return t != null && t.external_account_bank_logo && !t.external_account_bank_logo.includes("generic.svg") ? {
        src: t.external_account_bank_logo,
        alt: t.external_account_bank_name
      } : "bank";
    case "recipient_transfer":
    case "received_transfer":
    case "transfer_return":
    case "transfer_reversal":
      return "bank";
    case "card_dispute_credit":
      return "dollar";
    default:
      throw new Error(
        `Unexpected transaction type: ${e.transaction_type}`
      );
  }
}, Qn = (e, t) => ({
  lineItems: [
    ...dt(e),
    ...ut(e),
    {
      label: "Transaction ID",
      value: "#" + e.reference_id
    }
  ],
  action: t
}), lt = (e) => {
  var n;
  const t = (n = e.card_transaction_details) == null ? void 0 : n.dispute_state;
  if (t)
    switch (t) {
      case "user_submitted":
      case "parafin_pending":
      case "parafin_submitted":
      case "marqeta_chargeback_lost":
      case "marqeta_rejected":
        return "disputed";
      case "parafin_rejected":
      case "parafin_not_credited":
        return "dispute_lost";
      case "marqeta_chargeback_won":
      case "parafin_credited":
        return "dispute_won";
    }
  return e.state === "succeeded" ? void 0 : e.state;
}, er = (e) => {
  const t = e.amount + (e.fee_amount ?? 0);
  return {
    amount: e.direction === "money_in" ? t : -t,
    state: lt(e),
    date: e.effective_at
  };
}, O = (e) => e.memo ? [
  {
    label: "Memo",
    value: e.memo
  }
] : [], re = (e) => e.fee_amount ? [
  {
    label: "Transfer amount",
    value: g(e.amount)
  },
  {
    label: `${e.external_transfer_payment_type === "rtp" ? "Instant transfer" : "Same-day transfer"} fee`,
    value: g(e.fee_amount)
  }
] : [], tr = () => {
  const e = /* @__PURE__ */ new Date(), t = e.toLocaleString("en-US", { timeZone: "America/New_York" }), n = new Date(t), r = e.getUTCHours(), a = n.getHours();
  return r < a ? 24 - (a - r) : r - a;
}, M = {
  hours: 16,
  // 4PM ET
  minutes: 25
}, nr = () => {
  const e = /* @__PURE__ */ new Date(), t = new Date(
    e.toLocaleString("en-US", { timeZone: "America/New_York" })
  ), n = new Date(e.toLocaleString());
  if (t.getDate() !== n.getDate() && t > n)
    return !1;
  const r = t.getDay();
  if (r === 0 || r === 6)
    return !1;
  const a = t.getHours(), s = t.getMinutes();
  return !(a > M.hours || a === M.hours && s >= M.minutes);
}, mt = {
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  "District of Columbia": "DC",
  Florida: "FL",
  Georgia: "GA",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Puerto Rico": "PR",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
  // Canadian Provinces and Territories
  Alberta: "AB",
  "British Columbia": "BC",
  Manitoba: "MB",
  "New Brunswick": "NB",
  "Newfoundland and Labrador": "NL",
  "Northwest Territories": "NT",
  "Nova Scotia": "NS",
  Nunavut: "NU",
  Ontario: "ON",
  "Prince Edward Island": "PE",
  Quebec: "QC",
  Saskatchewan: "SK",
  Yukon: "YT"
}, ae = (e) => {
  const t = Object.entries(mt).reduce(
    (a, [s, o]) => (a[s.toLowerCase()] = o, a[o.toLowerCase()] = o, a),
    {}
  ), n = e.toLowerCase(), r = t[n];
  if (!r)
    throw new Error(`Invalid state abbreviation or name: ${e}`);
  return r;
}, _t = (e) => {
  var r, a, s, o, i, c, m, f, y, v, d, b, _;
  const t = ((r = e.business) == null ? void 0 : r.address) || e.address, n = (a = e.owner) == null ? void 0 : a.address;
  return {
    legal_business_name: ((s = e.business) == null ? void 0 : s.legalName) || e.legalBusinessName || e.businessName,
    dba_name: ((o = e.business) == null ? void 0 : o.dbaName) || e.dbaName || e.businessName,
    owner_first_name: ((i = e.owner) == null ? void 0 : i.firstName) || e.ownerFirstName,
    owner_last_name: ((c = e.owner) == null ? void 0 : c.lastName) || e.ownerLastName,
    account_managers: e.accountManagers,
    routing_number: ((m = e.bank) == null ? void 0 : m.routingNumber) || e.routingNumber,
    account_number_last_four: ((f = e.bank) == null ? void 0 : f.accountNumberLastFour) || e.accountNumberLastFour,
    email: ((y = e.owner) == null ? void 0 : y.email) || e.email,
    owner_phone: ((v = e.owner) == null ? void 0 : v.phoneNumber) || e.phoneNumber || e.ownerPhone,
    owner_dob: ((d = e.owner) == null ? void 0 : d.dateOfBirth) || e.ownerDob || null,
    business_established: ((b = e.business) == null ? void 0 : b.dateEstablished) || e.businessEstablished || null,
    incorporation_type: e.incorporationType || null,
    incorporation_state: e.incorporationState || null,
    postal_code: e.postalCode,
    bank_account_currency_code: ((_ = e.bank) == null ? void 0 : _.currencyCode) || e.bankAccountCurrencyCode,
    ...!!t && {
      address: {
        address_line1: t.addressLine1,
        address_line2: t.addressLine2,
        city: t.city,
        country: t.country,
        postal_code: t.postalCode,
        state: ae(t.state)
      }
    },
    ...!!n && {
      owner_address: {
        address_line1: n.addressLine1,
        address_line2: n.addressLine2,
        city: n.city,
        country: n.country,
        postal_code: n.postalCode,
        state: ae(n.state)
      }
    },
    opt_in_edit_source: "partner_datashare"
  };
}, rr = (e, t) => ({
  business_external_id: e,
  ..._t(t)
}), $ = "mfa_user_session_id", U = "mfa_user_session_expiration", B = "mfa_session_type", se = "mfa_has_had_valid_session", ar = () => {
  const [e, t] = p(!0), [n, r] = p(
    u.local.get($)
  ), [a, s] = p(
    Number(u.local.get(U)) || 0
  ), [o, i] = p(
    u.local.get(B)
  ), [c, m] = p(
    u.session.get(se) === "true"
  ), f = a - Date.now();
  h(() => {
    const _ = setTimeout(d, f);
    return document.addEventListener("visibilitychange", v), t(!1), () => {
      clearTimeout(_), document.removeEventListener("visibilitychange", v);
    };
  }, [f]);
  const y = () => Date.now() < a, v = () => {
    y() || d();
  }, d = () => {
    u.local.remove($), u.local.remove(U), u.local.remove(B), r(void 0), s(0), i(void 0);
  };
  return {
    isLoading: e,
    mfaSessionId: n,
    mfaSessionType: o,
    hasHadValidSession: c,
    expireMfaSession: d,
    storeMfaSession: (_) => {
      const S = new Date(_.expires_at).getTime();
      u.local.set($, _.id), u.local.set(U, S), u.local.set(B, _.session_type), u.session.set(se, "true"), r(_.id), s(S), i(_.session_type), m(!0);
    },
    isMfaSessionValid: y
  };
}, pt = (e) => ({
  results: (e == null ? void 0 : e.results) || [],
  previous: (e == null ? void 0 : e.previous) || "",
  next: (e == null ? void 0 : e.next) || ""
}), ht = ({ type: e, query: t }) => {
  var n, r, a, s, o, i;
  switch (e) {
    case "one":
      return (r = (n = t.data) == null ? void 0 : n.data) == null ? void 0 : r.results[0];
    case "many":
      return (s = (a = t.data) == null ? void 0 : a.data) == null ? void 0 : s.results;
    case "paginated":
      return pt((o = t.data) == null ? void 0 : o.data);
    default:
      return (i = t.data) == null ? void 0 : i.data;
  }
};
function sr({ type: e, query: t }) {
  const n = ht({ type: e, query: t });
  return {
    ...t,
    data: n,
    isInvalid: t.isError || n === void 0
  };
}
export {
  _n as PHONE_NUMBER_APP_STORAGE_KEY,
  M as SAME_DAY_ACH_CUTOFF,
  L as STORED_TOKEN_KEY,
  et as ScreenSize,
  $n as WebhookDeliveryStatuses,
  On as apiLogMethods,
  rr as buildOptInRequest,
  zn as calculateTransferFee,
  Q as capitalize,
  bt as dateToLocalDate,
  jn as decodeWebhookEvent,
  Gt as displayNumber,
  Gn as encodeWebhookEvent,
  Ut as extractPathFromUrl,
  Kt as firstNumberStartsWithSecondNumber,
  Bn as formatCreatedAt,
  g as formatCurrency,
  I as formatDate,
  He as formatDateRange,
  It as formatDateString,
  gt as formatNumber,
  Ve as formatPercentage,
  yt as formatPhoneNumber,
  sr as get,
  Qn as getActivityDetails,
  Jn as getActivityIcon,
  dt as getActivityLineItems,
  Zn as getActivityTitle,
  Ft as getAllPaybackPeriods,
  Pn as getApiName,
  Wn as getBadgeColorVariant,
  Vn as getCheckingAccount,
  Rt as getChunk,
  rn as getChunkFromId,
  Xt as getCurrentOnboardingProduct,
  _e as getDateInLocalTimezone,
  In as getDatesFromRange,
  Vt as getDaysSince,
  Kn as getDestinationAccountSubtitle,
  qn as getDestinationAccountTitle,
  ze as getDeviceTypeFromUserAgent,
  cn as getDurationDisplay,
  Bt as getEarliestDate,
  tr as getEasternTimeOffset,
  re as getFeeLineItems,
  Ln as getFirstOfMonth,
  Jt as getFixedTermRepaymentRate,
  Yn as getFormattedName,
  Nt as getFormattedTime,
  Ot as getFutureDate,
  Fn as getGenerationPrompt,
  ln as getInternalOrderId,
  An as getLastOfMonth,
  At as getMaxOffer,
  qt as getMaxOfferChunk,
  O as getMemoLineItem,
  Pt as getMinOffer,
  Nn as getMonthShort,
  on as getName,
  xt as getOrdinal,
  ut as getOriginalTransactionLineItems,
  pn as getOverdueStatus,
  sn as getPaybackPeriodFromChunkId,
  Yt as getPossiblePaybackPeriods,
  an as getProductCopy,
  it as getRecipientDetail,
  dn as getRelevantCapitalProduct,
  Ze as getRepaymentSchedule,
  Zt as getSalesBasedRepaymentRate,
  jt as getSalesDateOrDates,
  Je as getServicingDataType,
  kt as getSessionId,
  er as getSpendSaveActivityDefaultProps,
  Rn as getStatusColor,
  xn as getTooltipDate,
  Un as getWebhookModeDisplayName,
  en as groupSortedActivitiesByMonthYear,
  Qt as groupSortedActivitiesByYear,
  zt as handlePDF,
  hn as hasPastDueAmount,
  Lt as hexToRGBA,
  Mt as inIframe,
  fn as incorporationTypeDisplays,
  tn as isCapitalProduct,
  Dt as isDateWithinLastXDays,
  Et as isDateWithinPastYearToDate,
  Ct as isDateWithinYears,
  nr as isEligibleTimeForSameDayACH,
  Y as isMatchingSlug,
  un as isRutterConnectionSuccessful,
  St as localDateToDate,
  Tt as modifyDate,
  Ht as noisy,
  $t as normalizeUrl,
  wt as obfuscateEmail,
  vt as obfuscatePhoneNumber,
  Wt as pluralizeString,
  mn as productTypeToApplicationType,
  Xe as roundRateToInteger,
  P as screens,
  Hn as sortTransactions,
  Mn as statusCodes,
  u as storage,
  kn as storeToken,
  Qe as toSentenceCase,
  nn as toSnakeCase,
  Dn as useDebounce,
  Tn as useHandleAuth,
  ar as useHandleMfa,
  Sn as useInterval,
  vn as useIsResizing,
  bn as useOutsideClick,
  wn as useScreenSize,
  gn as useScrollPosition,
  yn as useSocureDeviceSession,
  En as useTransientState,
  Cn as useUserAgent,
  Xn as validateTransferAmount
};
