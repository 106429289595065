import * as api from '@parafin/medici-api'
import { useQueryOptions, useUnauthenticatedQueryOptions } from './queryOptions'

export const useCreateS3OfferCsv = () => {
  return api.useCreateS3OfferCsv(useQueryOptions())
}

export const useCreatePartnerDashboardTableCsv = () => {
  return api.useCreateS3PartnerDashboardTableCsv(useQueryOptions())
}

export const useTriggerCrmSync = () => {
  return api.useAttemptSync(useQueryOptions())
}

export const usePostRecentCrmSync = () => {
  return api.useGetSyncAndMaybeMarkFailed(useQueryOptions())
}

export const usePatchPartnersId = () => {
  return api.usePatchPartnersId(useQueryOptions())
}

export const useBatchResendWebhookAttempt = () => {
  return api.useBatchResendWebhookAttempt(useQueryOptions())
}

export const useResendCrmAttempt = () => {
  return api.useResendCrmAttempt(useQueryOptions())
}

export const usePostPartnerWebhooks = () => {
  return api.useCreatePdWebhookEndpoint(useQueryOptions())
}

export const usePatchPartnerWebhooksId = () => {
  return api.useUpdatePdWebhookEndpoint(useQueryOptions())
}

export const useDeletePartnerWebhooksId = () => {
  return api.useDeletePdWebhookEndpoint(useQueryOptions())
}

export const usePostDataingestionS3DatasetIntegrations = () => {
  return api.usePostDataingestionS3DatasetIntegrations(useQueryOptions())
}

export const usePatchPartnerWidgetConfigs = () => {
  return api.usePatchPartnerWidgetConfigs(useQueryOptions())
}

export const usePostMarketingIntegrations = () => {
  return api.usePostMarketingIntegrations(useQueryOptions())
}

export const usePostMarketingReview = () => {
  return api.usePostMarketingReview(useQueryOptions())
}

export const useDeleteMarketingIntegrationsVendor = () => {
  return api.useDeleteMarketingIntegrationsVendor(useQueryOptions())
}

export const useUserMutator = () => {
  return api.usePostPartnerUsers(useQueryOptions())
}

export const useDeletePartnerUserMutator = () => {
  return api.useDeletePartnerDeleteUsers(useQueryOptions())
}

export const useDeleteOrgUserMutator = () => {
  return api.useDeleteOrganizationDeleteUsers(useQueryOptions())
}

export const useUserChangeRoleMutator = () => {
  return api.usePatchPartnerUsers(useQueryOptions())
}

export const useSlackMutator = () => {
  return api.useDeleteSlackChannelsId(useQueryOptions())
}

export const useS3ConfigMutator = () => {
  return api.usePutDataingestionS3DatasetIngestionConfigs(useQueryOptions())
}

export const useRunS3DatasetIngestion = () => {
  return api.usePostDataingestionS3RunDatasetIngestion(useQueryOptions())
}

export const useAuthorizeSlack = () => {
  return api.usePostSlackAuthorize(useQueryOptions())
}

export const useMarkNotificationRead = () => {
  return api.useCreatePdPartnerEventMarkNotificationRead(useQueryOptions())
}

export const useMarkAllNotificationsRead = () => {
  return api.useBatchCreatePdPartnerEventMarkAllNotificationsRead(
    useQueryOptions()
  )
}

export const useUpdatePartnerEventSubscriptions = () => {
  return api.useUpdatePdPartnerEventUpdateUserSubscription(useQueryOptions())
}

export const useUpdatePartnerEventSubscriptionsForEventType = () => {
  return api.useUpdatePdPartnerEventUpdateUserSubscriptionsForEventType(
    useQueryOptions()
  )
}

export const startAuthFlow = () => {
  return api.usePostAuthLoginStart(useUnauthenticatedQueryOptions())
}

export const submitOtpCode = () => {
  return api.usePostAuthLoginValidateOtp(useUnauthenticatedQueryOptions())
}

export const exchangeIdentityTokenForAccessToken = () => {
  return api.usePostAuthTokenExchange(useUnauthenticatedQueryOptions())
}

export const useExperimentalCapitalProductOfferCreatedSandbox = () => {
  return api.useExperimentalCapitalProductOfferCreatedSandbox(useQueryOptions())
}

export const useExperimentalCapitalProductOfferClosedSandbox = () => {
  return api.useExperimentalCapitalProductOfferClosedSandbox(useQueryOptions())
}

export const useCreateFeeDiscountAccessRequest = () => {
  return api.useCreatePdFeeDiscountProgramAccessRequest(useQueryOptions())
}

export const useCreateFeeDiscountProgram = () => {
  return api.useCreatePdFeeDiscountProgram(useQueryOptions())
}

export const useCreateOrder = () => {
  return api.useCreatePdCreateOrder(useQueryOptions())
}

export const usePostUserV2 = () => {
  return api.usePostPartnerUsersV2(useQueryOptions())
}

export const usePatchUserV2 = () => {
  return api.usePatchPartnerUsersV2(useQueryOptions())
}
