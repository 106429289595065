import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import {
  useGetPartners,
  useGetPartnerConfigs,
  useListOrganizations,
} from '@/src/queries/gets'
import { FullScreenLoading, Loading } from '@/src/ui/components/Loading'
import {
  PartnerConfigSerializer,
  Partner,
  Organization,
  IdentityDataShare,
  ParafinProduct,
} from '@parafin/medici-api'
import { ErrorBoundary } from '@parafin/error-handling'
import { useSegment } from '@parafin/logging'
import { useAuth } from '@/src/providers/auth'
import { RouteName } from '@/src/routes/RouteName'
import { useNavigate } from '@/src/routes/Navigate'
import { ErrorDisplay } from '../components/generic/ErrorDisplay'
import { useStatsigUser } from '@parafin/experimentation'

export const SANDBOX_HEADER = 'x-parafin-sandbox-mode'
export const PARTNER_ID_HEADER = 'x-parafin-partner-id'

export type PartnerInfo = Partner & {
  config: PartnerConfigSerializer
}

export type OrganizationOrPartner = {
  slug: string
  name: string
  id: string
  created_at: string
  products?: ParafinProduct[]
  syncOptInEnabled: boolean
}

type CoreState = {
  sandbox: boolean
  partners: PartnerInfo[]
  partner?: PartnerInfo
  organization?: Organization
  orgOrPartner: OrganizationOrPartner
  setSandbox: (sandboxMode: boolean) => void
  setPartnerId: (partnerId: string | null) => void
}

const CoreContext = createContext<CoreState | undefined>(undefined)

export const CoreProvider = ({ children }: { children: ReactNode }) => {
  const { metadata } = useAuth()
  const [search, setSearch] = useSearchParams()
  const [sandbox, setSandbox] = useState(search.get('sandbox_mode') === 'true')
  const isOrganization =
    metadata.organization_id !== null && metadata.organization_id !== undefined
  const forcedSandbox = isOrganization ? false : sandbox
  const partners = useGetPartners(forcedSandbox, undefined)
  const partnerConfigs = useGetPartnerConfigs(forcedSandbox, {})
  const organization = useListOrganizations(sandbox, isOrganization)

  if (
    partners.isLoading ||
    partnerConfigs.isLoading ||
    (isOrganization && organization.isLoading)
  ) {
    return <FullScreenLoading />
  }

  if (
    partners.isInvalid ||
    partnerConfigs.isInvalid ||
    (isOrganization && organization.isInvalid)
  ) {
    throw new Error('Could not get partner configuration')
  }

  const partnerInfos = partners.data.map((partner) => {
    const config = partnerConfigs.data.find(
      (config) => config.partner_id === partner.id
    )
    if (!config) {
      throw new Error('Partner configuration not found')
    }
    return {
      ...partner,
      config,
    }
  })

  return (
    <CoreProviderWithData
      partners={partnerInfos}
      organization={organization.data}
      sandbox={sandbox}
      setSandbox={setSandbox}
    >
      {children}
    </CoreProviderWithData>
  )
}

type CoreProviderWithDataProps = {
  sandbox: boolean
  setSandbox: (sandboxMode: boolean) => void
  partners: PartnerInfo[]
  organization?: Organization
  children: ReactNode
}

const CoreProviderWithData = ({
  sandbox,
  setSandbox,
  partners,
  organization,
  children,
}: CoreProviderWithDataProps) => {
  const navigate = useNavigate()
  const { metadata, user } = useAuth()
  const { pathname } = useLocation()
  const { identify } = useSegment()
  const [search, setSearch] = useSearchParams()
  const partnerFromUrl =
    organization !== undefined ? search.get('partner_id') : null
  const [partnerId, setPartnerId] = useState<string | null>(
    partnerFromUrl ?? null
  )
  const isOrganization =
    metadata.organization_id !== null && metadata.organization_id !== undefined

  useEffect(() => {
    // default to first partner if using org token and page does not support org
    if (
      isOrganization &&
      !pageIsOrgEnabled(pathname) &&
      !search.get('partner_id')
    ) {
      search.set('partner_id', partners[0].id)
      setSearch(search)
      setPartnerId(partners[0].id)
    }

    if (pageIsForceSandbox(pathname) && !sandbox) {
      setSandboxAndNavigate(true)
    }
  }, [search, partners, location])

  const [partner, orgOrPartner] = useMemo(() => {
    if (isOrganization && partnerId === null) {
      if (!organization) {
        throw new Error('No organization found')
      }
      return [undefined, getOrganizationData(partners, organization)]
    }
    // if not organization, then get partner from URL or first partner
    const selectedPartner = partnerId
      ? partners.find((p) => p.id === partnerId)
      : partners[0]
    if (selectedPartner === undefined) {
      throw new Error('No partner was found for the given ID')
    }
    return [selectedPartner, getPartnerData(selectedPartner)]
  }, [isOrganization, partnerId, partners, organization])

  useEffect(() => {
    if (partner || organization) {
      identify({ partner, organization })
    }
  }, [partner, organization])

  const setSandboxAndNavigate = (sandboxMode: boolean) => {
    setSandbox(sandboxMode)
    if (sandboxMode) {
      search.set('sandbox_mode', 'true')
    } else {
      search.delete('sandbox_mode')
    }
    const maybeRedirectDestination = getBaseRoute(pathname)
    if (maybeRedirectDestination) {
      navigate({
        pathname: maybeRedirectDestination,
        search: search.toString(),
      })
    } else {
      setSearch(search)
    }
  }

  const setPartnerIdAndNavigate = (partnerId: string | null) => {
    if (partnerId === null) {
      if (!organization || pageIsOrgEnabled(pathname)) {
        setPartnerId(null)
        search.delete('partner_id')
      } else {
        setPartnerId(partners[0].id)
        search.set('partner_id', partners[0].id)
      }
    } else {
      setPartnerId(partnerId)
      search.set('partner_id', partnerId)
    }
    const maybeRedirectDestination = getBaseRoute(pathname)
    // Do not redirect if switching to org view
    if (maybeRedirectDestination && partnerId !== null) {
      navigate({
        pathname: maybeRedirectDestination,
        search: search.toString(),
      })
    } else {
      setSearch(search)
    }
  }

  return (
    <ErrorBoundary
      tags={{ partner: organization ? organization.name : orgOrPartner.name }}
      contexts={{
        partner: {
          id: partner?.id,
          slug: partner?.slug,
          userId: partner?.user_id,
          organizations: partner?.organizations,
        },
        organization,
        livemode: { sandbox },
      }}
      fallback={<ErrorDisplay />}
    >
      <CoreContext.Provider
        value={{
          sandbox,
          setSandbox: setSandboxAndNavigate,
          partners,
          partner,
          organization,
          orgOrPartner,
          setPartnerId: setPartnerIdAndNavigate,
        }}
      >
        {!pageIsOrgEnabled(pathname) && partner === undefined ? (
          <Loading />
        ) : (
          <StatsigUpdater
            userId={user?.email}
            orgOrPartnerSlug={orgOrPartner.slug}
            organizationSlug={organization?.slug}
          >
            {children}
          </StatsigUpdater>
        )}
      </CoreContext.Provider>
    </ErrorBoundary>
  )
}

const StatsigUpdater = ({
  orgOrPartnerSlug,
  organizationSlug,
  userId,
  children,
}: {
  orgOrPartnerSlug?: string
  organizationSlug?: string
  userId?: string
  children?: ReactNode
}) => {
  const { updateUserAsync } = useStatsigUser()
  const [isUpdating, setIsUpdating] = useState(false)
  useEffect(() => {
    setIsUpdating(true)
    updateUserAsync({
      userID: userId,
      custom: {
        partnerSlug: orgOrPartnerSlug,
        organizationSlugs: organizationSlug ? [organizationSlug] : [],
      },
    }).finally(() => {
      setIsUpdating(false)
    })
  }, [orgOrPartnerSlug, organizationSlug, userId])

  return <>{isUpdating ? <Loading /> : children}</>
}

export const useCore = (): CoreState => {
  const context = useContext(CoreContext)
  if (context === undefined) throw new Error('Not in CoreProvider')

  return context
}

export const pageIsOrgEnabled = (pathname: string) => {
  const OrgEnabledPages = [
    RouteName.BUSINESSES,
    RouteName.CAPITAL_BUSINESSES,
    RouteName.ANALYTICS,
    RouteName.SETTINGS,
    RouteName.REPORTING,
  ]
  const SpecificOrgDisabledPages = [
    RouteName.WIDGET,
    RouteName.BRANDING,
    RouteName.SUPPORT_INFO,
    RouteName.SLACK,
  ]
  for (const route of SpecificOrgDisabledPages) {
    if (pathname.includes(route)) {
      return false
    }
  }
  for (const route of OrgEnabledPages) {
    if (pathname.includes(route)) {
      return true
    }
  }
  if (pathname === RouteName.HOME) {
    return true
  }
  return false
}

export const pageIsForceSandbox = (pathname: string) => {
  const forceSandboxPages = [
    `${RouteName.PLAYGROUND}/create-offer`,
    `${RouteName.PLAYGROUND}/offer`,
  ]
  for (const route of forceSandboxPages) {
    if (pathname.includes(route)) {
      return true
    }
  }
  return false
}

const getBaseRoute = (path: string) => {
  if (
    path.match(/businesses\/.*\/capital\/.*/) ||
    path.match(/businesses\/.*\/wallet\/.*/)
  ) {
    return `/businesses`
  } else if (
    path.match(/data-share\/manual-uploads\/uploads\/.*/) ||
    path.match(/s3-pipeline\/uploads\/.*/)
  ) {
    return `/data-share`
  } else if (path.match(/developer\/webhook-events\/.*/)) {
    return `/developer/webhook-events`
  } else if (path.match(/developer\/crm-events\/.*/)) {
    return `/developer/crm-events`
  } else if (path.match(/developer\/api-logs\/.*/)) {
    return `/developer/api-logs`
  }
}

const getOldestPartnerCreated = (partners: Partner[]) => {
  if (partners.length === 0) {
    return '2020-01-01'
  }
  const dates = partners.map((partner) =>
    new Date(partner.created_at).getTime()
  )
  const smallestDate = new Date(Math.min(...dates))

  return smallestDate.toISOString().split('T')[0]
}

const getAllPartnerProducts = (partners: PartnerInfo[]) => {
  const organizationProducts = partners.reduce((products, partner) => {
    partner.config.products?.forEach((product) => {
      if (!products.includes(product)) {
        products.push(product)
      }
    })
    return products
  }, [] as ParafinProduct[])
  return organizationProducts
}

const getOrganizationSyncOptInEnabled = (partners: PartnerInfo[]) => {
  return (
    partners.find((partner) => {
      partner.config.identity_data_share ===
        IdentityDataShare.post_opt_in_sync_webhook
    }) !== undefined
  )
}

const getPartnerData = (partner: PartnerInfo): OrganizationOrPartner => {
  return {
    slug: partner.slug,
    name: partner.name,
    id: partner.id,
    created_at: partner.created_at,
    products: partner.config.products,
    syncOptInEnabled:
      partner.config.identity_data_share ===
      IdentityDataShare.post_opt_in_sync_webhook,
  }
}

const getOrganizationData = (
  partners: PartnerInfo[],
  organization: Organization
): OrganizationOrPartner => {
  return {
    name: organization.name,
    slug: `org-${organization.name.toLowerCase().replace(' ', '-')}`,
    id: organization.id,
    created_at: getOldestPartnerCreated(partners),
    products: getAllPartnerProducts(partners),
    syncOptInEnabled: getOrganizationSyncOptInEnabled(partners),
  }
}
